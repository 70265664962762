import PasswordField from '../../../../helpers/fields/PasswordField'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { removeTokens } from '../../../../../utils/functions'
import { pwdYup } from '../../../../../utils/constants'
import { changePin } from '../../../../../api/auth'
import { Box, Button, Dialog } from '@mui/material'
import { useNavigate } from 'react-router'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const validationSchema = Yup.object({
    pin: Yup.string().required('Шинэ пин код оруулна уу!')
        .min(4, '4 оронтой тоо байх ёстой!').max(4, '4 оронтой тоо байх ёстой!')
        .test('isNumeric', '4 оронтой тоо байх ёстой!', value => isFinite(value)),
    ...pwdYup
    
})

export default function ChangePin() {
    const [open, setOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)
    const navi = useNavigate()
    const afterSuccess = () => {
        setSuccess(false)
        setOpen(false)
        removeTokens()
        navi('/signin')
    }
    
    const handleClose = () => { setOpen(false) }
    const { handleSubmit, getFieldProps, errors } = useFormik({
        initialValues: { pin: '', password: '' },
        onSubmit: (vals) => {
            changePin(vals).then(res => {
                setSuccess(true)
            }).catch(e => { 
                const isPwdErr = e?.response?.data?.password
                if (isPwdErr) { setError(isPwdErr?.[0]) }
                const isPinErr = e?.response?.data?.pin
                if (isPinErr) { setError(isPinErr?.[0]) }
            })
        },
        validateOnChange: false,
        validationSchema
    })

    //console.log("ERR: ", error)

    return (
        <Box sx={{ p: 2 }}>
            <Button onClick={() => { setOpen(true) }} variant='contained'>Пин код өөрчлөх</Button>
            <Dialog open={open} onClose={handleClose}>
                <Box sx={{ p: '8px 6px', display: 'flex', flexDirection: 'column' }}>
                    <PasswordField
                        label='Шинэ пин код'
                        name='pin'
                        {...getFieldProps('pin')}
                        error={Boolean(errors?.pin) || error?.includes('тоо')}
                        helperText={Boolean(errors?.pin) ? errors?.pin : (error && error?.includes('тоо')) ? error : ' '}
                    />
                    <PasswordField
                        label='Нэвтрэх нууц үг'
                        name='password'
                        {...getFieldProps('password')}
                        error={Boolean(errors?.password) || error?.includes('Нууц үг')}
                        helperText={Boolean(errors?.password) ? errors?.password : error?.includes('Нууц үг') ? error : ' '}
                    />
                    <Button size='small' variant='contained' onClick={handleSubmit}>
                        Өөрчлөх
                    </Button>
                    {getToast(success, () => { afterSuccess() }, 'Амжилттай!', 'success', 1500)}
                </Box>
            </Dialog>
        </Box>
    )
}
