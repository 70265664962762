import { Box, Container, Divider, Paper, Typography } from '@mui/material'
import { getToast } from '../auth/helpers/ToastAlert'
import { useAuthCtx } from '../../context/AuthCtx'
import { Outlet, useLocation } from 'react-router'
import { getBranches } from '../../api/company'
import { addNewOrder } from '../../api/order'
import Address, { BY_COME } from './Address'
import React, { useState } from 'react'
import { createContext } from 'react'
import OrderItems from './OrderItems'
import { useContext } from 'react'
import { useEffect } from 'react'
import Payment from './Payment'
import Verify from './Verify'

export function CustomDivider ({ text }) {
    return (
        <Divider sx={{ mt: 3, mb: 2, "&::before, &::after": { borderColor: "primary.main" } }} textAlign='center'>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography fontSize='14px' fontStyle='italic' marginLeft={1} sx={{ color: 'primary.main' }}>{text}</Typography>
            </Box>
        </Divider>
    )
}

// order ctx
const OrderCtx = createContext()
export const useOrderCtx = () => useContext(OrderCtx)

// main component
export default function Order() {
    const { basket, fetchBasket, navBtm } = useAuthCtx()
    // branch
    const [branches, setBranches] = useState([])
    const [branchId, setBranchId] = useState(null)
    const [note, setNote] = useState(null)
    
    const [selectedType, setSelectedType] = useState(null)

    // payment type
    const [payType, setPayType] = useState(null)

    const reset = () => {
        setBranchId(null)
        setBranches([])
    }

    useEffect(() => {
        getBranches()
            .then(res => { setBranches(res?.data) })
            .catch(err => { console.log("ERr: ", err) })
    }, [])

    const [newOrder, setNewOrder] = useState(null)
    
    const [ordErr, setOrdErr] = useState(null)
    const handleErrOff = () => { setOrdErr(null) }

    const [ocp, setOcp] = useState(false)

    const createOrder = () => {
        setOcp(true)
        addNewOrder({ branchId, basketId: basket?.id, note })
            .then(res => {
                setNewOrder(res?.data)
                reset()
                window.localStorage.removeItem('adr')
                window.localStorage.removeItem('pt')
            })
            .catch(e => { 
                const errMsg = e?.response?.data?.detail
                if ( errMsg === 'Bad customer!') {
                    setOrdErr('Тухайн нийлүүлэгчээс захиалга хийх боломжгүй байна!')
                } else { setOrdErr('Түр хүлээгээд дахин оролдоно уу!') }
                console.log("ERRR: ", e)
            })
            .finally(() => { setOcp(false) })
    }

    useEffect(() => {
        setNewOrder(null)
        return () => {
            setNewOrder(null)
            window.localStorage.removeItem('tp')
            window.localStorage.removeItem('tc')
        }
    }, [])

    // restart
    const loc = useLocation()
    useEffect(() => {
        if (loc?.state) {
            if (loc?.state?.fromTemplet) { setNewOrder(null); fetchBasket() }
        }
    }, [loc?.state])

    // err
    const [qtysErr, setQtysErr] = useState(null)
    const [itemsErr, setItemsErr] = useState(null)
    useEffect(() => {
        if (!basket?.items?.length && newOrder === null) { setItemsErr('Сагсанд бараа нэмнэ үү!') }
        else { setItemsErr(null) }
    }, [basket?.items, newOrder])

    const selectAdrErr = BY_COME !== selectedType && !branchId

    return (
        <OrderCtx.Provider 
            value={{
                branches,
                setBranches,
                branchId,
                setBranchId,
                createOrder,
                newOrder,
                note,
                setNote
            }}
        >
            {
                loc?.pathname === '/order'
                    ?   <Container sx={{ mb: 4, height: 'auto' }} maxWidth={'lg'}>
                            <Box sx={{ height: `calc(${navBtm}px + 10px)` }}></Box>
                            <Paper sx={{ boxShadow: 10, p: 2 }}>
                                {getToast(Boolean(ordErr), handleErrOff, ordErr, 'warning', 2500)}
                                {getToast(Boolean(itemsErr), () => {}, itemsErr, 'warning', 1500)}
                                <Box sx={{ mt: 3, p: 0, width: '100%' }}>
                                    <OrderItems qtysErr={qtysErr} setQtysErr={setQtysErr} />
                                    <Address selectedType={selectedType} setSelectedType={setSelectedType} />
                                    <Payment payType={payType} setPayType={setPayType} />
                                    <Verify
                                        newOrder={newOrder}
                                        createOrder={createOrder}
                                        adrErr={selectAdrErr}
                                        qtysErr={qtysErr}
                                        itemsErr={itemsErr}
                                        payType={payType}
                                        ocp={ocp}
                                        branchId={branchId}
                                    />
                                </Box>
                            </Paper>
                        </Container>
                    :   loc?.pathname === '/order/completed'
                            ?   <Outlet />
                            :   null
            }
        </OrderCtx.Provider>
    )
}
