import { Box, Button, TextField, Typography } from '@mui/material'
import { createBasketTemplet } from '../../../../../api/basket'
import React, { useState } from 'react'

export default function SaveTemplet({ basket, setBt, boxOff }) {
    const [name, setName] = useState('')
    const nameErr = name?.length > 50 ? 'Сагсны нэр 50 тэмдэгтээс ихгүй байхыг анхаарна уу!' : ''
    // create basket templet
    const createTemplet = () => {
        const templetData = {
            name: name?.length > 50 ? name.slice(0, 50) : name,
            supplier: basket?.supplier?.id,
            items: basket?.items?.map(b => b?.product_id)
        }
        if (templetData) {
            createBasketTemplet(templetData)
                .then(_ => { setBt('Амжилттай хадгалагдлаа!') })
                .catch(e => { console.log("ERR TMP: ", e) })
                .finally(_ => { boxOff() })
        }
    }

    const handleEnter = (e) => { if (e.code === 'Enter') { createTemplet() } }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', p: 3 }}>
            <TextField
                label={<Typography fontSize='15px'>Хадгалах нэр</Typography>}
                value={name}
                onChange={e => setName(e?.target?.value)}
                size='small'
                inputProps={{ style: { padding: '5px', paddingLeft: 8, color: '#1b2e3c', fontSize: '15px' } }}
                InputLabelProps={{ style: { marginTop: -6 } }}
                error={Boolean(nameErr)}
                helperText={Boolean(nameErr) ? nameErr : ' '}
                sx={{ mr: 1 }}
                onKeyDown={handleEnter}
            />
            <Button size='small' variant='contained' sx={{ height: 'fit-content' }} onClick={createTemplet}>Хадгалах</Button>
        </Box>
    )
}
