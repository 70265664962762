import { scrollStyle } from "../../../../utils/constants";
import { getPartners } from "../../../../api/company";
import { useTablePagination } from "../../../hooks";
import React, {
  useEffect,
  useState,
  useRef,
  createContext,
  useContext,
} from "react";
import { isEmpty, keys } from "underscore";
import HeadCell from "./Cells/HeadCell";
import { useQuery } from "react-query";
import FilterRow from "./Filter";
import Row from "./Row";
import TablePages from "../../../common/TablePages";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const CustomerListCtx = createContext();
export const useCustomerListCtx = () => useContext(CustomerListCtx);
const Customers = () => {
  const [columns, setColumns] = useState({
    name: { label: "Нэр", order: "", align: "center" },
    rd: { label: "РД", order: "", align: "center" },
    debtLimit: { label: "Зээлийн лимит", order: "", align: "center" },
    debt: { label: "Нийт өр", order: "", align: "center" },
    debtCnt: { label: "Төлөгдөөгүй захиалгын тоо", order: "", align: "center" },
    orderCnt: { label: "Нийт захиалгын тоо", order: "", align: "center" },
    orderSum: { label: "Нийт захиалгын дүн", order: "", align: "center" },
    email: { label: "И-мэйл", order: "", align: "center" },
    phone: { label: "Утасны дугаар", order: "", align: "center" },
    isBad: { label: "Найдваргүй эсэх", order: "", align: "center" },
  });
  // ordering
  const handleOrder = (fieldName) => {
    const c = columns?.[fieldName];
    const v =
      c?.order === ""
        ? fieldName
        : c?.order === fieldName
        ? `-${fieldName}`
        : "";
    setColumns({
      ...columns,
      [fieldName]: { ...c, order: v },
    });
  };
  const orderParam = keys(columns)?.reduce((acc, k) => {
    const cur = columns?.[k]?.order;
    if (acc !== "ordering=" && cur !== "") {
      acc += ",";
    }
    if (cur !== "") {
      acc += `${cur}`;
    }
    return acc;
  }, "ordering=");

  // pagination
  const [
    page,
    handlePageChange,
    pageSize,
    setPageSize,
    handlePageSizeChange,
    rppOptions,
  ] = useTablePagination([10, 25, 50]);
  const [filtering, setFiltering] = useState(null);
  const filterParam = keys(filtering)?.reduce((acc, k) => {
    acc += acc === "" ? `${k}${filtering[k]}` : `&${k}${filtering[k]}`;
    return acc;
  }, "");
 
  // fetch data
  const { data } = useQuery(
    ["get_partners", page, pageSize, orderParam, filterParam],
    getPartners
  );
  const [partners, setPartners] = useState(data?.data?.results || []);
  useEffect(() => {
    if (!isEmpty(data?.data)) {
      setPartners(data?.data?.results || []);
    } else {
      setPartners([]);
    }
  }, [data?.data]);
  const hhRef = useRef(null);
  console.log("P: ", partners);
  return (
    <>
      <TableContainer
        sx={{
          ...scrollStyle,
          "&::-webkit-scrollbar": { width: 8, height: 8 },
        }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                "& > th": {
                  fontSize: "12px",
                  backgroundColor: "secondary.light",
                  color: "white",
                  borderRight: "1px solid white",
                  p: "4px 4px",
                  textAlign: "center",
                },
              }}
              ref={hhRef}
            >
              <TableCell>
                <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  №
                </Typography>
              </TableCell>
              {keys(columns)?.map((c) => (
                <HeadCell
                  key={c}
                  field={columns?.[c]}
                  fieldName={c}
                  handleOrder={handleOrder}
                />
              ))}
            </TableRow>
          </TableHead>
          <FilterRow filtering={filtering} setFiltering={setFiltering} />
          <TableBody>
            {partners?.map((row, idx) => (
              <Row
                key={`${idx}`}
                row={row}
                setPartners={setPartners}
                idx={idx}
                page={page}
                pageSize={pageSize}
              />
            ))}
          </TableBody>
          <TablePages
            sx={{
              p: 0,
              m: 0,
              position: "sticky",
              bottom: 0,
              background: "white",
            }}
            count={partners?.length || 0}
            rppOptions={rppOptions}
            setPageSize={setPageSize}
            page={page}
            handlePageChange={handlePageChange}
            pageSize={pageSize}
            handlePageSizeChange={handlePageSizeChange}
          ></TablePages>
        </Table>
      </TableContainer>
    </>
  );
};
export default Customers;