import { getToast } from '../../../../../auth/helpers/ToastAlert'
import { isTokenValid } from '../../../../../../utils/functions'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { deleteProds } from '../../../../../../api/product'
import React, { useState } from 'react'

export default function DeleteProduct({ selCnt, toDelIds, setDelIds, refetch, handleAddOpen }) {
    const { macs } = isTokenValid()
    // deletion verification modal
    const [delMod, setDelMod] = useState(false)
    const delModOn = () => { setDelMod(true) }
    const delModOff = () => { setDelMod(false) }
    // deletion success
    const [delSuc, setDelSuc] = useState(false)
    const delSucOn = () => { setDelSuc(true) }
    const delSucOff = () => { setDelSuc(false); delModOff(); }
    // deletion error
    const [delErr, setDelErr] = useState(false)
    const delErrOn = () => { setDelErr(true) }
    const delErrOff = () => { setDelErr(false) }
    // handle delete action
    const handleDelete = () => {
        deleteProds(toDelIds)
            .then(_ => {
                refetch()
                delSucOn()
                setDelIds([])
            })
            .catch(e => { console.log("DEL ERR: ", e); delErrOn(); delModOff() })
    }

    return (
        <>
            { getToast(delSuc, delSucOff, 'Амжилттай устгалаа!', 'success', 1500) }
            { getToast(delErr, delErrOff, 'Хүсэлт амжилтгүй. Дараа дахин оролдоно уу!', 'warning', 2000) }
            <Dialog open={delMod} onClose={delModOff}>
                <Box sx={{ display: 'flex', flexDirection: 'column', px: 2, py: 4 }}>
                    <Typography sx={{ mb: 2, color: 'primary.light' }}>
                        Устгахдаа итгэлтэй байна уу?
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                        <Button
                            variant='contained'
                            size='small'
                            sx={{ backgroundColor: 'primary.light', '&:hover': { backgroundColor: 'primary.main' }, mr: 2 }}
                            onClick={handleDelete}
                        >Тийм</Button>
                        <Button 
                            variant='contained' 
                            size='small' 
                            sx={{ backgroundColor: 'secondary.light', '&:hover': { backgroundColor: 'secondary.main'}} }
                            onClick={delModOff}
                        >Үгүй</Button>
                    </Box>
                </Box>
            </Dialog>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
               <Box sx={{ display: 'flex', flexDirection: 'row', visibility: selCnt > 0 ? 'visible': 'hidden' }}>
                    <Button size='small' variant='contained' onClick={delModOn} sx={{ bgcolor: 'brown' }}>Бараа устгах</Button>
                    <Typography sx={{ ml: 1, fontSize: '15px', color: 'primary.light', display: 'flex', alignItems: 'center' }}>({selCnt})</Typography>
                </Box>
                <Button size='small' variant='contained' onClick={handleAddOpen} sx={{ visibility: macs ? 'hidden' : 'visible' }}>Бараа нэмэх</Button>
            </Box>
        </>
    )
}
