import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import DeleteIconBtn from '../../../../helpers/buttons/DeleteIconBtn'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { removeStaff } from '../../../../../api/staff'
import { sortBy } from 'underscore'
import { useState } from 'react'
import React from 'react'

export default function StaffsList({ staffs, refetch }) {
    const stfs = sortBy(staffs, function(s){return Math.max(s.id)})
    const [delSuc, setDelSuc] = useState(false)
    const delSucOff = () => { setDelSuc(false) }
    
    const deleteStaffInfo = (staffId) => {
        removeStaff(staffId)
            .then(_ => { setDelSuc(true); refetch(); })
            .catch(e => { console.log("e: ", e) }) }
    
    // row hover
    const [hover, setHover] = useState(false)
    
    return (
        <TableContainer sx={{ display: 'flex', justifyContent: 'center' }}>
            <Table sx={{ maxWidth: '1000px', boxShadow: 4, mb: 4 }}>
                <TableHead>
                    <TableRow sx={{ backgroundColor: 'secondary.light', color: 'white' }}>
                        <TableCell sx={{ color: 'white' }}>№</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Нэр</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Регистр</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Имэйл хаяг</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Утасны дугаар</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Ажил үүрэг</TableCell>
                        <TableCell align='center' sx={{ color: 'white' }}>Устгах</TableCell>
                    </TableRow>                                
                </TableHead>
                <TableBody>
                    { stfs?.map((s, idx) => 
                    <TableRow 
                        key={`Row-${idx}`} sx={{ '& > td': { p: '4px 8px' }, '&:hover': { backgroundColor: '#f1f1f1' } }}
                        onMouseEnter={() => {setHover(idx)}}
                        onMouseLeave={() => {setHover(null)}}
                    >
                        <TableCell width='20px'>{idx + 1}</TableCell>
                        <TableCell align='center'>{s?.name}</TableCell>
                        <TableCell align='center'>{s?.rd}</TableCell>
                        <TableCell align='center'>{s?.email}</TableCell>
                        <TableCell align='center'>{s?.phone}</TableCell>
                        <TableCell align='center'>{s?.role}</TableCell>
                        <TableCell align='center' width='60px'>
                            <DeleteIconBtn onYes={() => { deleteStaffInfo(s?.id) }} idx={idx} hoverId={hover} onClose={delSucOff} />
                        </TableCell>
                    </TableRow>) }
                </TableBody>
            </Table>
            {getToast(delSuc, delSucOff, 'Амжилттай устгагдлаа!', 'success', 1500)}
        </TableContainer>
    )
}
