import AxiosWithToken, { Axios } from "../global/axiosBase"
import { getPageParams } from "./utils"

// company
// get company info
export const getCompanyInfo = () => {
    return AxiosWithToken.get(`company/`)
}
/// add company info
export const addCompany = (companyInfo) => {
    return AxiosWithToken.post('company/', companyInfo)
}
// update company info
export const updateCompany = (companyId, companyInfo) => {
    return AxiosWithToken.patch(`company/${companyId}/`, companyInfo)
}
export const updateCompanyContact = (companyId, data) => {
    return AxiosWithToken.patch(`company/${companyId}/contacts/`, data)
}

// branch
// get branches
export const getBranches = () => {
    return AxiosWithToken.get('branch/')
}
export async function getBranchList () {
    return await AxiosWithToken.get('branch/')
}

// get branch
export const getBranch = (branchId) => { return AxiosWithToken.get(`branch/${branchId}/`) }

// add branch
export const addBranch = (branchInfo) => { return AxiosWithToken.post('branch/', branchInfo) }

// update branch
export const updateBranch = (branchInfo, branchId) => { return AxiosWithToken.patch(`branch/${branchId}/`, branchInfo) }

// remove branch
export const removeBranch = (branchId) => { return AxiosWithToken.delete(`branch/${branchId}/`) }

// get suppliers
export const getSuppliers = () => { return Axios.get('suppliers/') }

// Settings
// get settings
export const getSettings = (userId) => {
    return AxiosWithToken.get(`settings/${userId}/`)
}
// set settings
export const updateSettings = (userId, data) => {
    return AxiosWithToken.patch(`settings/${userId}/`, data)
}

// get partners
export const getPartners = ({ queryKey }) => {
    const [ _, page, pageSize, orderParam, filterParam ] = queryKey
    let qParam = getPageParams(page, pageSize, 'partner/')
    if (orderParam !== 'ordering=') {
        qParam += `?${orderParam}`
    }
    if (filterParam) { qParam += qParam !== 'partner/' ? `&${filterParam}` : `?${filterParam}` }
    //console.log("refetched: ", orderParam, filterParam, qParam)
    return AxiosWithToken.get(qParam)
}

export const getPartnerSuppliers = () => {
    return AxiosWithToken.get('partner_suppliers/')
}
// update partner info
export const updatePartner = (id, data) => AxiosWithToken.patch(`partner/${id}/`, data)

// q
export const ci = ({ branchId, note }) => {
    if (branchId||note) { return AxiosWithToken.post('ci/', { branchId, note }) }
    return AxiosWithToken.post('ci/')
}
export const cp = () => AxiosWithToken.get(`cp/`)

export const rq = (data) => AxiosWithToken.post('rq/', { ...data })
export const gq = () => AxiosWithToken.get('rq/')
export const dq = () => AxiosWithToken.delete('rq/')

export const checkSQpay = () => AxiosWithToken.get('check_sqpay/')
