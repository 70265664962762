import { scrollStyle } from '../../../../../../utils/constants'
import { toPrice } from '../../../../../../utils/functions'
import { Box, Button, Typography } from '@mui/material'
import BasketItem from './BasketItem'
import React from 'react'

export default function Basket({ basket, goToOrder }) {
    const items = basket.items
    const totalPrice = items.reduce((acc, cur) => acc + (cur.price * cur.qty), 0)

    return (
        <Box sx={{ borderRadius: 1, backgroundColor: '#f1f1f1' }}>
            <Box>
                <Box
                    sx={{
                        maxHeight: '350px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        borderBottom: '1px solid',
                        borderBottomColor: 'primary.light',
                        ...scrollStyle,
                        "&::-webkit-scrollbar": { width: 6 }
                    }}
                >
                    { items.map((item, idx) => <BasketItem key={item.id} item={item} idx={idx} />) }
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%', pr: 1, pl: 1, mt: 1 }}>
                    <Typography fontSize='15px' color='secondary.light' fontWeight='bold'>Нийт дүн: </Typography>
                    <Typography fontSize='15px' color='secondary.light' fontWeight='bold' sx={{ pr: 4 }}>{toPrice(totalPrice)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        size='small'
                        onClick={() => { goToOrder() }}
                        sx={{ mb: 2, borderRadius: 0 }}
                        variant='contained'
                    >
                        Захиалга үүсгэх
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
