import { TableCell, TableRow, Tooltip } from "@mui/material";
import React from "react";
import TextFieldFilter from "./FilterCells/TextFieldFilter";
import ChoiceFieldFilter from "./FilterCells/ChoiceFieldFilter";
import NumberFieldFilter from "./FilterCells/NumberFieldFilter";
import { primaryLight, secondaryLight } from "../../../../../utils/constants";
import { FilterAlt } from "@mui/icons-material";

export default function FilterRow({ filtering, setFiltering }) {
  const handleFiltering = ({ fieldName, newValue, lookup }) => {
    if (newValue) {
      const lu = !lookup ? "=" : lookup;
      setFiltering({
        ...filtering,
        [fieldName]: `${lu}${newValue}`,
      });
    } else {
      const updated = { ...filtering };
      delete updated[fieldName];
      setFiltering({ ...updated });
    }
  };
  
  //console.log("F: ", filtering)

  return (
    <TableRow
      sx={{
        "& > td": {
          p: "2px ",
          borderBottom: "1px solid gray",
          borderBottomColor: primaryLight,
        },
      }}
    >
      <TableCell align="center" sx={{ p: "2px" }}>
        <Tooltip title="Шүүж хайх мөр" placement="right">
          <FilterAlt
            sx={{ color: secondaryLight, fontSize: "20px", margin: 0 }}
          />
        </Tooltip>
      </TableCell>
      <TextFieldFilter
        fieldName={"name"}
        setNewValue={handleFiltering}
      />
      <TextFieldFilter
        fieldName={"rd"}
        setNewValue={handleFiltering}
      />
      <TableCell />
      <NumberFieldFilter fieldName={"debt"} setNewValue={handleFiltering} />
      <TableCell />
      <TableCell />
      <NumberFieldFilter fieldName={"orderSum"} setNewValue={handleFiltering} />
      <TableCell />
      <TableCell />
      <ChoiceFieldFilter fieldName={"isBad"} setNewValue={handleFiltering} />
    </TableRow>
  );
}
