import { primaryLight, secondaryLight } from '../../../../../../utils/constants'
import { Box, IconButton, Tooltip } from '@mui/material'
import { Delete } from '@mui/icons-material'
import React from 'react'

export default function Thumbnail({ logo, removeImg }) {
    const handleRemove = (e) => { removeImg(); e.stopPropagation() }
    const handleImgOpen = (e) => { window.open(URL.createObjectURL(logo), '_blank'); e.stopPropagation() }

    return (
        <Box sx={{ position: 'relative', width: '80px', height: '80px' }} onClick={handleImgOpen}>
            <img src={logo ? URL.createObjectURL(logo) : ''} style={{ width: '80px', height: '80px', borderRadius: '8px', boxShadow: '4px 4px 4px grey' }} />
            <Tooltip title='Устгах' placement='right'>
                <IconButton sx={{ position: 'absolute', bottom: -5, right: -5, p: '2px', backgroundColor: 'white', '&:hover': { backgroundColor: 'white' } }} onClick={handleRemove}>
                    <Delete sx={{ color: primaryLight, '&:hover': { color: secondaryLight } }} />
                </IconButton>
            </Tooltip>
        </Box>
    )
}
