import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material/'
import { scrollStyle } from '../../../../utils/constants'
import { checkProductQty } from '../../../../api/product'
import { qtyMsgs } from '../../../hooks'
import { CustomDivider } from '..'
import Footer from './Footer'
import React from 'react'
import Row from './Row'

// Үндсэн компонэнт
export default function OrderItems({ promo, qtysErr, setQtysErr }) {
    const items = promo?.gift?.length > 0 ? promo?.bundle?.concat(promo?.gift?.map(g => ({ ...g, price: 0, isGift: true}))) : promo?.bundle
    
    const totalPrice = promo?.promo_type === 1 ? promo?.bundle_price : promo?.bundle?.reduce((acc, cur) => acc + cur.price * cur.qty, 0)
    const bundleTc = promo?.bundle?.reduce((acc, cur) => acc + cur.qty, 0)
    const giftTc = promo?.gift?.reduce((acc, cur) => acc + cur.qty, 0)||0
    const totalCount = bundleTc + giftTc
    //console.log("p items: ", promo?.promo_type, totalPrice)

    // item qtys
    const prepQtys = () => items?.reduce((acc, {qtyId, qty}) => ({...acc, [qtyId]: qty}), {})

    // барааны үлдэгдэл хүрэлцэхгүй эсвэл бараа дууссан эсэх мэдээлэл
    const handleQtysErr = (data) => {
        for (let k in data) {
            const qtyErr = data?.[k]
            const itemQtyErr = qtyErr === null ? qtyMsgs.noQty : qtyErr === false ? qtyMsgs.lessQty : null
            if (itemQtyErr) { setQtysErr({ ...qtysErr, [k]: itemQtyErr }) }
        }
    }
    React.useEffect(() => {
        if (items?.length > 0) {
            const ids = prepQtys()
            checkProductQty(ids)
                .then(res => {
                    handleQtysErr(res?.data)
                })
                .catch(e => { 
                    console.log("BULK err: ", e); 
                    setQtysErr(null); 
                })
        }
    }, [])
    
    return (
        <>
            <CustomDivider text={'Барааны тоо, ширхэг'} />
            {
                (items?.length > 0 && totalCount && totalPrice)
                    ?   <Box>
                            <TableContainer sx={{ width: '100%', ...scrollStyle, display: 'flex', justifyContent: 'center' }}>
                                <Table stickyHeader size='small' sx={{ width: '90%' }}>
                                    <TableHead>
                                        <TableRow sx={{ '& > th': { py: '2px', px: '6px', pl: '4px', textAlign: 'right', fontSize: '14px', fontWeight: 'bold', fontStyle: 'italic', color: 'primary.light' } }}>
                                            <TableCell width='30px'>№</TableCell>
                                            <TableCell sx={{ textAlign: 'left !important' }}>Барааны нэр</TableCell>
                                            <TableCell width='130px'>Үнэ</TableCell>
                                            <TableCell>Тоо, ширхэг</TableCell>
                                            <TableCell width='130px'>Нийт үнэ</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            items?.map( (b, idx) => {
                                                    return <Row key={`${idx}`} item={b} idx={idx} qtysErr={qtysErr} setQtysErr={setQtysErr} />
                                                }
                                            )
                                        }
                                    </TableBody>
                                    <Footer totalPrice={totalPrice} totalCount={totalCount} />
                                </Table>
                            </TableContainer>
                        </Box>
                    :   <div></div>
            }
        </>
    )
}