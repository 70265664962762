import { Box, Button, InputAdornment, MenuItem, TableCell, TextField, Typography } from '@mui/material'
import { primaryLight } from '../../../../../../utils/constants'
import DropMenu from '../../../../../helpers/DropMenu'
import { Close } from '@mui/icons-material'
import { keys } from 'underscore'
import React from 'react'

const lookupChoice = {
    __gte: '>=',
    __lte: '=<'
}

export default function NumberFieldFilter({ fieldName, setNewValue, left }) {
    const [lookup, setLookup] = React.useState('__gte')
    const selectLookup = (k) => {
        if (keys(lookupChoice).includes(k)) { setLookup(k) }
    }
    const [value, setValue] = React.useState('')
    const handleChange = (e) => {
        const v = e.target.value
        //console.log("A: ", v)
        if (v && !isNaN(v)) {
            setValue(v)
        } else { handleClear() }
    }

    const handleEnter = (e) => {
        if (e.code === 'Enter') { if (value) { setNewValue({ fieldName, newValue: value, lookup: `${lookup}=` }) } }
    }
    const handleClick = () => { { if (value) { setNewValue({ fieldName, newValue: value, lookup: `${lookup}=` }) } } }
    
    const handleClear = () => { setValue(''); setNewValue({ fieldName, newValue: null }) }
    
    const placeValue = (value && lookup) ? `${lookupChoice?.[lookup]}${value}` : null

    return (
        <TableCell>
            <DropMenu
                title={<Box sx={{ display: 'flex', flexDirection: 'row', p: '4px' }}>
                            <TextField
                                placeholder={placeValue??'Бүгд'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <Close
                                                sx={{ 
                                                    fontSize: '18px', color: primaryLight,
                                                    '&:hover': { cursor: 'pointer' },
                                                    visibility: Boolean(value) ? 'visible' : 'hidden'
                                                }}
                                                onClick={handleClear}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                inputProps={{ style: { padding: '2px 5px', fontSize: '14px', cursor: 'pointer' } }}
                                sx={{
                                    '& fieldset': { borderColor: primaryLight, borderRadius: 0 },
                                    '&:hover fieldset': { borderColor: primaryLight },
                                    '& .MuiInputBase-input::placeholder': { color: primaryLight, fontSize: '14px', opacity: 1, pl: 1 },
                                    '& .MuiInputBase-input': { 'caretColor': 'transparent' }
                                }}
                                size='small'
                            />
                        </Box>}
                detail={<Box sx={{ backgroundColor: 'white', boxShadow: 8, display: 'flex', flexDirection: 'row', padding: '16px 32px' }}>
                            <DropMenu
                                title={<Typography fontSize='14px' sx={{ display: 'flex', mr: 1, border: 1, borderColor: primaryLight, color: primaryLight, padding: '1.5px 5px', width: '30px' }}>{lookupChoice?.[lookup]||'>='}</Typography>}
                                detail={
                                    <Box sx={{ backgroundColor: 'white', boxShadow: 8 }}>
                                        {keys(lookupChoice).map(k => <MenuItem key={k} sx={{ fontSize: '14px', color: primaryLight }} onClick={() => selectLookup(k)}>
                                            {lookupChoice[k]}
                                        </MenuItem>)}
                                    </Box>
                                }
                                top={25}
                                left={0}
                            />
                            <TextField
                                value={value}
                                onChange={handleChange}
                                inputProps={{ style: { padding: '3px 16px', fontSize: '14px', cursor: 'pointer' } }}
                                sx={{
                                    width: '100px',
                                    '& fieldset': { borderColor: primaryLight, borderRadius: 0 },
                                    '&:hover fieldset': { borderColor: primaryLight },
                                }}
                                size='small'
                                onKeyDown={handleEnter}
                            />
                            <Button sx={{ p: 0, m: 0, ml: 1, borderRadius: 0 }} size='small' variant='contained' onClick={handleClick}>
                                Шүүх
                            </Button>
                        </Box>}
                top={32}
                left={left??4}
            />
            
        </TableCell>
    )
}
