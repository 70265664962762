import { Tooltip, Typography } from '@mui/material'
import React from 'react'

const MyTooltip = ({ title, X = 50, Y = -20, titleFontSize = 12, bgColor = 'primary.main', children }) => {
  return (
    <Tooltip 
        title={
            <Typography
                fontSize={titleFontSize}
                sx={{
                    m: 0,
                    p: 0
                }}
            >
                {title}
            </Typography>
        }
        sx={{
            p: 0,
        }}
        PopperProps={{
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [X, Y],
                    }
                },
            ],
        }}
        componentsProps={{
            tooltip: {
                sx: {
                    bgcolor: bgColor
                }
            }
        }}
        >
            {children}
        </Tooltip>
  )
}

export default MyTooltip