import { AppBar, Box, Container, Toolbar } from '@mui/material'
import { useAuthCtx } from '../../context/AuthCtx'
import { useLocation } from 'react-router'
import RightSection from './RightSection'
import SimpleSearch from './SimpleSearch'
import LeftSection from './LeftSection'
import NavTop from '../home/top'
import { useRef } from 'react'
import React from 'react'

export default function Navbar () {
    const { setNavBtm } = useAuthCtx()
    const location = useLocation()
    const isAuthRoute = ['/signin', '/signup'].includes(location.pathname)
    const navRef = useRef(null)

    React.useEffect(() => {
        if (navRef?.current) {
            const btm = navRef?.current?.getBoundingClientRect()?.bottom
            setNavBtm(btm)
        }
    }, [])

    return (
        <AppBar position='fixed' ref={navRef}>
            <NavTop />
            <Toolbar>
                <Container 
                    maxWidth='xl' 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center'
                    }}
                >
                    {
                        isAuthRoute
                            ?   <LeftSection isAuthRoute={isAuthRoute} />
                            :   <Box 
                                    sx={{ 
                                        display: 'flex', flexDirection: 'row', justifyContent: isAuthRoute ? 'center' : 'space-between', 
                                        alignItems: 'center', width: '100%', m: '4px'
                                    }}
                                >
                                    <LeftSection isAuthRoute={isAuthRoute} />
                                    <Box sx={{ flexGrow: 1 }}><SimpleSearch /></Box>
                                    <RightSection />
                                </Box>
                    }
                </Container>
            </Toolbar>
        </AppBar>
    )
}