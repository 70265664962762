import { promoBtnColor } from '../../../../home/Main/Result/Featured/PromosBtn'
import { toPrice } from '../../../../../utils/functions'
import { TableCell, TableRow } from '@mui/material'
import { Redeem } from '@mui/icons-material'
import CountInput from './CountInput'
import React from 'react'

export default function Row({ idx, item, qtysErr, setQtysErr }) {
    const totalPrice = item?.price * item?.qty
    return (
        <TableRow
            sx={{
                '& > td': {
                    py: '2px', px: '6px', textAlign: 'right',
                    fontSize: '13px', color: 'primary.main'
                },
                '&:hover': { backgroundColor: '#f5f5f5' },
            }}
        >
            <TableCell sx={{ position: 'relative' }}>
                {idx + 1}
                {item?.isGift && <Redeem sx={{ position: 'absolute', left: -10, top: 5, fontSize: '20px', color: promoBtnColor }} />}
            </TableCell>
            <TableCell sx={{ textAlign: 'left !important' }}>{item?.name}</TableCell>
            <TableCell align='center'>{toPrice(item?.price)}</TableCell>
            <TableCell>
                <CountInput item={item} qtysErr={qtysErr} setQtysErr={setQtysErr} />
            </TableCell>
            <TableCell align='right'>{toPrice(totalPrice)}</TableCell>
        </TableRow>
    )
}
