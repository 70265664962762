import { paperStyle } from '../../../../utils/constants'
import ThumbImages from './ThumbImages'
import { Stack } from '@mui/material'
import MainImage from './MainImage'
import React from 'react'

const ProductImage = ({ imgs }) => {
    const [main, setMain] = React.useState(imgs[0])
    React.useEffect(() => {
        if (imgs?.length) { setMain(imgs[0]) } else { setMain(null) }
    }, [imgs])
    const handleSelectMain = (imgIdx) => { setMain(imgs[imgIdx]) }
    
    return (
        <Stack sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '46%', ...paperStyle }}>
            <MainImage img={main} />
            <ThumbImages imgs={imgs} selectMain={handleSelectMain} />
        </Stack>
    )
}

export default ProductImage