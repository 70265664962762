import { Dashboard, ShoppingCart, People, Settings, ProductionQuantityLimitsRounded, List, LocalShipping, Redeem } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material'
import { primaryLight, secondaryLight } from '../../utils/constants'

export const boardHeight = 'calc(100vh - 100px)'

export const menus = [
	{
		icon: <ShoppingCart sx={{ color: 'secondary.light' }} />,
		name: 'Захиалга',
		route: 'orders',
	},
	{
		icon: <Redeem sx={{ color: 'secondary.light' }} />,
		name: 'Promotion',
		route: 'promotion',
	},
	{
		icon: <People sx={{ color: 'secondary.light' }} />,
		name: 'Харилцагч',
		route: 'customers',
	},
	{
		icon: <Dashboard sx={{ color: 'secondary.light' }} />,
		name: 'Бараа',
		route: 'products/list',
		subMenus: [
		{
			icon: <ProductionQuantityLimitsRounded sx={{ color: 'secondary.light' }} />,
			name: 'Жагсаалт',
			route: 'products/list',
		},
		{
			icon: <List sx={{ color: 'secondary.light' }} />,
			name: 'Ангилал',
			route: 'products/category',
		},
		]
	},
	{
	   icon: <LocalShipping sx={{ color: 'secondary.light' }} />,
	   name: 'Түгээлт',
	   route: 'shipment',
	},
	{
		icon: <Settings sx={{ color: 'secondary.light' }} />,
		name: 'Тохиргоо',
		route: 'settings',
	},
]

export const VerifyDialog = ({ msg, open, setOpen, handleOk }) => {
	const handleClose = () => { setOpen(false) }
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle><Typography sx={{ fontSize: '17px', color: primaryLight, fontWeight: 'bold' }}>{msg}</Typography></DialogTitle>
			<DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
				<Button onClick={handleOk} size='small' variant='contained' sx={{ backgroundColor: secondaryLight }}>Тийм</Button>
				<Button onClick={handleClose} size='small' variant='contained'>Үгүй</Button>
			</DialogActions>
		</Dialog>
	)
}