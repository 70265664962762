import { primaryLight, secondaryLight } from '../../../../../../../../utils/constants'
import { Box, Button, TableCell, TextField } from '@mui/material'
import DropCellMenu from '../DropCell/DropCellMenu'
import { PushPin } from '@mui/icons-material'
import OrderIdTitle from './OrderIdTitle'
import React, { useEffect } from 'react'
import { useState } from 'react'

export default function OrderNoCell({ filters, setFilters }) {
    const prevVal = filters?.find(f => f?.fKey === 'orderNo')
    const [value, setValue] = useState(null)
    const [error, setError] = useState(null)
    useEffect(() => { if (error) { setTimeout(() => { setError(null) }, 2000) } }, [error])

    const doFilter = () => {
        if (value) {
            if (!isNaN(value)) {
                if (value?.length >= 6 && parseInt(value) >= 123450) {
                    setFilters(prev => {
                        const f = { fKey: 'orderNo', fValue: value, operator: '=' }
                        if (prev?.length) {
                            return [ ...prev.map(p => p.fKey === 'orderNo' ? f : p)]
                        } else { return [f] }
                    })
                    setError(null)
                } else { setError('Захиалгын дугаар буруу байна!') }
        } else { setError('Захиалгын дугаар буруу!') } }
    }

    const handleEnter = (e) => { if (e?.code === 'Enter') { doFilter() } }

    const doClear = () => {
        setFilters(prev => prev?.filter(p => p.fKey !== 'orderNo'))
        setValue(null)
    }
    const [isPinned, setIsPinned] = useState(false)
    const handlePin = () => { setIsPinned(!isPinned) }

    return (
        <TableCell key='orderNo' sx={{ p: '2px' }}>
            <DropCellMenu
                title={<OrderIdTitle prevVal={prevVal} doClear={doClear} />}
                detail={
                    <Box sx={{ backgroundColor: 'white', boxShadow: 8, p: 3, width: '350px'}}>
                        <Box 
                            sx={{ 
                                position: 'absolute', top: 1, left: 3, 
                                '&:hover': { cursor: 'pointer' }
                            }}
                            onClick={handlePin}
                        >
                            <PushPin sx={{ color: isPinned ? secondaryLight : primaryLight }} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start'  }}>
                            <TextField
                                size='small'
                                placeholder='Захиалгын дугаар'
                                value={value||''}
                                onChange={e => { setValue(e?.target?.value) }}
                                sx={{
                                    flex: 1,
                                    '& fieldset': { borderColor: primaryLight, borderRadius: 0 },
                                    '&:hover fieldset': { borderColor: primaryLight },
                                    '& .MuiInputBase-input::placeholder': { color: primaryLight, fontSize: '13px', opacity: 1, ml: '5px' },
                                }}
                                inputProps={{ style: { padding: '3px 5px', fontSize: '13px', color: primaryLight } }}
                                onKeyDown={handleEnter}
                                error={Boolean(error)}
                                helperText={Boolean(error) ?  error :  ''}
                                autoComplete='off'
                            />
                            <Button
                                size='small' variant='contained' sx={{ p: '2px', borderRadius: 0, ml: 1, fontSize: '13px' }}
                                onClick={doFilter}
                            >Хайх</Button>
                        </Box>
                    </Box>
                }
            />
        </TableCell>
    )
}
