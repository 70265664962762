import { PAYMENT_TYPES, PROCESS_TYPES, STATUS_TYPES } from '../../../../../../constants'
import { primaryLight, secondaryLight } from '../../../../../../../utils/constants'
import { useExternalComponentRect } from '../../../../../../hooks'
import { DelmanCell, PackerCell, SellerCell } from './StaffCell'
import { TableCell, TableRow, Tooltip } from '@mui/material'
import { FilterAlt } from '@mui/icons-material'
import { useOrderListCtx } from '../../..'
import OrderNoCell from './OrderNoCell'
import DatePicker from './DatePicker'
import UserCell from './UserCell'
import DropCell from './DropCell'
import React from 'react'

const qp = { true: 'Тийм', false: 'Үгүй' }

const dropCells = ['status', 'process', 'payType', 'qp']
const staffCells = ['seller', 'delman', 'packer']
const dialogCells = ['createdOn']
const filterCells = ['orderNo', 'user'] + dropCells + staffCells + dialogCells

const dropChoices = {
    status: STATUS_TYPES,
    process: PROCESS_TYPES,
    payType: PAYMENT_TYPES,
    qp: qp
}
const getComponent = (fieldName, filters, handleFilters) => {
    if (filterCells.includes(fieldName)) {
        if (fieldName === 'orderNo') return <OrderNoCell filters={filters} setFilters={handleFilters} />
        if (fieldName === 'user') return <UserCell filters={filters} setFilters={handleFilters} />
        if (fieldName === 'createdOn') return <DatePicker />
        if (dropCells.includes(fieldName)) return <DropCell field={fieldName} choices={dropChoices[fieldName]||[]} filters={filters} setFilters={handleFilters} />
        if (staffCells.includes(fieldName)) {
            if (fieldName === 'seller') return <SellerCell />
            if (fieldName === 'delman') return <DelmanCell />
            if (fieldName === 'packer') return <PackerCell />
        }
    }
    return <TableCell key={fieldName} />
}

export default function FilterRow({ showns, hhRef }) {
    const { filters, handleFilters, hasFilter } = useOrderListCtx()
    const { height } = useExternalComponentRect({ externalRef: hhRef })

    return (
        <TableRow
            sx={{
                '& > td': {
                    m: 0, p: 0, borderBottom: '1px solid gray',
                    borderBottomColor: primaryLight, backgroundColor: 'white',
                },
                boxShadow: hasFilter ? '2px 2px 4px 2px rgba(0, 0, 0, 0.2)' : 'none',
                position: 'sticky', top: height||'initial', zIndex: 1
            }}
        >
            <TableCell align='center' sx={{ p: '2px' }}>
                <Tooltip title='Шүүж хайх мөр' placement='right'>
                    <FilterAlt sx={{ color: secondaryLight, fontSize: '20px', margin: 0 }} />
                </Tooltip>
            </TableCell>
            <TableCell colSpan={2} />
            {
                showns?.map(c => getComponent(c.field_name, filters, handleFilters))
            }
        </TableRow>
    )
}
