import { Box, Button, Dialog, Typography } from '@mui/material'
import React from 'react'

export default function EndBtn({ handleEnd, warning, setWarning }) {
    const handleClose = () => { setWarning(false) }

    return (
        <>
            <Button onClick={handleEnd} size='small' variant='contained' sx={{ p: '2px', fontSize: '12px' }}>Түгээлт дуусгах</Button>
            <Dialog open={warning} onClose={handleClose}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', p: 3 }}>
                    <Typography sx={{ mb: 2 }}>Захиалгууд хүргэгдэж дуусаагүй байна!</Typography>
                    <Typography sx={{ mb: 2 }}>Түгээлт дуусгахдаа итгэлтэй байна уу?</Typography>
                    <Box>
                        <Button 
                            variant='contained' 
                            size='small'
                            sx={{ mr: 3 }}
                            onClick={ handleEnd }
                        >Тийм</Button>
                        <Button 
                            variant='contained' 
                            size='small' 
                            sx={{ 
                                backgroundColor: 'secondary.light', 
                                '&:hover': { backgroundColor: 'secondary.main' } 
                            }}
                            onClick={ handleClose }
                        >Үгүй</Button>
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}
