import { toPrice } from '../../../../../../../../utils/functions'
import { Box, Typography } from '@mui/material'
import React from 'react'

export default function BundlePrice({ price }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 3 }}>
            <Typography sx={{ color: 'primary.main' }}>Багцын үнэ:</Typography>
            <Box 
                sx={{ 
                    fontSize: '20px', fontWeight: 700, 
                    textAlign: 'center', color: 'secondary.light',
                    textShadow: '1px 1px 1px',
                    ml: 1 
                }}
            >{price ? toPrice(price) : '-'}</Box>
        </Box>
    )
}
