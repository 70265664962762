import { Box, Button, Dialog, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { getSelectionProds } from '../../../../../../../../api/promo'
import { scrollStyle } from '../../../../../../../../utils/constants'
import { toPrice } from '../../../../../../../../utils/functions'
import { Close, Search } from '@mui/icons-material'
import { useAdminCtx } from '../../../../../..'
import { useQuery } from 'react-query'
import CheckCell from './CheckCell'
import React from 'react'

export default function SelectProduct({ selected, setSelected, isGift, promoType }) {
    const { alertWarning, setMsg } = useAdminCtx()

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }

    const [name, setName] = React.useState('')
    const { data, isFetched } = useQuery(['get_selection_prods', name], getSelectionProds, { onError: alertWarning })
    const products = data?.data?.results||[]
    //console.log("ISF: ", isFetching)

    // delayed search
    const [search, setSearch] = React.useState(null)
    const searchTimeoutRef = React.useRef(null)
    React.useEffect(() => {
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current)
            searchTimeoutRef.current = null
        }
        searchTimeoutRef.current = setTimeout(() => { setName(search) }, 650)
        return () => { clearTimeout(searchTimeoutRef.current) }
    }, [search])

    const addToSelected = (product) => {
        if (promoType === 3) { // Барааны урамшуулал дээр зөвхөн нэг бараа сонгох
            setSelected([{ ...product, qty: 1 }])
        } else {
            selected.some(s => s.id === product.id)
                ?   setSelected(selected.filter(s => s.id !== product.id))
                :   setSelected([...selected, {...product, qty: 1}])
        }
    }

    return (
        <>
            <Button variant='contained' size='small' onClick={handleOpen}>{isGift ? 'Урамшууллын бараа' : 'Бараа'}&nbsp;нэмэх</Button>
            <Dialog open={open} onClose={handleClose} maxWidth='md'>
                <Box sx={{ p: 2, width: 730, height: 680 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                            variant='standard'
                            size='small'
                            label='Барааг нэрээр хайх'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            sx={{ width: '50%', mb: 1 }}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                                ),
                            }}
                        />
                        <IconButton sx={{ p: '2px' }} onClick={handleClose}><Close /></IconButton>
                    </Box>
                    <Paper sx={{ p: 1, boxShadow: 5, display: 'flex', justifyContent: 'space-between' }}>
                        <TableContainer sx={{ height: 580, maxHeight: 580, ...scrollStyle, '&::-webkit-scrollbar': { width: 8 } }}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ '& > th': { py: '2px', px: '10px', backgroundColor: 'secondary.light', color: 'white', borderRight: '1px solid white' } }}>
                                        <TableCell width='20px' />
                                        <TableCell sx={{ px: '4px !important' }}>№</TableCell>
                                        {
                                            ['Барааны нэр', 'Баркод', 'Үнэ', 'Дуусах хугацаа'].map(
                                                (col, idx) => <TableCell key={idx}>{col}</TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        products.map((product, idx) => 
                                            <TableRow 
                                                key={idx} 
                                                sx={{ '& > td': { py: '1px', px: '10px' }, '&:hover': { backgroundColor: '#e5e5e5' }, cursor: 'pointer'}}
                                                onClick={() => {addToSelected(product)}}
                                            >
                                                <CheckCell checked={selected?.some(s => s.id === product.id)} />
                                                <TableCell sx={{ px: '4px !important' }}>{idx+1}</TableCell>
                                                <TableCell>{product.name||'-'}</TableCell>
                                                <TableCell>{product.barcode||'-'}</TableCell>
                                                <TableCell>{product.price ? toPrice(product.price) : '-'}</TableCell>
                                                <TableCell align='center'>{product.expDate||'-'}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Box>
            </Dialog>
        </>
    )
}
