import { Box, Grid, InputAdornment, MenuItem, TextField, Typography } from '@mui/material'
import { COMPANY_TYPES } from '../../../../utils/constants'
import { ArrowDropDown } from '@mui/icons-material'
import DropMenu from '../../../helpers/DropMenu'
import LicenseInput from './LicenseInput'
import LogoInput from './LogoInput'
import React from 'react'

export default function CompanyInfo({ getErrors, getFieldProps, licenseFiles, setLicenseFiles, logo, setLogo, setFieldValue, cType, handleKeyDown }) {
    //console.log("LF: ", licenseFiles)
    return (
        <Grid container>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <TextField
                        label='Байгууллагын РД'
                        inputProps={{ style: { fontSize: '15px', color: '#1b2e3c' }}}
                        InputLabelProps={{ style: { fontSize: '15px' } }}
                        name='rd'
                        {...getFieldProps('rd')}
                        {...getErrors('rd')}
                        size='small'
                        sx={{ width: '100%' }}
                        onKeyDown={handleKeyDown}
                        id='rd'
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label='Байгууллагын нэр'
                        inputProps={{ style: { fontSize: '15px', color: '#1b2e3c' }}}
                        InputLabelProps={{ style: { fontSize: '15px' } }}
                        name='name'
                        {...getFieldProps('name')}
                        {...getErrors('name')}
                        size='small'
                        sx={{ width: '100%' }}
                        onKeyDown={handleKeyDown}
                        id='name'
                    />
                </Grid>
                <Grid item xs={4}>
                    <DropMenu
                        title={
                            <TextField
                                label='Чиглэл'
                                inputProps={{ style: { fontSize: '15px', color: '#1b2e3c', cursor: 'pointer' }}}
                                InputLabelProps={{ style: { fontSize: '15px' }, shrink: Boolean(cType) }}
                                size='small'
                                value={COMPANY_TYPES?.[cType]||''}
                                {...getErrors('cType')}
                                sx={{ width: '100%', borderColor: '#1b2e3c' }}
                                InputProps={{ readOnly: true, endAdornment: (<InputAdornment><ArrowDropDown sx={{ color: '#1b2e3c' }} /></InputAdornment>)}}
                                onKeyDown={handleKeyDown}
                                id='cType'
                            />
                        }
                        detail={
                            <Box sx={{ backgroundColor: 'white', boxShadow: 8 }}>
                                {Object.keys(COMPANY_TYPES).map(
                                    t => 
                                        <MenuItem 
                                            key={`${t}`} 
                                            onClick={_ => { setFieldValue('cType', t) }} 
                                            onKeyDown={handleKeyDown}
                                            sx={{ width: '180px' }}
                                        >
                                            <Typography fontSize={'15px'} sx={{ color: '#1b2e3c' }}>{COMPANY_TYPES[t]}</Typography>
                                        </MenuItem>
                                )}
                            </Box>
                        }
                        top={38}
                        left={0}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LogoInput logo={logo} setLogo={setLogo} />
                </Grid>
                <Grid item xs={8}>
                    <LicenseInput licenseFiles={licenseFiles} setLicenseFiles={setLicenseFiles} />
                </Grid>
            </Grid>
        </Grid>
        
    )
}
