import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { scrollStyle, secondaryLight } from '../../../../../utils/constants'
import DeleteIconBtn from '../../../../helpers/buttons/DeleteIconBtn'
import { isTokenValid } from '../../../../../utils/functions'
import { delNotf } from '../../../../../api/confirm'
import React, { useState } from 'react'
import { sortBy } from 'underscore'

const cols = ['№', 'Тайлбар', 'Төрөл', 'Огноо', 'Устгах']
export default function NotfList({ notfs, refetch }) {
    const { user_id } = isTokenValid()
    const [hoverId, setHoverId] = useState(null)
    const [delSuc, setDelSuc] = useState(false)
    const handleDel = (id) => { delNotf(id).then(_ => { refetch(); setDelSuc(true) }).catch(e => { console.log("ERR: ", e) }) }
    
    return (
        <TableContainer component={'Paper'} sx={{ display: 'flex', justifyContent: 'center', ...scrollStyle, maxHeight: '600px', p: 1, pt: 0 }}>
            <Table sx={{ width: '800px', boxShadow: 3 }} stickyHeader aria-label="sticky table" size='small'>
                <TableHead
                    sx={{
                        '& > *:first-child': { borderLeft: `1px solid ${secondaryLight}` },
                        '& > *:last-child': { borderRight: `1px solid ${secondaryLight}` },
                        boxShadow: 3,
                    }}
                >
                    {cols.map((c, i) => 
                    <TableCell
                        sx={{ 
                            color: 'white', backgroundColor: secondaryLight,
                            borderColor: secondaryLight, borderLeft: '1px solid white', textAlign: 'center'
                        }}
                    >{c}</TableCell>)}
                </TableHead>
                <TableBody>
                    {
                        sortBy(notfs, 'date')?.reverse()?.map((n, i) => {
                            const day = n?.date?.split('T')?.[0]
                            const time = n?.date?.split('T')?.[1]?.split('.')[0]
                            const d = `${day} ${time}`
                            const r = n?.role === 'A' ? 'Нийлүүлэгч' : n?.role === 'PA' ? 'Эмийн сан' : n?.isPublic === true ? 'Бүгд' : 'Сонгох'
                            return <TableRow onMouseEnter={() => setHoverId(n.id)}>
                                        <TableCell width='30px'>{i+1}</TableCell>
                                        <TableCell dangerouslySetInnerHTML={{ __html: n.body}} />
                                        <TableCell align='center' width='100px'>{r}</TableCell>
                                        <TableCell align='center' width='120px'>{d}</TableCell>
                                        <TableCell align='center' width='50px'>
                                            { 
                                                n.frm === user_id
                                                    ?   <DeleteIconBtn onYes={() => handleDel(n.id)} idx={n.id} hoverId={hoverId} delSuc={delSuc} />
                                                    :   null
                                            }
                                        </TableCell>
                                    </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
