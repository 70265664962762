import { TableBody } from '@mui/material'
import Shipment from './Shipment'
import React from 'react'

export default function Shipments({ ships }) {
    return (
        <TableBody>
            {
                ships?.map((s, idx) =>
                    <Shipment key={s.id} shipment={s} idx={idx} />
                )
            }
        </TableBody>
    )
}
