import { Avatar, Box, Button, Grid, TextField, Typography } from '@mui/material'
import { SUPPLIER_ROLES, emailYup, pwdYup } from '../../../../utils/constants'
import { isTokenValid, setTokens } from '../../../../utils/functions'
import PasswordField from '../../../helpers/fields/PasswordField'
import { LogIn, isRegistered } from '../../../../api/auth'
import ClickableLink from '../../helpers/ClickableLink'
import { getToast } from '../../helpers/ToastAlert'
import { LockOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useState } from 'react'
import * as Yup from 'yup'
import React from 'react'

const emailSchema = Yup.object({ ...emailYup })
const emailPwdSchema = Yup.object({ ...emailYup, ...pwdYup })
const supRoles = SUPPLIER_ROLES.filter(r => r !== 'S')

const CheckRegistration = ({ 
        email, setEmail, setBadEmail, toLogin, setNoPwd, setIncomplete, 
        setRegisterAddress, setRegisterLicense, setRegMsg, restart
    }) => {
    const navi = useNavigate()
    const [err, setErr] = useState(null)
    const errOff = () => { setErr(null) }

    const afterLogin = (role) => {
        const rte = supRoles.includes(role) ? '/admin/orders' : '/'
        navi(rte, { state: { signedIn: true } })
    }

    const checkRegistered = (email) => {
        isRegistered(email)
            .then(res => {
                const { ema, pwd } = res?.data
                //console.log("reged/ RES: ", { ema, pwd })
                if (!ema) {
                    setBadEmail(true)
                    setRegMsg('Бүртгэлгүй имэйл хаяг байна!')
                } else {
                    setEmail(ema)
                    setBadEmail(false)
                    if (!pwd) { 
                        setNoPwd(true)
                        setRegMsg('Нууц үг үүсгэнэ үү!')
                    } else { setNoPwd(false) }
                }
            })
            .catch(_ => { setErr('Түр хүлээгээд дахин оролдоно уу!') })
    }

    const doLogin = (fVals) => {
        LogIn({ email: fVals?.email, password: fVals?.password })
            .then(res => {
                let { access_token, refresh_token } = res?.data
                const { role } = isTokenValid(access_token)
                setTokens(access_token, refresh_token)
                const r = process.env.REACT_APP_R
                const s = res.status
                const err = res.data
                if (s === 200 && r !== role) {
                    if (err?.noCmp) { setIncomplete(true); setRegMsg(err.noCmp) }
                    else if (err?.noLic) { setRegisterLicense(true); setRegMsg(err.noLic) }
                    else if (err?.noAdr) { setRegisterAddress(true); setRegMsg(err.noAdr) }
                    else if (err?.noRev) { setRegMsg(err.noRev) }
                    else { setRegMsg(null); afterLogin(role) }
                }
                if (role === r) { navi(process.env.REACT_APP_CR) }
            })
            .catch(e => {
                //console.log("ER: ", e)
                const status = e?.response?.status
                if (status === 401) { setErr('Имэйл хаяг эсвэл нууц үг буруу байна!') }
                const badPwdErr = e?.response?.data?.password?.[0]
                if (badPwdErr) { setErr(badPwdErr); console.log(badPwdErr) }
            })
    }
    
    const { handleSubmit, getFieldProps, setFieldValue, values, errors } = useFormik({
        initialValues: toLogin ? { email, password: '' } : { email },
        onSubmit: (fVals) => { toLogin ? doLogin(fVals) : checkRegistered(fVals?.email) },
        validationSchema: toLogin ? emailPwdSchema : emailSchema,
        validateOnChange: false
    })

    const handleEmailEnter = (e) => { if (e.key === 'Enter') { handleSubmit() } }
    const handlePassEnter = (e) => { if (e.key === 'Enter') { handleSubmit() } }

    // input auto focus
    useEffect(() => {
        if (toLogin) {
            document.getElementById('pwd')?.focus()
        } else { setFieldValue('password', '') }
    }, [toLogin])
    useEffect(() => { document.getElementById('ema')?.focus() }, [])

    return (
        <>
            <Avatar sx={{ bgcolor: 'secondary.light' }}><LockOutlined /></Avatar>
            <Typography fontSize={'20px'} marginBottom={5} marginTop={2}>Нэвтрэх</Typography>
            {
                <Box>
                    <TextField
                        id={'ema'}
                        size='small'
                        fullWidth
                        label="Имэйл хаяг"
                        name='email'
                        { ...getFieldProps('email') }
                        error={Boolean(errors?.email)}
                        helperText={Boolean(errors?.email) ? errors.email : ' '}
                        InputLabelProps={{ style: { color: toLogin ? '#000' : '#666' } }}
                        sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "#000" } }}
                        disabled={toLogin}
                        onKeyDown={ handleEmailEnter }
                    />
                    {
                        toLogin &&
                            <PasswordField
                                id={'pwd'}
                                required
                                size='small'
                                fullWidth
                                { ...getFieldProps('password') }
                                error={Boolean(errors?.password)}
                                helperText={ errors?.password ? errors.password : ' ' }
                                onKeyDown={ handlePassEnter }
                            />
                    }
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2, mb: 1 }}
                        onClick={ () => handleSubmit() }
                    >
                        { toLogin ? 'Нэвтрэх' : 'Үргэлжлүүлэх' }
                    </Button>
                    <Grid container>
                        <Grid item xs>{ toLogin && <ClickableLink label={'Буцах'} onClick={restart} /> }</Grid>
                        <Grid item>
                            {
                                toLogin
                                    ?   <ClickableLink label={'Нууц үг сэргээх'} onClick={() => { setNoPwd(true) }} />
                                    :   <ClickableLink label={'Бүртгүүлэх'} onClick={() => { navi('/signup') }} />
                            }
                        </Grid>
                    </Grid>
                    { getToast(Boolean(err), errOff, err, 'warning', 2000) }
                </Box>
            }
        </>
    )
}

export default CheckRegistration