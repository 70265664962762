import { isTokenValid } from '../../../../utils/functions'
import { getDelmans } from '../../../../api/staff'
import { Box, Tab, Tabs } from '@mui/material'
import ShipmentHistory from './History'
import CurrentShipment from './Current'
//import DeliveryMap from './GoogleMap'
import 'leaflet/dist/leaflet.css'
import '../../../../style.css'
import React from 'react'

const ShipCtx = React.createContext()
export const useShipCtx = () => React.useContext(ShipCtx)

const Shipment = () => {
    const { role } = isTokenValid()
    const isDelman = role === 'D'
    const [tab, setTab] = React.useState(0)
    const handleChange = (e, v) => { setTab(v) }

    const [staffs, setStaffs] = React.useState([])
    const staffNames = staffs?.map(s => ({ id: s.id, name: s.name }))

    React.useEffect(() => {
        getDelmans()
            .then(res => { if (res?.data) { setStaffs(res.data) } })
            .catch(err => { console.log("staffs ERR: ", err) })
    }, [])

    const tabRef = React.useRef()
    const tabHeight = tabRef?.current?.getBoundingClientRect()?.height||0

    return (
        <ShipCtx.Provider value={{ isDelman, staffNames }}>
            <Tabs value={tab} onChange={handleChange} aria-label='tabs' ref={tabRef}>
                <Tab label='Өнөөдрийн түгээлт' value={0} />
                {/* {!isDelman && <Tab label='Түгээлтийн явц' value={1} />} */}
                <Tab label='Түгээлтийн түүх' value={2} />
            </Tabs>
            <Box sx={{ height: `calc(100% - ${tabHeight}px)` }}>
                { tab === 0 && <CurrentShipment isDelman={isDelman} />}
                {/* { tab === 1 && <DeliveryMap staffNames={staffNames} />} */}
                { tab === 2 && <ShipmentHistory />}
            </Box>
        </ShipCtx.Provider>
    )
}

export default Shipment
