import React, { createContext, useContext, useEffect, useState } from 'react';
import { toPrice } from '../utils/functions';

// BroadcastChannel
const BcCtx = createContext(null)

export const BcProvider = ({ children }) => {
	const [msg, setMsg] = useState(null)

	useEffect(() => {
        const pharmoChannel = new BroadcastChannel('pharmo-channel');
		// show system notif
		pharmoChannel.onmessage = (e) => {
            const data = e?.data
            setMsg(e?.data)
            if (data?.type === 'notif') {
                if (data.body?.type === 'OC') {
                    const d = data.body?.data
                    const wd = d?.who?.split(' ')
                    const who = wd?.[0] === 'seller' ? 'Борлуулагч' : 'Захиалагч'
                    const td = d?.total?.split(' ')
                    const price = td?.[0] ? toPrice(td[0]) : ''
                    const pt = td?.[1] === 'C' ? 'Бэлнээр' : 'Зээлээр'
                    new Notification(data.body?.title, {
                        body: `${who}:\t${wd?.[1]}\nҮнийн дүн:\t${price}\nТөлбөр:\t\t${pt}`,
                        icon: '/Pharmo.ico'
                    })
                }
/*                 if (data.body?.type === 'OO') {
                    new Notification(data.body?.title, {
                        body: `Захиалгын дугаар:\t${data.body.data?.orderNo}\nНийлүүлэгч:\t\t${data.body.data?.supplier}`,
			            icon: '/Pharmo.ico'
                    })
                } */
            }
		}
		return () => { pharmoChannel.close() }
	}, [])

	return (<BcCtx.Provider value={{ msg, setMsg }}>{children}</BcCtx.Provider>)
}

export const useBcCtx = () => useContext(BcCtx)
