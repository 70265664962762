import { TableFooter, TableRow, TableCell, Button } from '@mui/material'
import { primaryMain } from '../../utils/constants'
import React from 'react'

const TablePaginationFooter = ({ rppOptions, page, pageSize, count, handlePage, handlePageSize }) => {
    const labelDisplayedRows = () => {
        const isAll = page === 1 && count <= pageSize
        const isLastPage = page === Math.ceil(count / pageSize)
        //console.log("PAGE: ", page, pageSize, count, isLastPage)
        const lastPageCnt = page === 1 ? 0 : (page - 1) * pageSize
        const fromTo = isAll ? ' ' : isLastPage ? `ас ${lastPageCnt+1} - ${count}` : `ас ${lastPageCnt+1}-${page*pageSize} `
        if (page < 1) { return 'aaa' }
        if (page === 1 && count <= pageSize) { return  `Нийт ${count} захиалга байна.`}
        return `Нийт ${count} захиалга${fromTo}${ isAll ? ' бүгд' : ' хүртэлх нь'} харагдаж байна.`
    }

    return (
        <TableFooter style={{ position: 'sticky', bottom: 0, background: 'white', boxShadow: '0 0 1px 1px #e1e1e1', padding: 0 }}>
            <TableRow>
                <TableCell colSpan={8} sx={{ fontSize: '15px', color: primaryMain, textAlign: 'right' }}>
                    {labelDisplayedRows()}
                </TableCell>
                <TableCell colSpan={3} sx={{ fontSize: '15px', color: primaryMain, textAlign: 'right' }}>
                    <Button 
                        size='small' variant='outlined' sx={{ mr: 2, p: '1px 4px 1px 4px', textTransform: 'capitalize', fontSize: '15px' }}
                        disabled={page === 1}
                        onClick={() => handlePage(page - 1)}
                    >
                        Өмнөх хуудас
                    </Button>
                    <Button 
                        size='small' variant='outlined'
                        disabled={page === Math.ceil(count / pageSize)}
                        sx={{ p: '1px 4px 1px 4px', textTransform: 'capitalize', fontSize: '15px' }}
                        onClick={() => handlePage(page + 1)}
                    >
                        Дараагийн хуудас
                    </Button>
                </TableCell>
            </TableRow>
        </TableFooter>
    )
}

export default TablePaginationFooter