import { TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'

const cols = ['№', 'Захиалгын дугаар', 'Захиалагч', 'Төлөв', 'Хүргэсэн цаг', 'Тэмдэглэл']

export default function ShipDetail({ detail, colLen }) {
    //console.log("detail: ", detail)

    return (
        <TableRow sx={{ '& > td': { p: '3px', pl: 5 } }}>
            <TableCell colSpan={colLen||6} sx={{ border: '1px solid', borderColor: 'secondary.light', borderTop: '1px solid transparent' }}>
                <TableHead>
                    <TableRow sx={{ '& > th': { borderBottomWidth: '2px', fontWeight: 'bold', p: '3px 10px', textAlign: 'center', zIndex: 0 } }}>
                        {cols.map(c => <TableCell>{c}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        detail?.map((item, idx) => {
                            const ig = item.givenOn
                            const parts = ig ? ig.split(' ') : null
                            const t = parts?.length > 1 ? parts[1] : null
                            const tps = t ? t.split(':') : null
                            const ts = tps?.length > 1 ? `${tps[0]} цаг ${tps[1]} минут` : null
                            return <>
                                    <TableRow
                                        sx={{ 
                                            '& > td': {
                                                p: '2px 10px', textAlign: 'center'
                                            }
                                        }}
                                    >
                                        <TableCell>{idx+1}</TableCell>
                                        <TableCell>{item.orderNo}</TableCell>
                                        <TableCell>{`${item.user} (${item.branch||''})`}</TableCell>
                                        <TableCell>{item.process === 'A' ? 'Хүргэгдсэн' : '-'}</TableCell>
                                        <TableCell>{ts ? ts : '-'}</TableCell>
                                        <TableCell>{item.note || '-'}</TableCell>
                                    </TableRow>
                                </>
                        })
                    }
                </TableBody>
            </TableCell>
        </TableRow>
    )
}
