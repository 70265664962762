import { AddCircleOutlineTwoTone, ChevronRight, DeleteForeverTwoTone, EditTwoTone, ExpandMore, SaveTwoTone } from '@mui/icons-material'
import { Box, Button, Dialog, IconButton, ListItem, TextField, Tooltip, Typography } from '@mui/material'
import { removeCategory, updateCategory } from '../../../../../../api/category'
import { secondaryLight } from '../../../../../../utils/constants'
import { getToast } from '../../../../../auth/helpers/ToastAlert'
import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

export default function CategoryItem({ categoryItem, selected, onSelect, refetchCategories, addClicked, setAddClicked, passTopBtm, toMove, isFetching }) {
    const isSelected = categoryItem.id === selected?.id
    const handleExpand = () => {
        if (!toMove) {
            if (!isSelected) { onSelect({ ...categoryItem, expanded : true }) }
            else { onSelect({ ...selected, expanded: !selected?.expanded }) }
        }
    }
    const iconStyle = { fontSize: '18px', fontWeight: 'bolder', color: isSelected ? 'secondary.main' : 'primary.dark' }

    const [toDelete, setToDelete] = useState(null)
    const handleClose = () => { setToDelete(null) }
    // msg
    const [msg, setMsg] = useState(null)
    const msgOff = () => { setMsg(null) }
    // ангилал устгах
    const handleDelete = () => {
        removeCategory(toDelete?.id)
            .then(res => {
                refetchCategories()
                handleClose()
            })
            .catch(e => setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }))
    }

    const [toEdit, setToEdit] = useState(null)
    // ангиллын нэр өөрчлөх
    const handleEdit = () => {
        updateCategory(toEdit?.id, { name: toEdit?.name })
            .then(_ => {
                setToEdit(null)
                refetchCategories()
                setMsg({ m: 'Амжилттай!', s: 'success' })
            })
            .catch(_ => { setMsg({m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning'}) })
    }
    const handleEditKeyDown = (e) => { if (e.code === 'Enter') { handleEdit() } }

    const handleAdd = (e) => { setAddClicked(!addClicked) }

    useEffect(() => {
        if (toEdit && !isSelected) { setToEdit(null) }
    }, [isSelected, toEdit])

    // drag & drop
    const cir = useRef(null)
    useEffect(() =>{
        if (toMove) {
            if (cir.current) {
                const domRect = cir.current.getBoundingClientRect()
                passTopBtm({ id: categoryItem?.id, top: domRect?.top, bottom: domRect?.bottom })
            }
            onSelect({})
        }
    }, [toMove, isFetching])

    const handleDragStart = (e) => { e.dataTransfer.setData('text/plain', JSON.stringify(categoryItem.id)) }

    const [isOvered, setIsOvered] = useState(false)
    const handleDragEnter = (e) => { setIsOvered(true) }
    const handleDragLeave = (e) => { setIsOvered(false) }
    useEffect(() => { if(!isFetching) { handleDragLeave() } }, [isFetching])
  
    return (
        <Box 
            sx={{ 
                position: 'relative', width: '100%',
                ml: `${categoryItem.parents_num * 20}px`,
                border: '1px solid transparent',
                borderColor: isOvered ? secondaryLight : 'transparent',
                '&:hover': { borderColor: toMove ? secondaryLight : 'transparent' },
                transition: 'all .1s ease',
            }}
            ref={cir}
            draggable
            onDragStart={handleDragStart}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragEnd={handleDragLeave}
        >
            {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 1500)}
            <Dialog open={Boolean(toDelete)} onClose={handleClose}>
                <Box sx={{ p: 2, border: 1, borderRadius: 1 }}>
                    <Typography textAlign='center'>
                        <span style={{fontWeight: 'bold'}}>
                            {toDelete?.name}
                        </span> нэртэй категорыг утсгах гэж байна. <br/>Итгэлтэй байна уу?
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <Button onClick={handleDelete} variant="contained" sx={{ mr: 2 }}>Тийм</Button>
                        <Button onClick={handleClose} variant="outlined">Үгүй</Button>
                    </Box>
                </Box>
            </Dialog>
            {
                (toEdit && isSelected)
                    ?   <TextField
                            size='small'
                            value={toEdit?.name||''}
                            onChange={e => setToEdit({...toEdit, name: e.target.value})}
                            onKeyDown={e => handleEditKeyDown(e)}
                            inputProps={{ style: { fontSize: '15px', padding: '4px', paddingLeft: '20px' } }}
                            sx={{ width: '100%' }}
                        />
                    :   <ListItem
                            sx={{
                                p: 0, cursor: 'pointer', borderRadius: 1,
                                color: isSelected ? 'secondary': 'black',
                                backgroundColor: isSelected ? '#e1e2e3' : 'white',
                            }}
                            onClick={handleExpand}
                        >          
                            {!toMove && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {
                                    categoryItem?.children?.length > 0
                                        ? isSelected && (selected.expanded || categoryItem?.childs?.includes(selected?.id))
                                                ? <ExpandMore sx={{ ...iconStyle }}/>
                                                : <ChevronRight sx={{ ...iconStyle }}/>
                                        : <ExpandMore  sx={{ color: 'transparent', fontSize: '18px', fontWeight: 'bolder' }} />
                                }
                            </Box>}
                            <Typography variant="body1" fontSize='15px' fontWeight='bold' padding={'3px'}>
                                {categoryItem.name}
                            </Typography>
                        </ListItem>
            }
            {
                isSelected
                    ?   <Box sx={{ position: 'absolute', verticalAlign: 'center', zIndex: 100, top: 0, right: 0 }}>
                            {
                            toEdit
                                ?   <IconButton onClick={() => handleEdit()} sx={{ mr: 2, p: '5px' }}>
                                        <Tooltip title='Хадгалах' placement='top-start' arrow>
                                            <SaveTwoTone sx={{ color: 'secondary.main', fontSize: '20px' }} />
                                        </Tooltip>
                                    </IconButton>
                                :   <Box>
                                        <IconButton onClick={handleAdd} sx={{ p: '5px' }}>
                                            <Tooltip title='Дэд ангилал нэмэх' placement='top-start' arrow>
                                                <AddCircleOutlineTwoTone sx={{ color: 'secondary.main', fontSize: '20px' }} />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton onClick={() => setToEdit(categoryItem)} sx={{ p: '5px', ml: '4px' }}>
                                            <Tooltip title='Ангилалын нэр өөрчлөх' placement='top-start' arrow>
                                                <EditTwoTone sx={{ color: 'secondary.main', fontSize: '20px' }} />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton onClick={() => setToDelete(categoryItem)} sx={{ p: '5px', ml: '4px', mr: 2 }}>
                                            <Tooltip title='Ангилал устгах' placement='top-start' arrow>
                                                <DeleteForeverTwoTone sx={{ color: 'secondary.main', fontSize: '20px' }} />
                                            </Tooltip>
                                        </IconButton>
                                    </Box>                    
                            }
                        </Box>
                    :   null
            }
        </Box>
    )
}
