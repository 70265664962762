import AxiosWithToken from "../global/axiosBase"

export const getCategories = () => {
    return AxiosWithToken.get('product_category/')
}
export const addCategory = (categoryInfo) => {
    return AxiosWithToken.post('product_category/', {...categoryInfo})
}
export const updateCategory = (categoryId, categoryData) => {
    return AxiosWithToken.patch(`product_category/${categoryId}/`, categoryData)
}
export const removeCategory = (pk) => {
    return AxiosWithToken.delete(`product_category/${pk}/`)
}