import AddressForm from '../../../../../auth/RegisterAddress/AddressForm'
import { Box, Typography } from '@mui/material'
import React from 'react'

export default function Address({ handleAddressFieldValue, values, errors }) {
    return (
        <Box sx={{ width: '500px', ml: 1 }}>
            <Typography sx={{ mb: 1 }}>Салбарын хаяг</Typography>
            <AddressForm setFieldValue={handleAddressFieldValue} values={values} errors={errors} />
        </Box>
    )
}
