import { startShipment } from '../../../../../../../../api/shipment'
import { Button, TableCell } from '@mui/material'
import React, { useState } from 'react'

export default function StartCell({ startTime, shipmentId, setMsg }) {
    const [st, setSt] = useState(startTime||'')
    //console.log("ST: ", st, typeof st)
    const handleStart = () => {
        startShipment({ shipmentId })
            .then(res => {
                setMsg({ s: 'success', m: 'Түгээлт эхэллээ.' })
                setSt(res?.data)
            })
            .catch(err => { console.log("ERR: ", err); setMsg({ s: 'warning', m: 'Түр хүлээгээд дахин оролдоно уу!' }) })
            .finally(() => {})
    }
    const st1 = st ? st.split(':') : []
    const timeStr = st1?.length >= 2 ? `${st1[0]} цаг ${st1[1]} минут` : ''

    return (
        <TableCell width='160px'>
            {
                st
                    ?   timeStr
                    :   <Button onClick={handleStart} size='small' variant='contained' sx={{ p: '2px', fontSize: '12px' }}>Түгээлт эхлүүлэх</Button>
            }
        </TableCell>
    )
}
