import { TableCell, TextField, Box, IconButton, Tooltip } from '@mui/material'
import { primaryLight, secondaryLight } from '../../../../../../utils/constants'
import { updatePartner } from '../../../../../../api/company'
import { toPrice } from '../../../../../../utils/functions'
import { Edit, Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { keys } from 'underscore'

export default function DebtLimitCell({ row, setPartners }) {
    const [dl, setDl ] = useState(row?.debtLimit||0)
    const handleChange = (e) => {
        if (/^\d+$/.test(e.target.value)) {
            setDl(e.target.value)
        }
    }
    const [isEditing, setIsEditing] = useState(false)
    const handleEdit = () => { setIsEditing(!isEditing) }
    const handleSave = () => {
        updatePartner(row?.id, { debtLimit: dl })
            .then(res => {
                if (res?.data && keys(res.data).includes('debtLimit')) {
                    setDl(res.data.debtLimit||0.00)
                    setPartners(prev => prev.map(p => p.id === row?.id ? { ...p, ...res.data } : p) || null)
                    setIsEditing(false)
                }
            })
            .catch(e => { console.log("ERR: ", e) })
    }
    const handleClick = () => { isEditing ? handleSave() :  handleEdit() }
    const handleEnter = (e) => { if (e.key === 'Enter') handleSave() }

    return (
        <TableCell>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                <TextField
                    disabled={!isEditing}
                    value={isEditing ? parseInt(dl) : `${toPrice(dl)}`}
                    onChange={handleChange}
                    size='small'
                    inputProps={{ style: { padding: '2px', paddingLeft: '8px', fontSize: '15px' } }}
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: primaryLight,
                      },
                    }}
                    onKeyDown={handleEnter}
                />
                <Tooltip title={isEditing ? 'Хадгалах' : 'Өөрчлөх'} placement='right'>
                    <IconButton sx={{ p: '4px' }} onClick={handleClick}>
                        { isEditing ? <Save sx={{ color: secondaryLight }} /> : <Edit sx={{ color: secondaryLight }} /> }
                    </IconButton>
                </Tooltip>
            </Box>
        </TableCell>
    )
}
