import { Box, Typography } from '@mui/material'
import GiftProductBox from './GiftProductBox'
import TotalPrice from './TotalPrice'
import EndDate from './EndDate'
import React from 'react'
import Name from './Name'

export default function PurchasePromo({ promo }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Name name={promo?.name} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ textAlign: 'center', p: 1, my: 3, width: '500px', color: 'primary.main', }}>{promo?.desc}</Typography>
            </Box>
            <TotalPrice promo={promo} />
            {promo?.has_gift && promo?.gift && <GiftProductBox products={promo?.gift} />}
            {promo?.end_date && <EndDate endDate={promo?.end_date} />}
        </Box>
    )
}
