import { secondaryLight } from '../../../../../../../../utils/constants'
import { toPrice } from '../../../../../../../../utils/functions'
import { TableCell } from '@mui/material'
import React from 'react'

export default function ExpenseCell({ expense }) {
    return (
        <TableCell border={1}>
            {
                expense
                    ?   toPrice(expense)
                    :   '-'
            }
        </TableCell>
    )
}