import { Box, Typography } from '@mui/material'
import React from 'react'
import Basket from './Basket'

export default function BasketDetail({ basket, goToOrder }) {
    const totalCount = basket?.items?.length

    return (
        <Box sx={{ width: '350px', backgroundColor: 'white', boxShadow: 8, p: '4px' }}>
            {
                Boolean(totalCount)
                    ?   <Box>
                            <Typography 
                                variant='body2'
                                textAlign='center'
                                sx={{ 
                                    backgroundColor: 'secondary.light', 
                                    color: 'white', 
                                    p: '4px'
                                }}
                            >
                                Сагсанд нийт {totalCount} бараа байна.
                            </Typography>
                            <Basket basket={basket} goToOrder={goToOrder} />
                        </Box>
                    :   <Box 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                borderBottom: '1px solid', 
                                borderBottomColor: 'secondary.light',
                                backgroundColor: '#f1f1f1'
                            }}
                        >
                            <Typography
                                width='100%'
                                textAlign='center'
                                fontSize='14px'
                                color='primary.main'
                                p={1}
                                sx={{ mb: 1 }}
                            >
                                Захиалгын сагс хоосон байна!
                            </Typography>
                        </Box> 
            }
        </Box>        
    )
}
