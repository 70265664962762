import { FormControl, FormControlLabel, Switch } from '@mui/material'
import React from 'react'

export default function ShowAllSwitch({ title, fieldName, state, handleChange }) {
    return (
		<FormControl component="fieldset" variant="standard">
			<FormControlLabel
				control={<Switch checked={state?.[fieldName]} onChange={handleChange} name={fieldName} />}
				label={title}
			/>
		</FormControl>
    )
}
