import PasswordField from '../../../../../helpers/fields/PasswordField'
import { isTokenValid } from '../../../../../../utils/functions'
import { checkPin } from '../../../../../../api/order'
import { usePromoOrderCtx } from '../../..'
import { Box, Button } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React from 'react'

const validationSchema = Yup.object({ 'pin': Yup.string().matches(/^\d{4}$/, '4 оронтой тоо байх ёстой!').required('Пин кодоо оруулна уу!') })

export default function CheckPin({ boxOff }) {
    const { createOrder, setMsg } = usePromoOrderCtx()
    const { pc } = isTokenValid()

    const handleCheckPin = (vals) => {
        const { pin } = vals
        checkPin({ pin })
            .then(_ => { createOrder(); boxOff(); })
            .catch(e => {
                if (e?.response?.status === 406) {
                    setMsg({ m: 'Пин код буруу байна!', s: 'warning' })
                }
            })
    }

    const { handleSubmit, errors, values, getFieldProps } = useFormik({
        initialValues: { pin: '' },
        onSubmit: (vals) => { handleCheckPin(vals) },
        validationSchema,
        validateOnChange: false
    })
    
    const handleEnter = (e) => {
        if (e.code === 'Enter') {
            e.preventDefault()
            handleCheckPin(values)
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', mt: 2, mb: 1, pl: 1, pr: 1 }}>
            <PasswordField
                autoFocus
                label='Пин код'
                placeholder={pc ? '' : '1234'}
                sx={{ width: '120px', height: '60px' }}
                inputProps={{ style: { paddingLeft: '8px' } }}
                name='pin'
                { ...getFieldProps('pin') }
                variant='standard'
                onKeyDown={ handleEnter }
                error={Boolean(errors?.pin)}
                helperText={Boolean(errors?.pin) ? errors.pin : ' '}
            />
            <Button onClick={handleSubmit} variant='contained' size='small' sx={{ mt: '14px', height: 'fit-content', borderRadius: 0 }}>Баталгаажуулах</Button>
        </Box>
    )
}
