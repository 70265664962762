import { Box, IconButton, ListItemText, Typography } from '@mui/material'
import { removeFromBasket } from '../../../../../../../api/basket'
import { useAuthCtx } from '../../../../../../../context/AuthCtx'
import { toPrice } from '../../../../../../../utils/functions'
import { Close } from '@mui/icons-material'
import React from 'react'


export default function BasketItem ({ item, idx }) {
    const { fetchBasket } = useAuthCtx()
    const removeItem = (itemId) => {
        removeFromBasket(itemId)
            .then(_ => { fetchBasket() })
            .catch(e => { console.log("ERR basket remove: ", e) })
    }

    return (
            <Box
                sx={{
                    display: 'flex', flexDirection: 'row',
                    ml: 1, p: '2px', position: 'relative',
                    borderBottom: '1px solid', borderBottomColor: '#c1c1c1',
                    '&:hover': { backgroundColor: '#e1e1e1', borderBottomColor: 'secondary.main' }
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', pr: 4 }}>
                    <ListItemText
                        primary={
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography fontSize='15px' color='primary.main' noWrap marginRight={1}>
                                    {idx + 1}.
                                </Typography>
                                <Typography fontSize='15px' color='primary.main' noWrap>
                                    {item?.product_name}
                                </Typography>
                            </Box>
                        }
                        secondary={
                            <Typography sx={{ color: 'secondary.main', fontSize: '15px', marginLeft: 2 }}>
                                {toPrice(item?.price)}
                            </Typography>
                        }
                    />
                </Box>
                <IconButton 
                    sx={{ position: 'absolute', right: 5, top: 5, p: 0 }}
                    onClick={() => removeItem(item?.id)}
                >
                    <Close sx={{ color: 'secondary.main', fontSize: '16px' }} />
                </IconButton>
            </Box>
    )
}