import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { addBasketItemBulk, deleteBasketTemplet, getBasketTemplets } from '../../../api/basket'
import { paperStyle, secondaryLight } from '../../../utils/constants'
import { isTokenValid, toPrice } from '../../../utils/functions'
import { useLocation, useNavigate } from 'react-router-dom'
import { getToast } from '../../auth/helpers/ToastAlert'
import { useAuthCtx } from '../../../context/AuthCtx'
import React, { useEffect, useState } from 'react'
import { pickSupplier } from '../../../api/auth'
import DeleteBtn from './DeleteBtn'

const rows = ['№', 'Загварын нэр', 'Нийлүүлэгч', 'Бараанууд', 'Захиалах', 'Устгах']
export default function BaskTemps() {
    const loc = useLocation()
    const inOrder = loc?.pathname === '/order'

    const { supplier } = isTokenValid()
    const { fetchBasket, setSupplierId } = useAuthCtx()
    const [msg, setMsg] = useState(null)
    const msgOff = () => { setMsg(null) }

    const [bts, setBts] = useState([])
    const navi = useNavigate()

    const fetchBts = () => {
        getBasketTemplets()
            .then(res => { setBts(res?.data) })
            .catch(e => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) })
    }
    useEffect(() => { fetchBts() }, [])

    const addBulk = (items, onSuccess) => {
        addBasketItemBulk({ items })
                .then(_ => { onSuccess() })
                .catch(e => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) })
    }
    const toBasket = (items, supplierId) => {
        if (supplierId === parseInt(supplier)) {
            addBulk(items, () => { fetchBasket(); setMsg({ m: 'Амжилттай!', s: 'success' }) })
        } else { 
            setMsg({ m: 'Сагс болон загварын нийлүүлэгч тохирохгүй байна!', s: 'warning' })
        }
    }
    //console.log("BasketItems: ", bts)

    const toOrder = (items, supplierId) => {
        if (supplierId === parseInt(supplier)) {
            addBulk(items, () => { navi('/order') })
        } else {
            pickSupplier(supplierId).then(res => {
                setSupplierId(res?.data?.id)
                if (res?.data?.access_token && res?.data?.refresh_token) {
                    window.localStorage.setItem('access_token', res?.data?.access_token)
                    window.localStorage.setItem('refresh_token', res?.data?.refresh_token)
                }
                addBulk(items, () => { navi('/order') })
            }).catch(e => { console.log("Pick supplier err: ", e) })
        }
    }

    const deleteTemplet = (id) => {
        deleteBasketTemplet(id)
            .then(res => { setMsg({ m: 'Амжилттай!', s: 'success' }); fetchBts() })
            .catch(e => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning'}) })
    }

    const [hovId, setHovId] = useState(null)

    return (
        <Box sx={{ p: 4 }}>
            { getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 1500) }
            <TableContainer sx={{ ...paperStyle }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ '& > th': { backgroundColor: secondaryLight, color: 'white', borderRight: '1px solid white', p: '4px', fontSize: '15px', textAlign: 'center' } }}>
                            {rows.map(r => <TableCell key={`${r}`}>{r}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody onMouseLeave={() => { setHovId(null) }}>
                        {
                            bts?.map((bt, idx) => 
                            <TableRow 
                                key={`${idx}`} 
                                sx={{ '& > td': { p: '6px' }, '&:hover': { backgroundColor: '#e1e1e1' } }}
                                onMouseEnter={() => setHovId(bt.id)}
                            >
                                <TableCell>{idx+1}</TableCell>
                                <TableCell>{bt.name}</TableCell>
                                <TableCell>{bt.supplier.name}</TableCell>
                                <TableCell sx={{ minWidth: '350px' }}>
                                    {
                                        bt?.items?.map(
                                            (item, idx) => (
                                                <TableRow key={`${idx}`} sx={{ '& > td': { p: '4px' } }}>
                                                    <TableCell sx={{ width: '30px' }}>{idx+1}</TableCell>
                                                    <TableCell sx={{ width: '200px' }}>{item.name}</TableCell>
                                                    <TableCell sx={{ width: '120px', textAlign: 'right'}}>{toPrice(item.price)}</TableCell>
                                                </TableRow>
                                            )
                                        )
                                    }
                                </TableCell>
                                <TableCell sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Button 
                                        size='small' 
                                        variant='contained' 
                                        sx={{ p: '4px', fontSize: '12px',
                                            visibility: hovId === bt.id ? 'visible' : 'hidden',
                                            transition: 'all 0.05s linear'
                                        }}
                                        onClick={() => toBasket(bt.items.map(i => i.id), bt?.supplier?.id)}
                                    >Сагсанд нэмэх</Button>
                                    {!inOrder && <Button 
                                        size='small' 
                                        variant='contained' 
                                        sx={{ p: '4px', fontSize: '12px',
                                            visibility: hovId === bt.id ? 'visible' : 'hidden',
                                            transition: 'all 0.05s linear'
                                        }}
                                        onClick={() => toOrder(bt.items.map(i => i.id), bt?.supplier?.id)}
                                    >Захиалах үүсгэх</Button>}
                                </TableCell>
                                <TableCell>
                                    <DeleteBtn
                                        onYes={() => { deleteTemplet(bt.id) }}
                                        isShow={hovId === bt.id}
                                    />
                                </TableCell>
                            </TableRow>)
                        }
                        {
                            bts?.length === 0 && <TableCell colSpan={6} sx={{ color: '#660000' }}>Одоогоор хадгалсан загвар байхгүй байна.</TableCell>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}