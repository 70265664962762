import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { secondaryLight } from '../../../../../utils/constants'
import { getTodayShipment } from '../../../../../api/shipment'
import React, { useEffect, useState } from 'react'
import Shipments from './Shipments'
import { useAdminCtx } from '../../..'

const cols = [ '№', '', 'Түгээгч', 'Эхэлсэн', 'Дууссан', 'Захиалгын тоо', 'Явц', 'Зарлага']
export default function CurrentShipment({ isDelman }) {
    const { alertWarning } = useAdminCtx()
    const [ships, setShips] = useState([])
    useEffect(() => {
        getTodayShipment()
            .then(res => { setShips(res?.data?.results) })
            .catch(err => { console.log("ERR: ", err); alertWarning() })
    }, [])

    return (
        <Box>
            <TableContainer sx={{ p: 1, width: '1000px' }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ '& > th': { color: 'white', textAlign: 'center', backgroundColor: secondaryLight, borderRight: '1px solid white', p: '4px' } }}>
                            {cols.map(c => <TableCell key={c}>{(isDelman && c === '№') ? '': c}</TableCell>)}
                        </TableRow>
                    </TableHead>
                        {
                            ships?.length > 0
                                ?   <Shipments ships={ships} />
                                :   <TableBody>
                                        <TableRow sx={{ m: 2 }}>
                                            <TableCell colSpan={cols?.length} sx={{ textAlign: 'center' }}>Одоогоор түгээлт байхгүй!</TableCell>
                                        </TableRow>
                                    </TableBody>
                        }
                </Table>
            </TableContainer>
        </Box>
    )
}