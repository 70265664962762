import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { scrollStyle } from '../../../../../../../../../utils/constants'
import { toPrice } from '../../../../../../../../../utils/functions'
import Return from './Cells/Return'
import React from 'react'

const itemCols = ['№', 'Барааны нэр', 'Үнэ', 'Захиалсан', 'Буцаасан', 'Хүлээн авсан', 'Нийт үнэ']
export default function ShipItems({ inItems, setItems, cl, disabled }) {
    const { totalPrice, totalCount } = inItems?.reduce((acc, cur) => {
        if (!acc['totalPrice']) { acc['totalPrice'] = 0 }
        if (!acc['totalCount']) { acc['totalCount'] = 0 }
        acc['totalPrice'] = acc['totalPrice'] + cur.itemPrice * cur.itemQty
        acc['totalCount'] = acc['totalCount'] + cur.itemQty
        return acc
    }, {})

    return (
        <TableRow>
            <TableCell colSpan={cl} sx={{ p: 0 }}>
                <TableContainer sx={{ ...scrollStyle, '&::-webkit-scrollbar': { height: 8 } }}>
                    <Table sx={{ mb: 2, ml: 2 }}>
                        <TableHead>
                            <TableRow sx={{ '& > th': { fontWeight: 'bold', p: '3px', textAlign: 'center' } }}>
                                {
                                    itemCols.map(c => <TableCell>{c}</TableCell>)
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                inItems?.map(
                                    (item, idx) =>
                                        <TableRow sx={{ '& > td': { p: '2px 6px', textAlign: 'center' } }}>
                                            <TableCell width='30px'>{idx+1}</TableCell>
                                            <TableCell align='left'>{item.itemName}</TableCell>
                                            <TableCell>{item.itemPrice ? toPrice(item.itemPrice) : '-'}</TableCell>
                                            <TableCell>{item.iQty}</TableCell>
                                            <Return itemId={item.itemId} iQty={item.iQty} rQty={item.rQty} disabled={disabled} setItems={setItems} />
                                            <TableCell>{item.itemQty}</TableCell>
                                            <TableCell>{item.itemTotalPrice ? toPrice(item.itemTotalPrice) : '-'}</TableCell>
                                        </TableRow>
                                )
                            }
                            <TableRow sx={{ '& > td': { textAlign: 'center', fontWeight: 'bold', p: '3px' } }}>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell>{totalCount??''}</TableCell>
                                <TableCell>{totalPrice ? toPrice(totalPrice) : ''}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </TableCell>
        </TableRow>
    )
}
