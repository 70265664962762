import { apiUrl } from "../global/axiosBase"

// localStorage
export const getItm = (key) => window.localStorage.getItem(key)
export const setItm = (key, value) => window.localStorage.setItem(key, value)
export const removeItm = (key) => window.localStorage.removeItem(key)

// product image
export const sizes = [150, 300, 1000]
export const getImageUrl = (imgUrl, size) => {
    const parts = imgUrl?.split('.')
    const ext = parts?.pop()
    const imgName = parts ? parts.join() + `_${size}x${size}` + '.' + ext : null
    return imgName ? apiUrl + imgName : null
}

// number formatting
export const roundTo = (num, places) => {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
}