import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import ProductsBox from './ProductsBox'
import BundlePrice from './BundlePrice'
import EndDate from './EndDate'
import React from 'react'

export default function BundlePromo({ promo, isProduct = false }) {
    const navi = useNavigate()
    const handleClick = () => { navi('/promo_order', { state: { promo } }) }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography sx={{ color: 'secondary.main', fontWeight: 500, fontSize: '18px', textAlign: 'center' }}>{promo?.name||'-'}</Typography>
            {
                promo?.desc && 
                <Typography sx={{ display: 'flex', justifyContent: 'center', p: 1, mb: 1 }}>{promo?.desc}</Typography>
            }
            <ProductsBox products={promo?.bundle} isProduct={isProduct} />
            {!isProduct && <BundlePrice price={promo?.bundle_price} />}
            {
                promo?.has_gift && promo?.gift && 
                <ProductsBox products={promo?.gift} isProduct={isProduct} isGift={true} />
            }
            {promo?.end_date && <EndDate endDate={promo?.end_date} />}
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', my: 2 }}>
                <Button variant='contained' size='small' sx={{ mr: 1 }} onClick={handleClick}>Захиалах</Button>
            </Box>
        </Box>
    )
}
