import { Box, Grow } from '@mui/material'
import { useState } from 'react'
import React from 'react'

export default function DropMenu({ title, detail, top = 10, left = 10, pinVal = null, width = null }) {
    const [isHover, setIsHover] = useState(false)
    const handleHoverOff = () => {
        if (pinVal === null) {
            setIsHover(false)
        } else if (pinVal === false) {
            setIsHover(false)
        }
    }

    return (
        <Box 
            sx={{ display: 'flex', flexDirection: 'column', position: 'relative', width: width||'initial' }}
            onMouseEnter={(e) => { setIsHover(true); e.stopPropagation() }}
            onMouseLeave={(e) => { handleHoverOff(); e.stopPropagation() }}
        >
            <Box sx={{ width: 'fit-content' }}>{title}</Box>
            <Grow in={isHover}>
                <Box 
                    sx={{ 
                        position: 'absolute', zIndex: 1500, 
                        top, left, display: isHover ? 'block': 'none'
                    }}
                >{detail}</Box>
            </Grow>
        </Box>
    )
}
