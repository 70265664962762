import { getProductFilters, getProds, getInDemandProds, getDiscountProds, getNewestProds } from '../../../api/products'
import { Discount, NewReleases, ShoppingBag, Star } from '@mui/icons-material'
import { Box, Container, Typography } from '@mui/material'
import { getPromosBySupplier } from '../../../api/promo'
import { primaryLight } from '../../../utils/constants'
import FiltersSkeleton from './Filter/FiltersSkeleton'
import { useAuthCtx } from '../../../context/AuthCtx'
import { useQuery } from 'react-query'
import { getChilds } from './utils'
import Category from './Category'
import Vndr from './Filter/Vndr'
import Mnfr from './Filter/Mnfr'
import { useHomeCtx } from '..'
import Results from './Result'
import React from 'react'

const sfChoices = [
    { id: 0, icon: <ShoppingBag sx={{ fontSize: '20px' }} />, text: 'Бүх бараа' },
    { id: 2, icon: <Discount sx={{ fontSize: '20px' }} />, text: 'Хямдралтай' },
    { id: 1, icon: <Star sx={{ fontSize: '20px' }} />, text: 'Эрэлттэй' },
    { id: 3, icon: <NewReleases sx={{ fontSize: '20px' }} />, text: 'Шинэ' },
]

export default function Main () {
    const { alertWarning } = useHomeCtx()
    const { supplierId, navBtm } = useAuthCtx()

    // page
    const [page, setPage] = React.useState(1)
    const pageSize = 30

    // filter values
	const { data: fs, isFetching: isFiltersFetching } = useQuery(['getProductFilters', supplierId], getProductFilters )
    const { mnfrs, vndrs, cats } = fs?.data||{ mnfrs: [], vndrs: [], cats: []}

    // selected filters
    const [filters, setFilters] = React.useState({ mnfr: [], vndr: [], category: null })

    // selected category
    const [selectedCategory, setSelectedCategory] = React.useState(null)
    const handleSelectedCategory = (category) => {
        setSelectedCategory(category)
        if (!category) {
            const { category, ...rest } = filters
            setFilters(rest)
        } else {
            const categoryStr = typeof category?.id === 'string' ? [] : category?.childs?.length > 0 ? [...category.childs, category.id] : [category.id]
            setFilters({ ...filters, category: JSON.stringify(categoryStr) })
        }
        setPage(1)
        setSf(null)
    }

    // selected feature
    const [sf, setSf] = React.useState(0)
    const handleSf = (id) => {
        setSelectedCategory(null)
        setFilters({ mnfr: [], vndr: [], category: null })
        setSf(id)
        setPage(1)
    }
    const qps = sf ? [page, pageSize, supplierId] : [filters, page, pageSize, supplierId]
    // products
	const { data, isFetching } = useQuery(['get_products', ...qps, sf], sf === 1 ? getInDemandProds : sf === 2 ? getDiscountProds : sf === 3 ? getNewestProds : getProds, { retry: false, onError: alertWarning, enabled: sf !== 4 } )
    // promos
    const { data: promoData } = useQuery(['get_promos', 1, pageSize, supplierId], getPromosBySupplier, { retry: false, onError: alertWarning, enabled: sf === 4 } )

    const categories = React.useMemo(() => { return cats?.length > 0 ? getChilds(cats) : [] }, [cats])

    return (
        <>
            {/* Boolean(images?.length) && <HomeImageSlider images={images} /> */}
            <Container
                maxWidth='xl'
                sx={{
                    backgroundColor: '#f1f1f1', display: 'flex',
                    p: 0, flexDirection: 'column', mt: `calc(${navBtm}px)`
                }}
            >

                {
                    supplierId
                        ?   <Box sx={{ display: 'flex' }}>
                                {
                                    isFiltersFetching
                                        ?   <FiltersSkeleton />
                                        :   <Box sx={{ mt: 1 }}>
                                                { categories?.length > 0 && <Category categories={categories} selectedCategory={selectedCategory} setSelectedCategory={handleSelectedCategory} /> }
                                                { vndrs?.length > 0 && <Vndr vndrs={vndrs} filters={filters} setFilters={setFilters} setPage={setPage} setSf={setSf} /> }
                                                { mnfrs?.length > 0 && <Mnfr mnfrs={mnfrs} filters={filters} setFilters={setFilters} setPage={setPage} setSf={setSf} /> }
                                            </Box>
                                }
                                <Results
                                    data={data}
                                    page={page}
                                    setPage={setPage}
                                    pageSize={pageSize}
                                    isFetching={isFetching}
                                    sf={sf}
                                    setSf={handleSf}
                                    choices={sfChoices}
                                    promoData={promoData}
                                />
                            </Box>
                        :   <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 135px)' }}>
                                <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: 1, boxShadow: 3 }}>
                                    <Typography sx={{ color: primaryLight }}>Нийлүүлэгч сонгоно уу!</Typography>
                                </Box>
                            </Box>
                }
            </Container>
        </>
    )
}