import { isTokenValid } from "../../../utils/functions"
import { ADMINS } from "../../../utils/constants"
import { Box, Tab, Tabs } from "@mui/material"
import CompanyInfo from "./CompanyInfo"
import { useState } from "react"
import Setting from "./Setting"

export default function Settings() {
    //console.log("ERR: ", error)
    const { role } = isTokenValid()
    const isA = ADMINS.includes(role)
    const [tab, setTab] = useState(isA ? 1 : 2)
    const handleChange = (e, newValue) => { setTab(newValue) }

    return (
        <Box sx={{ width: '100%', pl: 1 }}>
            <Tabs value={tab} onChange={handleChange}>
                { isA && <Tab label='Ерөнхий мэдээлэл' value={1} />}
                <Tab label='Тохиргоо' value={2} />
            </Tabs>
            {
                tab === 1
                    ?   <CompanyInfo />
                    :   <Setting />
            }
        </Box>
    )
}
