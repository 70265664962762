import {
  Switch,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { updatePartner } from "../../../../../api/company";
import { toPrice } from "../../../../../utils/functions";
import DebtLimitCell from "./Cells/DebtLimitCell";
import { keys } from "underscore";
import React from "react";

export default function Row(props) {
  const { row, setPartners, idx, page, pageSize } = props;
  const handleCheck = () => {
    updatePartner(row?.id, { isBad: !row?.isBad })
      .then((res) => {
        if (res?.data && keys(res.data).includes("isBad")) {
          setPartners(
            (prev) =>
              prev.map((p) =>
                p.id === row?.id ? { ...p, ...res?.data } : p
              ) || null
          );
        }
      })
      .catch((e) => {
        console.log("ERRbad: ", e);
      });
  };
  const partner = row?.partner;
  console.log("Row: ", row);
  return (
    <>
      <TableRow
        key={`${idx + 1}`}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "& > td": { padding: "2px 4px", fontSize: "13px" },
        }}
      >
       
        <TableCell>
          {page === 1 ? idx + 1 : page * pageSize + idx + 1}
        </TableCell>
        <TableCell align="center">{partner.name}</TableCell>
        <TableCell align="center">{partner.rd}</TableCell>
        <DebtLimitCell row={row} setPartners={setPartners} />
        <TableCell align="center">{toPrice(row?.debt)}</TableCell>
        <TableCell align="center">{row?.debtCnt}</TableCell>
        <TableCell align="center">{row?.orderCnt}</TableCell>
        <TableCell align="center">{toPrice(row?.orderSum)}</TableCell>
        <TableCell align="center">{partner.email}</TableCell>
        <TableCell align="center">{partner.phone}</TableCell>
        <TableCell align="center" sx={{ textAlign: "center" }}>
          <Tooltip
            title={row?.isBad ? "Найдвартай болгох" : "Найдваргүй болгох"}
            placement="right"
          >
            <Switch checked={row?.isBad} onChange={handleCheck} size="small" />
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
}
