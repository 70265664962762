import { useOrderListCtx } from '../../..'
import { TableCell } from '@mui/material'
import React from 'react'

export default function SellerCell({ sellerId }) {
    const { sellers, delmans } = useOrderListCtx()
    const staffs = (sellers?.length > 0 && delmans?.length > 0) ? sellers?.concat(delmans) : sellers?.length > 0 ? sellers : delmans?.length > 0 ? delmans : []
    const s = sellerId ? staffs?.find(s => s.id === sellerId) : '-'

    return (<TableCell textAlign='center'>{s?.name||'-'}</TableCell>)
}