import { Box, IconButton, List, TextField, Tooltip } from '@mui/material'
import { addCategory } from '../../../../../api/category'
import { SaveTwoTone } from '@mui/icons-material'
import CategoryItem from './CategoryItem'
import { categoryItemWidth } from '..'
import { useState } from 'react'
import React from 'react'

export default function Category({ categories, selected, onSelect, refetchCategories, passTopBtm, toMove, isFetching, setMsg }) {
    const [addClicked, setAddClicked] = useState(false)
    const [subCat, setSubCat] = useState('')
    // дэд ангилал нэмэх
    const handleAddSubCat = (parentCatId) => {
        addCategory({ 'name': subCat, 'parent': parentCatId })
            .then(res => {
                setAddClicked(false)
                setSubCat('')
                refetchCategories()
                setMsg({ m: 'Амжилттай!', s: 'success' })
            })
            .catch(e => {
                setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
            })
    }
    const handleAddSubCatKeyDown = (e, parentCatId) => { if (e.code === 'Enter') { handleAddSubCat(parentCatId) } }
  
    return (
        <List sx={{ padding: 0, width: categoryItemWidth, ml: 5 }}>
            {
                categories.map(
                    cat => <Box key={cat.id}>
                            <CategoryItem 
                                categoryItem={cat} 
                                selected={selected} 
                                onSelect={onSelect} 
                                refetchCategories={refetchCategories} 
                                addClicked={addClicked} 
                                setAddClicked={setAddClicked} 
                                passTopBtm={passTopBtm}
                                toMove={toMove}
                                isFetching={isFetching}
                            />
                            {
                                addClicked && cat.id === selected?.id
                                    ?   <Box sx={{ position: 'relative', width: '100%', ml: `${(cat.parents_num ? cat.parents_num + 3 : 3) * 20}px` }}>
                                            <TextField
                                                label='Дэд ангилал нэмэх'
                                                value={subCat}
                                                onChange={e => setSubCat(e.target.value)}
                                                onKeyDown={e => handleAddSubCatKeyDown(e, cat.id)}
                                                size='small'
                                                inputProps={{ style: { fontSize: '15px', padding: '4px', paddingLeft: '20px' } }}
                                                InputLabelProps={{ style: { fontSize: '15px', top: -5, paddingLeft: '8px' } }}
                                                sx={{ width: '100%', mt: '9px' }}
                                            />
                                            <IconButton onClick={() => handleAddSubCat(cat.id)} sx={{ position: 'absolute', p: '5px', top: 8, right: 0, mr: 2 }}>
                                                <Tooltip title='Хадгалах' placement='top-start' arrow>
                                                    <SaveTwoTone sx={{ color: 'secondary.main', fontSize: '20px' }} />
                                                </Tooltip>
                                            </IconButton>
                                        </Box>                                    
                                    :   null
                            }
                            {
                                (
                                    (cat.children.length > 0 && cat.id === selected?.id && selected?.expanded) || 
                                    (cat?.childs?.includes(selected?.id) && selected) || 
                                    (cat.children.length > 0 && toMove)
                                )
                                    ?    <Category 
                                            categories={cat.children} 
                                            selected={selected} 
                                            onSelect={onSelect} 
                                            refetchCategories={refetchCategories}
                                            passTopBtm={passTopBtm}
                                            toMove={toMove}
                                            isFetching={isFetching}
                                            setMsg={setMsg}
                                        />
                                    :   null
                            }
                        </Box>
                        )
            }
        </List>
    )
}
