import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useOrderListCtx } from '../../../../..'
import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import { mn } from 'date-fns/locale'
import { format } from 'date-fns'
registerLocale('mn', mn)

export default function DateRangePicker({ handleClose }) {
	const { filters, handleFilters } = useOrderListCtx()
	const [start, setStart] = useState(filters?.find(f => f.fKey === 'start')?.dateObj||null)
	const [end, setEnd] = useState(filters?.find(f => f.fKey === 'end')?.dateObj||null)

	const handleStartChange = (date) => {
		setStart(date)
		if (date > end) { setEnd(null) }
	}
	const handleEndChange = (date) => { setEnd(date) }

	const handleFilter = () => {
		if (start||end) {
			const dateFs = []
			if (start) { dateFs.push({ fKey: 'start', fValue: format(start, 'yyyy-MM-dd'), operator: '=', dateObj: start}) }
			if (end) { dateFs.push({ fKey: 'end', fValue: format(end, 'yyyy-MM-dd'), operator: '=', dateObj: end}) }
			handleFilters(filters?.length > 0 ? [...filters.filter(f => !['start', 'end'].includes(f.fKey)), ...dateFs] : dateFs)
		}
		handleClose()
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', p: 3, gap: 2, height: '330px' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center' }}>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<DatePicker
						locale='mn'
						selected={start}
						onChange={handleStartChange}
						selectsStart
						startDate={start}
						endDate={end}
						dateFormat="yyyy/MM/dd"
						placeholderText="Эхлэх огноо"
						className="date-picker-input"
						showIcon
						isClearable
					/>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<DatePicker
						locale='mn'
						selected={end}
						onChange={handleEndChange}
						selectsEnd
						startDate={start}
						endDate={end}
						minDate={start}
						dateFormat="yyyy/MM/dd"
						placeholderText="Дуусах огноо"
						className="date-picker-input"
						showIcon
						isClearable
					/>
				</Box>
			</Box>
			<Button size='small' variant='contained' onClick={handleFilter}>Шүүх</Button>
	</Box>
	)
}