import { scrollStyle, secondaryLight } from '../../../../../../../utils/constants'
import { selectDelman, selectPacker } from '../../../../../../../api/staff'
import { Box, Typography } from '@mui/material'
import { useOrderListCtx } from '../../..'
import TableCellMenu from './TableCellMenu'
import React from 'react'

const getName = (staffs, id) => staffs?.find(s => s.id === id)?.name||'-'

export default function SelectStaffCell({ row, refetch, sels, fieldName }) {
    const { delmans, packers, isAB, setAlert } = useOrderListCtx()
    const staffs = fieldName === 'packer' ? packers : delmans

    // select staff
    const choices = staffs?.filter(s => s.id !== row?.delman)||[]
    const handleSelect = (staffId = null) => {
        const inSels = sels?.includes(row?.id)
        const orderIds = inSels ? sels : [row?.id]
        if (fieldName === 'delman') {
            selectDelman({ orderIds, staffId })
                .then(_ => { refetch() })
                .catch(err => { 
                    console.log("ERR: ", err)
                    setAlert({ s: 'warning', m: 'Түр хүлээгээд дахин оролдоно уу!' })
                })
                .finally(() => {})
        } else {
            selectPacker({ orderIds, staffId })
                .then(_ => { refetch() })
                .catch(err => { 
                    console.log("ERR: ", err)
                    setAlert({ s: 'warning', m: 'Түр хүлээгээд дахин оролдоно уу!' })
                })
                .finally(() => {})
        }
    }

    const isAO = ['A', 'O'].includes(row?.process)
    const noSelectPacker = row?.delman && fieldName === 'packer'

    const name = getName(staffs, row[fieldName])

    return (
        <TableCellMenu
            current={<Typography sx={{ textAlign: 'center', fontSize: '13px' }}>{name}</Typography>}
            dropMenu={
                <Box
                    sx={{
                        display: (isAB && !isAO && !noSelectPacker) ? 'flex' : 'none', flexDirection: 'column', maxHeight: '300px',
                        backgroundColor: 'white', boxShadow: 8,
                        overflowY: 'auto', overflowX: 'hidden', ...scrollStyle,
                        '&::-webkit-scrollbar': { width: 5 }, width: '100%'
                    }}
                >
                    {choices?.length > 0 && choices.map(c => (
                        <Typography
                            key={`${c.id}`}
                            onClick={ (e) => { handleSelect(c.id) } }
                            sx={{
                                borderBottom: '1px solid transparent', borderRadius: 0, fontSize: '13px', p: '4px 8px',
                                '&:hover': { cursor: 'pointer', backgroundColor: '#f1f1f1', borderBottomColor: '#ad5555' }
                            }}
                        >{getName(staffs, c.id)}</Typography>
                    ))}
                    {
                        row[fieldName] !== null &&
                            <Typography
                                onClick={ () => { handleSelect() } }
                                sx={{
                                    borderBottom: '1px solid transparent', borderRadius: 0, fontSize: '13px', p: '4px 8px',
                                    '&:hover': { cursor: 'pointer', backgroundColor: '#f1f1f1', borderBottomColor: '#ad5555' },
                                    color: secondaryLight
                                }}
                            >Болих</Typography>
                    }
                </Box>
            }
        />
    )
}
