import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { secondaryLight } from '../../../../../utils/constants'
import { removeBranch } from '../../../../../api/company'
import DeleteBtn from '../../../BaskTemps/DeleteBtn'
import { Edit } from '@mui/icons-material'
import { useProfileCtx } from '../../..'
import React from 'react'

const cols = ['№', 'Салбарын нэр', 'Хаяг', 'Утасны дугаар', 'Салбарын менежер', 'Үйлдэл']

export default function BranchList({ branches, refetch, setSelected }) {
    const { setMsg } = useProfileCtx()
    const remove = (branchId, isMain) => {
        if (isMain === false) {
            removeBranch(branchId)
                .then(_ => { refetch(); setMsg({ msg: 'Амжилттай устгагдлаа', severity: 'success' }) })
                .catch(e => { 
                    setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
                    //console.log("BRANCH REMOVE ERR: ", e)
                })
        }
    }

    const [hover, setHover] = React.useState(null)

    return (
        <Box sx={{ boxShadow: 8 }}>
            <TableContainer sx={{ width: '800px' }} component={'Paper'}>
                <Table>
                    <TableHead>
                        <TableRow 
                            sx={{
                                '& > th': { 
                                    backgroundColor: secondaryLight, borderRight: '1px solid white', 
                                    color: 'white', textAlign: 'center',
                                    p: '2px'
                                } 
                            }}
                        >
                            { cols.map((c, idx) => <TableCell key={`key_${idx}`}>{c}</TableCell>) }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            branches.map((b, idx) => (
                                <TableRow 
                                    sx={{ 
                                        '& > td': { borderBottom: `1px solid ${secondaryLight}` },
                                        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' }, p: '2px'
                                    }}
                                    onMouseEnter={() => {setHover(idx)}}
                                    onMouseLeave={() => {setHover(null)}}
                                >
                                    <TableCell>{idx + 1}</TableCell>
                                    <TableCell>{b?.name||'-'}</TableCell>
                                    <TableCell>{b?.address?.address||'-'}</TableCell>
                                    <TableCell>{b?.phone||'-'}</TableCell>
                                    <TableCell>
                                        <Typography fontSize='15px'>{b?.manager?.name||'-'}</Typography>
                                        <Typography fontSize='15px'>{b?.manager?.email||'-'}</Typography>
                                        <Typography fontSize='15px'>{b?.manager?.phone||'-'}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
                                        <Tooltip title='Засах' placement='right-end'>
                                            <IconButton
                                                onClick={() => setSelected(b)}
                                                sx={{
                                                    visibility: idx === hover ? 'visible' : 'hidden', 
                                                    '&:hover': { color: secondaryLight }, transition: 'all 0.1s linear'
                                                }}
                                            ><Edit /></IconButton>
                                        </Tooltip>
                                        { b?.isMain === false && 
                                            <DeleteBtn onYes={() => { remove(b?.id, b?.isMain) }} isShow={idx === hover} />
                                        }
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
