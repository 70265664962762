import { toPrice } from '../../../../../../../../utils/functions'
import { Box, Typography } from '@mui/material'
import React from 'react'

export default function TotalPrice({ promo}) {
    const { total, procent } = promo || {}
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: !promo?.has_gift ? 5 : 0 }}>
            <Box 
                sx={{ 
                    display: 'flex', justifyContent: 'center', width: '500px',
                    flexDirection: 'column', alignItems: 'center', 
                    px: 5, py: 2, boxShadow: 3, borderRadius: 1
                }}
            >
                <Typography sx={{ color: 'primary.main' }}>Захиалгын үнийн дүн{`${promo?.is_per ? 'гийн' : ''}`}</Typography>
                <Box 
                    sx={{ 
                        fontSize: '30px', fontWeight: 600,
                        textAlign: 'center', color: 'secondary.light',
                        textShadow: '2px 2px 2px'
                    }}
                >{total ? toPrice(total) : '-'} </Box>
                <Typography sx={{ color: 'primary.main' }}>{promo?.is_per ? 'тутмаас' : '-c дээш бол'}</Typography>
                <Box 
                    sx={{ 
                        fontSize: '30px', fontWeight: 600,
                        textAlign: 'center', color: 'secondary.light',
                        textShadow: '2px 2px 2px'
                    }}
                >{procent ? `${procent} %` : '-'} </Box>
                <Typography sx={{ color: 'primary.main' }}>{`хямдрал эдлэ${ (promo?.has_gift && promo?.gift?.length > 0) ? 'ж' : 'эрэй!'}`}</Typography>
            </Box>
        </Box>
    )
}
