import { checkProductQty } from '../../../../../api/product'
import { qtyMsgs } from '../../../../hooks'
import { TextField } from '@mui/material'
import React from 'react'

const CountInput = ({ item, qtysErr, setQtysErr }) => {
    const [val, setVal] = React.useState(item.qty)
    const handleValue = (e) => {
        const v = e.target.value
        if (v >= item?.qty) { setVal(v) }
        else { setVal(item.qty) }
    }
    // үлдэгдлийг шалгаж, тохирох мсжийг харуулах
    const checkItemQty = () => {
        checkProductQty({ [item.qtyId]: val })
            .then(res => {
                const qtyErr = res?.data?.[item.qtyId]
                const itemQtyErr = qtyErr === null ? qtyMsgs.noQty : qtyErr === false ? qtyMsgs.lessQty : null
                setQtysErr({ ...qtysErr, [item.qtyId]: itemQtyErr })
            })
            .catch(e => {
                console.log("item qty err: ", e)
                setQtysErr(null)
            })
    }
    const handleKeyDown = (e) => { if (e.key === 'Enter') { checkItemQty() } }
    
    return <>
                <TextField
                    size='small'
                    sx={{ width: '120px', px: 1 }}
                    value={ val }
                    onChange={ handleValue }
                    onBlur={ checkItemQty }
                    onKeyDown={ handleKeyDown }
                    inputProps={{ style: { textAlign: 'center', fontSize: '14px', fontWeight: 'bold', padding: '2px', } }}
                    type='number'
                    error={Boolean(qtysErr?.[item.qtyId])}
                    helperText={Boolean(qtysErr?.[item.qtyId]) ? qtysErr[item.qtyId] : ''}
                    disabled={true}
                />
            </>
}

export default CountInput