import { IconButton, TableCell, Tooltip } from '@mui/material'
import { North, South } from '@mui/icons-material'
import { useOrderListCtx } from '../../..'
import React from 'react'

export default function OrderingCell() {
    const { ordering, handleOrdering } = useOrderListCtx()
    const isDesc = ordering?.includes('=-')

    return (
        <TableCell align='center'>
            <Tooltip title='Огноогоор эрэмбэлэх' placement='top'>
                <IconButton
                    onClick={handleOrdering}
                    sx={{ p: '2px', textAlign: 'center' }}
                >
                    { isDesc ? <North sx={{ fontSize: '18px', fontWeight: 'bold', color: 'white' }} /> : <South sx={{ fontSize: '18px', fontWeight: 'bold', color: 'white' }} /> }
                </IconButton>
            </Tooltip>
        </TableCell>
    )
}
