import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import React, { forwardRef, useState } from 'react'
import { TextField } from '@mui/material'
import { mn } from 'date-fns/locale'
import { format } from 'date-fns'
registerLocale('mn', mn)

const CustomDateInput = forwardRef(({ value, onClick, label }, ref) => {
	return <TextField
				ref={ref}
				onClick={onClick}
				value={value}
				label={label}
				sx={{ width: '230px' }}
				variant='standard'
			/>
})

export default function StartDatePicker({ setFieldValue, initialValue }) {
	const [start, setStart] = useState(initialValue ? new Date(initialValue) : null)

	const handleStartChange = (date) => {
		if (date) {
			setStart(date)
			setFieldValue('start_date', format(date, 'yyyy-MM-dd'))
		} else {
			setStart(null)
			setFieldValue('start_date', null)
		}
	}

	return (
		<DatePicker
            locale='mn'
            selected={start}
            onChange={(date) => handleStartChange(date)}
            selectsStart
            startDate={start}
            dateFormat="yyyy/MM/dd"
            showIcon
            isClearable
            customInput={<CustomDateInput label='Эхлэх огноо' />}
            withPortal
        />
	)
}