import { Box, List } from '@mui/material'
import CategoryItem from './CategoryItem'
import React from 'react'

export default function CategoryList({ menus, selected, onSelect, indent = 0 }) {

    return (
        <List sx={{ p: 0 }}>
            {
                menus.map(
                    m => <Box key={m.id}>
                            <CategoryItem menuItem={m} indent={indent} selected={selected} onSelect={onSelect} />
                            {
                                (m?.children?.length > 0 && m.id === selected?.id && selected?.expanded) || (m?.children?.length > 0 && m?.childs?.includes(selected?.id) && selected)
                                    ?   <CategoryList menus={m.children} indent={indent + 25} selected={selected} onSelect={onSelect} />
                                    :   null
                            }
                        </Box>
                )
            }
        </List>
    )
}
