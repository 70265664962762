import { Tab, Tabs } from '@mui/material'
import AccountsInfo from './Info'
import { useState } from 'react'
import Setups from './Setups'
import Staff from './Staff'
import React from 'react'
import Qpay from './Qpay'

const tabs = [ 'Ерөнхий мэдээлэл', 'Ажилтны бүртгэл', 'Qpay холбох', 'Тохиргоо' ]

export default function Setting() {
	const [tab, setTab] = useState(tabs[0])
	const handleChange = (e) => { setTab(e.target.textContent) }

	return (
		<div>
			<Tabs value={tab} onChange={ handleChange }>
			{
				tabs.map(t => <Tab key={t} value={t} label={t} />)
			}
			</Tabs>
			{ tab === 'Ерөнхий мэдээлэл' && <AccountsInfo /> }
			{ tab === 'Ажилтны бүртгэл' && <Staff /> }
			{ tab === 'Qpay холбох' && <Qpay /> }
			{ tab === 'Тохиргоо' && <Setups /> }
		</div>
	)
}