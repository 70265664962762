import { Box, Typography } from '@mui/material'
import React from 'react'

export default function EndDate({ endDate }) {
    return (
        <Box sx={{  display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1, width: '500px', mt: 2 }}>
            <Typography sx={{ color: 'primary.main' }}>Урамшуулал дуусах хугацаа:</Typography>
            <Typography sx={{ color: 'primary.main', color: 'secondary.main', fontWeight: 500, fontSize: '20px' }}>{endDate?.split(' ')?.[0]}</Typography>
        </Box>
    )
}
