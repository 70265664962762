import { Checkbox, IconButton, TableCell } from '@mui/material'
import React from 'react'

export default function CheckCell({ checked }) {
    return (
        <TableCell sx={{ p: 0 }}>
            <IconButton sx={{ p: 0 }}>
                <Checkbox
                    color='primary'
                    size='small'
                    checked={checked}
                    sx={{ p: '2px' }}
                />
            </IconButton>
        </TableCell>
    )
}
