import PasswordField from '../../helpers/fields/PasswordField'
import { Button, Stack, Typography } from '@mui/material'
import { passCreateYup } from '../../../utils/constants'
import { createPassword } from '../../../api/auth'
import GetVerifyOTP from './GetVerifyOTP'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const validationSchema = Yup.object({ ...passCreateYup })

export default function CreatePwd({ email, setRs, handleClose }) {
    const [otpError, setOtpError] = useState('')

    const [validPwd, setValidPwd] = useState(null)

    const { handleSubmit, getFieldProps, errors, touched } = useFormik({
        initialValues: { password1: '', password2: '' },
        onSubmit: _ => { setValidPwd(_?.password1) },
        validationSchema,
        validateOnChange: false
    })

    const makePwd = ({ email, otp }) => {
        if (validPwd) {
            createPassword({ email, otp, new_pwd: validPwd })
                .then(_ => { setRs('Амжилттай!'); handleClose() })
                .catch(e => {
                    console.log("ERRpwd: ", e?.response?.data)
                    if (e?.response?.status === 400) {
                        const err = e?.response?.data
                        if (err) { setOtpError(err) }
                    }
                })
        }
    }

    const getErrors = (name) => {
        const isErr = Object.keys(errors)?.includes(name) && Object.keys(touched)?.includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }

    const handleEnter = (event) => {
        const curElmId = event.target.id
        if (event.key === 'Enter') {
            event.preventDefault()
            if (curElmId === 'f1') {
                const elm = document.getElementById('f2')
                elm.focus()
            } else if (curElmId === 'f2') { handleSubmit() }
        }
    }

    return (
        <Stack 
            sx={{ 
                display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
                px: 3, py: 2, minWidth: '300px', maxWidth: '300px', minHeight: '435px', maxHeight: '435px'
            }}
        >
            <Typography fontSize='22px' textAlign='center' marginBottom={4}>Нууц үг үүсгэх</Typography>
            <PasswordField
                name='password1'
                size='small'
                sx={{ width: '100%' }}
                {...getFieldProps('password1')}
                {...getErrors('password1')}
                id='f1'
                onKeyDown={handleEnter}
            />
            <PasswordField
                label='Нууц үг давтах'
                name='password2'
                size='small'
                sx={{ width: '100%' }}
                {...getFieldProps('password2')}
                {...getErrors('password2')}
                id='f2'
                onKeyDown={handleEnter}
            />
            {
                validPwd
                    ?   <GetVerifyOTP email={email} onVerify={makePwd} otpError={otpError} />
                    :   <Button 
                            variant='contained' size='small'
                            sx={{ p: '6.5px', width: '100%', mt: 2 }}
                            onClick={ handleSubmit }
                        >Баталгаажуулах код авах</Button>
            }
        </Stack>
    )
}