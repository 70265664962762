import React, { useEffect, useState } from 'react'
import { gq } from '../../../../../api/company'
import Cred from './Cred'

export default function Qpay() {
    const [cur, setCur] = useState({})
    const [isFetched, setIsFetched] = useState(null)
    
    useEffect(() => {
        setIsFetched(false)
        gq()
            .then(res => {
                if (res?.status === 200) { setCur(res?.data) }
            })
            .catch(() => {})
            .finally(() => { setIsFetched(true) })
    }, [])

    if (!isFetched) { return null }

    return <Cred cur={cur} />
}
