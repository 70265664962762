import { Box } from '@mui/material'
import Thumbnail from './Thumbnail'
import React from 'react'

export default function Thumbs({ licenseFiles, setLicenseFiles }) {
    const removeImg = (fileName) => {
        setLicenseFiles(prev => prev.filter(f => f.name !== fileName))
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
            {
                licenseFiles?.map(lf => {
                    return <Thumbnail key={lf.name} file={lf} removeImg={removeImg} />
                })
            }
        </Box>
    )
}
