import { Box, Button, Dialog, MenuItem, TextField, Typography } from '@mui/material'
import { secondaryLight, secondaryMain } from '../../../../../utils/constants'
import { createNotf } from '../../../../../api/confirm'
import DropMenu from '../../../../helpers/DropMenu'
import { keys } from 'underscore'
import Editr from './Editr'
import React from 'react'

const toWho = {
    A: 'Нийлүүлэгч',
    PA: 'Эмийн сан',
    ALL: 'Бүгд'
}
export default function NewNotf({ open, handleClose, nw, setNw, setOkMsg, setErrMsg, refetch }) {
    const handleClear = () => {setNw({
        kind: 'SU',
        title: 'Системийн шинэчлэлт',
        body: null,
        role: null,
        isPublic: null
    })}
    const selectTo = (v) => {
        if (v == 'A') {
            setNw(prev => ({ ...prev, role: 'A', isPublic: false }))
        } else if (v == 'PA') {
            setNw(prev => ({ ...prev, role: 'PA', isPublic: false }))
        } else {
            setNw(prev => ({ ...prev, role: null, isPublic: true }))
        }
    }
    const val = nw?.role === 'A' ? 'Нийлүүлэгч' : nw?.role === 'PA' ? 'Эмийн сан' : nw?.isPublic === true ? 'Бүгд' : 'Сонгох'
    const handleSend = () => {
        if (!nw?.role && !nw?.isPublic) { setErrMsg('Хэрэглэгчийн төрөл сонгоно уу!') }
        else if (!nw?.body) { setErrMsg('Мэдэгдлийн тайлбар оруулна уу!') }
        else {
            createNotf(nw)
                .then(res => {
                    handleClose()
                    handleClear()
                    setOkMsg('Амжилттай илгээлээ!')
                    refetch()
                    console.log("RES: ", res?.data)
                }).catch(e => { setErrMsg('Түр хүлээгээд дахин оролдоно уу!'); console.log("ERR: ", e) })

        }
    }
    //console.log("V: ", nw?.body)

    return (
        <Dialog open={open} onClose={handleClose}>
            <Box sx={{ width: '500px', p: 2, mt: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2 }}>
                    <Typography sx={{ mr: 2, fontSize: '15px' }}>Хэрэглэгчийн төрөл</Typography>
                    <DropMenu 
                        title={
                                <TextField
                                    value={val}
                                    size='small'
                                    default={val}
                                    InputProps={{ readOnly: true }}
                                    inputProps={{ style: { cursor: 'pointer', fontSize: '15px' } }}
                                    sx={{ width: '120px', fontSize: '15px' }}
                                />
                            }
                        detail={
                                <Box sx={{ backgroundColor: 'white', boxShadow: 3 }}>
                                    {keys(toWho).map((k, i) => <MenuItem key={i} onClick={() => selectTo(k)} sx={{ fontSize: '15px' }}>{toWho[k]}</MenuItem>)}
                                </Box>
                            }
                        top={38}
                        left={0}
                    />
                </Box>
                <Editr nw={nw?.body} setNw={setNw} />
                {/* <Typography dangerouslySetInnerHTML={{ __html: nw}} /> */}
                {/* <Typography>{nw}</Typography> */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                        <Button 
                            variant='contained'
                            size='small'
                            onClick={handleClear}
                            sx={{ width: '120px', background: secondaryLight, '&:hover': { backgroundColor: secondaryMain } }}
                        >Арилгах</Button>
                        <Button 
                            variant='contained'
                            size='small'
                            onClick={handleSend}
                            sx={{ ml: 1, width: '120px' }}
                        >Илгээх</Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}
