import { MenuItem, Select, Tooltip, Typography } from '@mui/material'
import React from 'react'

const pageSizes = [10, 20, 50]
export default function PageSizeControl({ pageSize, setPageSize }) {
    const handlePageSize = (e) => { setPageSize(e.target.value) }
    return (
        <Tooltip title='Хуудасны мөрийн тоо' placement='right'>
            <Select
                value={pageSize}
                onChange={handlePageSize}
                size='small'
                sx={{ ml: 2 }}
            >
                {
                    pageSizes.map(p =>
                        <MenuItem key={'p'} value={p} sx={{ p: 0 }}>
                            {<Typography sx={{ fontSize: '15px', textAlign: 'center', width: '100%' }}>{p}</Typography>}
                        </MenuItem>)
                }
            </Select>
        </Tooltip>
    )
}
