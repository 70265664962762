import BaskTemps from '../../../../profile/BaskTemps'
import { Box } from '@mui/material'
import React from 'react'

export default function TempletList() {
    return (
        <Box>
            <BaskTemps />
        </Box>
    )
}
