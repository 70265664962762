import { Box, Container, Divider, Paper, Typography } from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { getToast } from '../../auth/helpers/ToastAlert'
import { useAuthCtx } from '../../../context/AuthCtx'
import { createPromoOrder } from '../../../api/order'
import Address, { BY_COME } from './Address'
import OrderItems from './OrderItems'
import { values } from 'underscore'
import Payment from './Payment'
import Verify from './Verify'
import React from 'react'

export function CustomDivider ({ text }) {
    return (
        <Divider sx={{ mt: 3, mb: 2, "&::before, &::after": { borderColor: "primary.main" } }} textAlign='center'>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography fontSize='14px' fontStyle='italic' marginLeft={1} sx={{ color: 'primary.main' }}>{text}</Typography>
            </Box>
        </Divider>
    )
}

// order ctx
const PromoOrderCtx = React.createContext()
export const usePromoOrderCtx = () => React.useContext(PromoOrderCtx)

// main component
export default function PromoOrder() {
    const { navBtm } = useAuthCtx()
    const loc = useLocation()
    const promo = loc?.state?.promo
    // address
    const [adrType, setAdrType] = React.useState(null)

    const [newOrder, setNewOrder] = React.useState(null)

    const [order, setOrder] = React.useState({ promoId: promo?.id, branchId: null, note: null, payType: promo?.is_cash === true ? 'C' : 'L' })
    //console.log("Promo: ", promo)
    //console.log("Order: ", order)
    const navi = useNavigate()
    const toCompleted = () => { navi('/promo_order/completed', { state: { orderNo: newOrder?.orderNo } }) }
    React.useEffect(() => { if (newOrder?.orderNo) { toCompleted() } }, [newOrder])
    const createOrder = () => {
        createPromoOrder(order)
            .then(res => { setNewOrder(res?.data) })
            .catch(err => {
                console.log("ERR: ", err)
                setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
            })
    }

    React.useEffect(() => { setNewOrder(null); return () => setNewOrder(null) }, [])

    const adrErr = BY_COME !== adrType && !order?.branchId

    const [qtysErr, setQtysErr] = React.useState(null)
    const qtyErr = values(qtysErr)?.find(q => !!q)

    const [msg, setMsg] = React.useState(null)
    const msgOff = () => { setMsg(null) }

    return (
        <PromoOrderCtx.Provider 
            value={{
                order,
                setOrder,
                createOrder,
                msg,
                setMsg
            }}
        >
            {
                loc?.pathname === '/promo_order/completed'
                    ?   <Outlet />
                    :   <Container sx={{ mb: 4, height: 'auto' }} maxWidth={'md'}>
                            <Box sx={{ height: `calc(${navBtm}px + 10px)` }} />
                            <Paper sx={{ boxShadow: 10, p: 2 }}>
                                {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 2000)}
                                <Box sx={{ mt: 3, p: 0, width: '100%' }}>
                                    <OrderItems
                                        promo={promo}
                                        qtysErr={qtysErr}
                                        setQtysErr={setQtysErr}
                                    />
                                    <Address
                                        adrType={adrType}
                                        setAdrType={setAdrType}
                                        order={order}
                                        setOrder={setOrder}
                                    />
                                    <Payment payType={order?.payType} setOrder={setOrder} isCash={promo?.is_cash} />
                                    <Verify
                                        payType={order?.payType}
                                        createOrder={createOrder}
                                        adrErr={adrErr}
                                        qtyErr={qtyErr}
                                    />
                                </Box>
                            </Paper>
                        </Container>
            }
        </PromoOrderCtx.Provider>
    )
}
