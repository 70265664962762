import PromoOrderCompleted from "../../components/order/PromoOrder/PromoOrderCompleted"
import ProductDetail from "../../components/home/ProductDetail"
import PromoOrder from "../../components/order/PromoOrder"
import BaskTemps from "../../components/profile/BaskTemps"
import Settings from "../../components/profile/Settings"
import Completed from "../../components/order/Completed"
import Orders from "../../components/profile/Orders"
import Branch from "../../components/profile/Branch"
import Profile from "../../components/profile"
import Main from "../../components/home/Main"
import Seller from "../../components/seller"
import Order from "../../components/order"

export const homeRoutes = [
    {
        index: true,
        element: <Main />,
    },
    {
        path: '/order',
        element: <Order />,
        children: [
            { path: 'completed', element: <Completed /> },
        ]
    },
    {
        path: '/promo_order',
        element: <PromoOrder />,
        children: [
            { path: 'completed', element: <PromoOrderCompleted /> },
        ]
    },
    {
        path: '/seller',
        element: <Seller />,
        children: [
            {
                path: 'completed',
                element: <Completed />
            }
        ]
    },
    {
        path: '/products/:productId',
        element: <ProductDetail />
    },
    {
        path: '/profile',
        element: <Profile />,
        children: [
            {
                path: 'orders',
                element: <Orders />,
            },
            {
                path: 'branch',
                element: <Branch />,
            },
            {
                path: 'templets',
                element: <BaskTemps />,
            },
            {
                path: 'settings',
                element: <Settings />,
            }
        ]
    }
]