import { getToast } from '../../../auth/helpers/ToastAlert'
import React, { useEffect, useState } from 'react'
import { getNotfs } from '../../../../api/confirm'
import { Box, Button } from '@mui/material'
import 'react-quill/dist/quill.snow.css'
import NotfList from './NotfList'
import NewNotf from './NewNotf'

export default function Notif() {
    const [nw, setNw] = useState({
        kind: 'SU',
        title: 'Системийн шинэчлэлт',
        body: null,
        role: null,
        isPublic: null
    })
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }

    const [okMsg, setOkMsg] = useState(null)
    const okOff = () => { setOkMsg(null) }
    const [errMsg, setErrMsg] = useState(null)
    const errOff = () => { setErrMsg(null) }

    const [notfs, setNotfs] = useState([])
    const fetchNotfs = () => {
        getNotfs()
            .then(res => { setNotfs(res?.data?.results) })
            .catch(e => { console.log("ERR: ", e) })
    }
    useEffect(() => { fetchNotfs() }, [])
    //console.log("N: ", notfs)
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            { getToast(Boolean(okMsg), okOff, okMsg, 'success', 1500) }
            { getToast(Boolean(errMsg), errOff, errMsg, 'warning', 1500) }
            <Button variant='outlined' size='small'onClick={handleOpen} sx={{ mt: 3, mb: 3 }}>Шинэ мэдэгдэл илгээх</Button>
            {notfs?.length > 0 && <NotfList notfs={notfs} refetch={fetchNotfs} />}
            {
                open && <NewNotf 
                            nw={nw}
                            setNw={setNw}
                            open={open}
                            handleClose={handleClose}
                            setOkMsg={setOkMsg}
                            setErrMsg={setErrMsg}
                            refetch={fetchNotfs}
                        />
            }
        </Box>
    )
}
