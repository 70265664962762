import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material'
import { Email, FmdGood, Phone } from '@mui/icons-material'
import logo from '../../helpers/imgs/logo.png'
import { useLocation } from 'react-router'
import React from 'react'

const timetable = [ '09:00-18:00 (Даваа-Баасан)' ]
const contact = [
    { 'icon': <Email sx={{ fontSize: '18px', mr: 2 }} />, 'value': 'contact@infosystems.mn' },
    { 'icon': <Phone sx={{ fontSize: '18px', mr: 2 }} />, 'value': '70116399, 70126399' },
    { 'icon': <FmdGood sx={{ fontSize: '18px', mr: 2 }} />, 'value': `Улаанбаатар хот, Чингэлтэй дүүрэг, 5-р хороо, Баянбогд плаза, 402 тоот` }
]
const helpLinks = ['Бидний тухай', 'Ашиглах заавар', 'Түгээмэл асуулта, хариулт']

const Footer = () => {
    const loc = useLocation()
    if (loc?.pathname == '/order') { return; }
    const handleClick = () => {
        window.open('https://infosystems.mn/p/about-us', '_blank')
    }

    return (
        <AppBar position='static' sx={{ bottom: 0, transform: 'translateY(100%)' }}>
            <Toolbar sx={{ display: 'flex', flexDirection: 'column' }}>
                <Container maxWidth='md'>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', p: 2, pb: 10, pt: 5 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
                            <Typography fontSize='17px' fontWeight='bold' sx={{ mb: 2 }}>Тусламж</Typography>
                            {helpLinks.map((h, idx) => 
                            <Typography 
                                key={idx} fontSize='14px' 
                                onClick={handleClick} 
                                sx={{ mb: 1, color: 'white', borderBottom: 1, borderColor: 'transparent', '&:hover': { cursor: 'pointer', borderColor: 'white' } }}>
                                {h}
                            </Typography>)}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
                            <Typography fontSize='17px' fontWeight='bold' sx={{ mb: 2 }}>Цагийн хуваарь</Typography>
                            {timetable.map(t => <Typography key={t} fontSize='14px' sx={{ mb: 1 }}>{t}</Typography>)}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', maxWidth: '300px' }}>
                            <Typography fontSize='17px' fontWeight='bold' sx={{ mb: 2 }}>Холбоо барих</Typography>
                            {contact.map(c => <Box key={c.value} sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>{c['icon']}<Typography fontSize='14px'>{c['value']}</Typography></Box>)}
                        </Box>
                    </Box>
                </Container>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', p: 1 }}>
                    <a href='https://infosystems.mn/' target='_blank'>
                        <img src={logo} alt='logo' style={{ width: '120px', height: '30px' }} />
                    </a>
                    <Typography sx={{ color: 'white', ml: 2, fontSize: '15px' }}>
                        © 2023 Зохиогчийн эрх хуулиар хамгаалагдсан.
                    </Typography>
                </Box>                    
            </Toolbar>
        </AppBar>
    )
}

export default Footer