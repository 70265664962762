import { getBranchList } from '../../../../api/company'
import { Box, Button } from '@mui/material'
import { useProfileCtx } from '../..'
import BranchList from './BranchList'
import { isEmpty } from 'underscore'
import AddBranch from './AddBranch'
import React from 'react'

export default function Branches() {
    const { setMsg } = useProfileCtx()
    const [selected, setSelected] = React.useState(null)

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false); setSelected(null) }
    
    const [branches, setBranches] = React.useState([])
    const refetch = () => { 
        getBranchList()
            .then(res => setBranches(res?.data))
            .catch(err => {
                setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
                //console.log("RES err: ", err)
            })
    }
    React.useEffect(() => { refetch() }, [])

    return (
        <Box sx={{ p: 4 }}>
            <Button onClick={handleOpen} size='small' variant='contained' sx={{ mb: 1 }}>Салбар нэмэх</Button>
            {
                (!isEmpty(selected)||open) && 
                    <AddBranch 
                        refetch={refetch}
                        selected={selected} 
                        open={open||Boolean(selected)} 
                        handleClose={handleClose} 
                    />
            }
            <BranchList
                branches={branches}
                refetch={refetch}
                setSelected={setSelected}
            />
        </Box>
    )
}
