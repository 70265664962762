import { secondaryLight } from '../../../../../../../utils/constants'
import { useExternalComponentRect } from '../../../../../../hooks'
import { TableCell, TableRow } from '@mui/material'
import { FilterAlt } from '@mui/icons-material'
import ByCategory from './Cells/ByCategory'
import ByBarcode from './Cells/ByBarcode'
import { useProductListCtx } from '../..'
import ByField from './Cells/ByField'
import ByName from './Cells/ByName'
import ByMnfr from './Cells/ByMnfr'
import ByVndr from './Cells/ByVndr'
import React from 'react'

const filterCells = ['name', 'price', 'barcode', 'sale_price', 'sale_qty', 'discount', 'discount_expiredate', 'in_stock', 'expDate', 'category', 'name', 'mnfr', 'vndr']

const getComponent = (fieldName, selMnfrName, selVndrName, selCatName) => {
    if (filterCells.includes(fieldName)) {
        if (fieldName === 'name') return <ByName />
        if (fieldName === 'barcode') return <ByBarcode />
        if (fieldName === 'category') return <ByCategory selCatName={selCatName} />
        if (fieldName === 'mnfr') return <ByMnfr selMnfrName={selMnfrName} />
        if (fieldName === 'vndr') return <ByVndr selVndrName={selVndrName} />
        if (fieldName === 'price') return <ByField field={fieldName} label='Үнээр' />
        if (fieldName === 'sale_price') return <ByField field={fieldName} label='Бөөний үнээр' />
        if (fieldName === 'sale_qty') return <ByField field={fieldName} label={'Бөөний тоогоор'} />
        if (fieldName === 'discount') return <ByField field={fieldName} label={'Хямдралын хувиар'} />
        if (fieldName === 'discount_expiredate') return <ByField field={fieldName} label={'Хямдрал дуусах'} />
        if (fieldName === 'in_stock') return <ByField field={fieldName} label={'Үлдэгдлээр'} />
        if (fieldName === 'expDate') return <ByField field={fieldName} label={'Бараа дуусах'} />
    }
    return <TableCell key={fieldName} />
}

export default function ProductFilterRow({ hr }) {
    const { colFields, first, filters } = useProductListCtx()
    const selCatName = filters?.find(f => f.k === 'category') ? first?.category_names?.[0] : null
    const selMnfrName = filters?.find(f => f.k === 'mnfr') ? first?.mnfr?.name : null
    const selVndrName = filters?.find(f => f.k === 'vndr') ? first?.vndr?.name : null

    const { height } = useExternalComponentRect({ externalRef: hr })

    return (
        <TableRow
            sx={{
                '& > td': { p: 0, px: '1px' },
                backgroundColor: 'white', maxHeight: '20px', height: '20px',
                position: 'sticky', top: height||'initial', zIndex: 1,
                boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)'
            }}
        >
            <TableCell sx={{ backgroundColor: 'white', textAlign: 'center', p: 0 }}><FilterAlt sx={{ color: secondaryLight }} /></TableCell>
            <TableCell sx={{ backgroundColor: 'white' }} />
            {colFields?.map(fieldName => getComponent(fieldName, selMnfrName, selVndrName, selCatName))}
        </TableRow>
    )
}
