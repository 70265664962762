import AxiosWithToken, { Axios } from "../global/axiosBase"
import { getPageParams } from "./utils"

export const getTodayShipment = () => AxiosWithToken.get('shipment/')

export const getShipment = (shipmentId) => AxiosWithToken.get(`shipment/${shipmentId}/`)

export const startShipment = (data) => AxiosWithToken.patch('start_shipment/', data)

export const endShipment = (data) => AxiosWithToken.patch('end_shipment/', data)

export const getShipmentDetail = ({ shipmentId }) => { return AxiosWithToken.get(`shipment/${shipmentId}/items/`) }

export const getAddressByCoords = (data) => {
    return Axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${data?.lat}&lon=${data?.lon}&format=json`)
}

export const getTodayShipmentLocations = () => {
    return AxiosWithToken.get('shipment_location/')
}

export const getShipmentStops = (delmanId = null) => {
    return AxiosWithToken.post('delman_stops/', { delmanId })
}

export const updateShipmentLocation = () => {
    return AxiosWithToken.patch('update_shipment_location/')
}

// history
export const getShipments = ({ queryKey }) => {
    const [ _, filters, page, pageSize ] = queryKey
    let url = getPageParams(page, pageSize, 'shipment/history/')
    url = filters?.reduce((acc, cur) => acc += `&${cur.k}=${cur.v}`, url)
    //console.log("u1: ", url)
    return AxiosWithToken.get(url)
}

// shipment expense
export const getShipmentExpense = (shipmentId) => {
    return AxiosWithToken.get(`shipment/${shipmentId}/expense/`)
}