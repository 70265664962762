import { Box, Skeleton } from '@mui/material'
import React from 'react'

export default function FiltersSkeleton() {
    return (
        <Box 
            sx={{
                bgColor: '#fff', width: '250px', height: '500px', mt: 1,
                m: 1, p: 2, boxShadow: 5, textAlign: 'center',
                borderRadius: 2, backgroundColor: 'white'
            }}
        >
            {
                new Array(15).fill(0).map((_, i) => (
                    <Skeleton key={`${i}`} variant='rectangular' height='20px' width={225} sx={{ borderRadius: 2, mt: 1 }} />
                ))
            }
        </Box>
    )
}
