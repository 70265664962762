import { PROCESS_COLORS, PROCESS_TYPES } from '../../../../../../constants'
import { primaryLight } from '../../../../../../../utils/constants'
import { changeProcess } from '../../../../../../../api/order'
import { Box, Typography } from '@mui/material'
import TableCellMenu from './TableCellMenu'
import { isEmpty, keys } from 'underscore'
import { useOrderListCtx } from '../../..'
import React, { useState } from 'react'
import { capitalize } from './utils'

const PROCESS = { N: 'Шинэ' }
const FOR_EMPTY = {}
const FOR_PACKER = { M: 'Бэлтгэж эхэлсэн', P: 'Бэлэн болсон' }
const FOR_DELMAN = {
    R: 'Буцаагдсан',
    C: 'Хаалттай'
}
const FOR_AB = { ...PROCESS, ...FOR_PACKER, ...FOR_DELMAN }
const getOrderProcess = (row, role) => {
    if (role === 'D') { return FOR_DELMAN }
    else if (role === 'P') { return FOR_PACKER }
    else if (['A', 'B'].includes(role)) {
        if (row?.delman) { return { ...FOR_DELMAN, P: 'Бэлэн болсон'} }
        else { return FOR_AB }
    }
    else { return FOR_EMPTY }
}

export const getProcessColor = (process) => PROCESS_COLORS.find(c => c.name === process)?.color

export default function SelectProcessCell({ row, setRow }) {
    const { role, setAlert } = useOrderListCtx()
    const prc = getOrderProcess(row, role)
    const choices = !isEmpty(prc) ? Object.fromEntries(Object.entries(prc).filter(([key]) => key !== row?.process)) : {}
    //console.log("PRC: ", prc, choices)
    
    // change process
    const handleChange = (process) => {
        changeProcess({ id: row?.id, process })
            .then(res => { setRow(res?.data) })
            .catch(e => { setAlert({m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning'}) })
    }

    const clr = row?.process ? getProcessColor(row.process) : '#d52127'

    const noSelect = ['A', 'O'].includes(row?.process) || row?.status === 'S'

    return (
        <TableCellMenu
            current={<Typography
                        fontSize='13px'
                        noWrap 
                        sx={{ 
                            textAlign: 'center', width: '100%',
                            backgroundColor: clr||'initial', 
                            color: clr ? 'white' : 'initial'
                        }}
                    >
                        {PROCESS_TYPES[row?.process]??'-'}
                    </Typography>}
            dropMenu={
                <Box
                    sx={{ 
                        display: noSelect ? 'none' : 'flex', flexDirection: 'column',
                        backgroundColor: 'white', boxShadow: 8
                    }}
                >
                    {keys(choices)?.map(k => (
                        <Typography
                            key={`${k}`}
                            onClick={ () => { handleChange(k) } }
                            sx={{
                                borderBottom: '1px solid transparent', borderRadius: 0,
                                p: '4px 10px', fontSize: '13px', color: primaryLight,
                                '&:hover': { backgroundColor: '#f1f1f1', cursor: 'pointer', borderBottom: '1px solid #ad5555' }
                            }}
                        >{capitalize(choices[k])}</Typography>
                    ))}
                </Box>
            }
        />
    )
}
