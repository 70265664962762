import { Box, Button,  Dialog, Grid, TextField, Typography } from '@mui/material'
import { createPromo, updatePromo } from '../../../../../../api/promo'
import { SelectionInput, StyledGridItem } from './utils'
import StartDatePicker from './Inputs/StartDatePicker'
import { PROMO_TYPES, TARGET_TYPES } from '../utils'
import SelectedCustomers from './SelectedCustomers'
import EndDatePicker from './Inputs/EndDatePicker'
import SelectedProducts from './SelectedProducts'
import PriceInput from './Inputs/PriceInput'
import CheckInput from './Inputs/CheckInput'
import { useAdminCtx } from '../../../..'
import { isEmpty } from 'underscore'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React from 'react'

const AddPromoCtx = React.createContext()
export const useAddPromoCtx = () => React.useContext(AddPromoCtx)

const validationSchema = Yup.object().shape({
    name: Yup.string().max(150, 'Нэр хэт урт байна! 150 тэмдэгтээс бага байх ёстой!').required('Нэр оруулна уу!'),
    promo_type: Yup.mixed().oneOf(Object.keys(PROMO_TYPES)?.map(k => parseInt(k))).required('Урамшууллын төрөл сонгоно уу!'),
    is_cash: Yup.boolean().nullable(),
    start_date: Yup.date().nullable(),
    end_date: Yup.date().nullable(),
    target_type: Yup.mixed().oneOf(Object.keys(TARGET_TYPES)?.map(k => parseInt(k))).required('Хэрэглэгчийн төрөл сонгоно уу!'),
    target: Yup.array(Yup.number()).test((value, ctx) => {
        if (ctx.parent.target_type === 3 && value.length === 0) {
            return ctx.createError({ message: 'Зорилтот харилцагч нэмнэ үү!' })
        }
        return true
    }),
    bundle: Yup.array(Yup.object({ id: Yup.number().min(1), qty: Yup.number().min(1) })).test((value, ctx) => {
        if ([1, 3].includes(ctx.parent.promo_type) && value.length === 0) {
            return ctx.createError({ message: 'Багцын бараа сонгоно уу!' })
        } else if (ctx.parent.promo_type === 3 && value.length !== 1) {
            return ctx.createError({ message: 'Барааны урамшуулалд зөвхөн нэг төрлийн бараа сонгоно!' })
        }
        return true
    }),
    bundle_price: Yup.number().nullable().test((value, ctx) => {
        if (ctx.parent.promo_type === 1 && !value) {
            return ctx.createError({ message: 'Багцын үнэ оруулна уу!' })
        }
        return true
    }),
    has_gift: Yup.boolean().nullable(),
    gift: Yup.array(Yup.object({ id: Yup.number().min(1), qty: Yup.number().min(1) })).test((value, ctx) => {
        if (ctx.parent.has_gift === true || ctx.parent.promo_type === 3) {
            if (value.length === 0) {
                return ctx.createError({ message: 'Урамшууллын бараа сонгоно уу!' })
            }
        }
        return true
    }),
    total: Yup.number().nullable().test((value, ctx) => {
        if (ctx.parent.promo_type === 2 && !value) {
            return ctx.createError({ message: 'Нийт үнийн дүн оруулна уу!' })
        }
        return true
    }),
    is_per: Yup.boolean().nullable(),
    procent: Yup.number().nullable().test((value, ctx) => {
        if (ctx.parent.promo_type === 2 && !value) {
            return ctx.createError({ message: 'Хямдралын хувь оруулна уу!' })
        }
        return true
    })
})

export default function AddPromo({ refetch, promo, setPromo }) {
    const { alertSuccess, alertWarning, setMsg } = useAdminCtx()
    const isEditing = !isEmpty(promo)
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => {
        setOpen(true);
        if (!isEditing) {
            setSelectedGifts([]);
            setSelectedProducts([]);
            setSelected([]);
            setSelectedTargetGroup([])
        }
    }
    const handleClose = () => { 
        setOpen(false)
        if (isEditing) { resetForm(); setPromo(null); }
    }
    const handleCancel = () => {
        handleClose()
        setSelectedTargetGroup([])
        resetForm()
        setSelected([])
        setSelectedProducts([])
        setSelectedGifts([])
        if (isEditing) { setPromo(null) }
    }

    const onSubmit = (values) => {
        const vals = {
            ...values,
            bundle: values?.bundle?.length === 0 ? null : values?.bundle,
            gift: values?.gift?.length === 0 ? null : values?.gift,
            target: values?.target?.length === 0 ? null : values?.target
        }
        if (isEditing) {
            updatePromo(promo?.id, vals)
                .then(() => { refetch(); handleCancel(); alertSuccess(); })
                .catch(e => {
                    console.log("ERR: ", e);
                    if (e?.response?.data?.non_field_errors?.includes('The fields name, supplier must make a unique set.'))
                        { setMsg({ m: 'Урамшуулалын нэр давхардаж байна!', s: 'warning' })}
                    else { alertWarning() }
                })
        } else {
            createPromo(vals)
                .then(() => { refetch(); handleCancel(); alertSuccess(); })
                .catch(e => { 
                    console.log("ERR: ", e);
                    if (e?.response?.data?.non_field_errors?.includes('The fields name, supplier must make a unique set.'))
                        { setMsg({ m: 'Урамшуулалын нэр давхардаж байна!', s: 'warning' })}
                    else { alertWarning() }
                })
        }
    }

    const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue, resetForm } = useFormik({
        initialValues: {
            name: '',
            promo_type: '',
            is_cash: false,
            start_date: null,
            end_date: null,
            target_type: '',
            target: [],
            bundle: [],
            bundle_price: null,
            is_per: false,
            has_gift: false,
            gift: [],
            total: null,
            procent: null
        },
        onSubmit,
        validationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false
    })

    React.useEffect(() => {
        if (!isEditing) {
            setFieldValue('bundle', [])
            setFieldValue('gift', [])
            setSelectedProducts([])
            setSelectedGifts([])
        }
    }, [values?.promo_type])
    
    // to edit
    React.useEffect(() => {
        if(isEditing) {
            setOpen(true)
            for (const key in promo) {
                if (key === 'bundle' || key === 'gift') {
                    setFieldValue(key, promo[key]?.map(p => ({ id: p.product_id, qty: p.qty })))
                    continue
                } else if (key === 'target') {
                    setFieldValue(key, promo[key]?.map(p => p.id))
                    continue
                }
                setFieldValue(key, promo[key])
            }
        }
    }, [promo])

    const [selected, setSelected] = React.useState([]) // selected users
    const [selectedTargetGroup, setSelectedTargetGroup] = React.useState([])
    const handleTargetFieldValue = (newValues) => { setFieldValue('target', [...values?.target, ...newValues]) }
    const [selectedProducts, setSelectedProducts] = React.useState([]) // bundle products
    const [selectedGifts, setSelectedGifts] = React.useState([]) // gift products
    React.useEffect(() => {
        if (isEditing) {
            setSelected(promo?.target||[])
            setSelectedProducts(promo?.bundle||[])
            setSelectedGifts(promo?.gift||[])
        }
    }, [promo])

    const [isNewListAdded, setIsNewListAdded] = React.useState(false)

    const totalPrice = selectedProducts?.reduce((acc, curr) => acc + curr.price * curr.qty, 0)||0

    //console.log("REFrrr: ", values.gift, selectedGifts)
    
    return (
        <AddPromoCtx.Provider
            value={{
                values,
                setFieldValue,
                selectedTargetGroup,
                setSelectedTargetGroup,
                handleTargetFieldValue,
                isNewListAdded,
                setIsNewListAdded
            }}
        >
            <Button
                variant='contained'
                color='primary'
                onClick={handleOpen}
                size='small'
            >Нэмэх</Button>
            {open && <Dialog open={open} onClose={handleClose} maxWidth='lg'>
                <Grid container sx={{ p: 5 }} spacing={1} rowSpacing={3}>
                    <StyledGridItem item xs={12}>
                        <Typography sx={{ textAlign: 'center', fontSize: '20px', color: 'primary.main' }}>Хямдрал, урамшуулал</Typography>
                    </StyledGridItem>
                    <StyledGridItem item xs={4}>
                        <TextField
                            variant='standard'
                            size='small'
                            label='Нэр'
                            {...getFieldProps('name')}
                            error={errors.name && touched.name}
                            helperText={errors.name && touched.name && errors.name}
                            sx={{ width: '230px' }}
                        />
                    </StyledGridItem>
                    <StyledGridItem item xs={4}>
                        <StartDatePicker setFieldValue={setFieldValue} initialValue={promo?.start_date} />
                    </StyledGridItem>
                    <StyledGridItem item xs={4}>
                        <EndDatePicker setFieldValue={setFieldValue} initialValue={promo?.end_date} />
                    </StyledGridItem>
                    <CheckInput label='Зөвхөн бэлнээр' field_name='is_cash' setFieldValue={setFieldValue} value={values?.is_cash} />
                    <StyledGridItem item xs={8}>
                        <TextField
                            variant='standard'
                            size='small'
                            label='Тайлбар'
                            {...getFieldProps('desc')}
                            error={errors.desc && touched.desc}
                            helperText={errors.desc && touched.desc && errors.desc}
                            sx={{ width: '82%' }}
                            multiline
                            maxRows={1}
                        />
                    </StyledGridItem>
                    <SelectionInput label='Хэрэглэгч сонгох' options={TARGET_TYPES} getFieldProps={getFieldProps} field='target_type' errors={errors} touched={touched} />
                    {
                        values?.target_type === 3
                                ?   <StyledGridItem item xs={12}>
                                        <SelectedCustomers selected={selected} setSelected={setSelected} error={errors?.target} />
                                    </StyledGridItem>
                                :   <StyledGridItem item xs={8} />
                    }
                    <SelectionInput label='Урамшууллын төрөл' options={PROMO_TYPES} getFieldProps={getFieldProps} field='promo_type' errors={errors} touched={touched} />
                    {   values?.promo_type === 2 &&
                        <PriceInput label='Нийт үнийн дүн' field_name='total' getFieldProps={getFieldProps} values={values} errors={errors} touched={touched} />
                    }
                    {
                        values?.promo_type === 2 &&
                        <PriceInput label='Хямдралын хувь' field_name='procent' getFieldProps={getFieldProps} values={values} errors={errors} touched={touched} />
                    }
                    { values?.promo_type === 2 && <CheckInput label='Тутам' field_name='is_per' setFieldValue={setFieldValue} value={values?.is_per} /> }
                    <StyledGridItem item xs={12}>
                        {
                            [1, 3].includes(values?.promo_type) &&
                            <SelectedProducts
                                selected={selectedProducts}
                                setSelected={setSelectedProducts}
                                error={errors?.bundle}
                                setFieldValue={setFieldValue}
                                totalPrice={totalPrice}
                                promoType={values?.promo_type}
                            />
                        }
                    </StyledGridItem>
                    {
                        values?.promo_type === 1 &&
                        <PriceInput label='Багцын үнэ' field_name='bundle_price' getFieldProps={getFieldProps} values={values} errors={errors} touched={touched} totalPrice={totalPrice} />
                    }
                    { values?.promo_type !== 3 && <CheckInput label='Бэлэгтэй' field_name='has_gift' setFieldValue={setFieldValue} value={values?.has_gift} /> }
                    <StyledGridItem item xs={12}>
                        {
                            (values?.promo_type === 3 || values.has_gift) &&
                            <SelectedProducts selected={selectedGifts} setSelected={setSelectedGifts} error={errors?.gift} isGift={true} setFieldValue={setFieldValue} />
                        }
                    </StyledGridItem>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 5, flexDirection: 'row' }}>
                    <Button size='small' variant='contained' onClick={handleSubmit}>{isEditing ? 'Шинэчлэх' : 'Нэмэх'}</Button>
                    <Button size='small' variant='contained' onClick={handleCancel} sx={{ bgcolor: 'secondary.light', ml: 2 }}>Болих</Button>
                </Box>
            </Dialog>}
        </AddPromoCtx.Provider>
    )
}
