import { Box, Button, Container, Paper, Toolbar, Typography } from '@mui/material'
import ThumbUpTwoTone from '@mui/icons-material/ThumbUpTwoTone'
import { useLocation, useNavigate } from 'react-router'
import React from 'react'
import './Completed.css'

export default function PromoOrderCompleted() {
    const lc = useLocation()
    const orderNo = lc?.state?.orderNo

    const navigate = useNavigate()
    const toOrderPace = () => { navigate('/profile/orders') }
    const toHome = () => { navigate('/') }

    React.useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }, [])
    
    return (
        <Container maxWidth="md" sx={{ mb: 4, mt: `16px` }}>
            <Toolbar />
            <Toolbar />
            <Paper sx={{ boxShadow: 10, p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', m: 2, mb: 4, minHeight: '50vh' }}>
                    <Box textAlign='center' sx={{ mb: 4 }}>
                        <ThumbUpTwoTone className='thumbsUp' sx={{ color: 'secondary.main', fontSize: '60px' }} />
                    </Box>
                    <Typography variant="h5" gutterBottom textAlign='center' sx={{ color: 'green' }}>Захиалга амжилттай хийгдлээ.</Typography>
                    {
                        orderNo &&
                        <Typography variant="subtitle1" textAlign='center' fontWeight='bold' sx={{ color: 'primary.main', mb: 8 }}>
                            {`Таны захиалгын дугаар: ${orderNo}`}
                        </Typography>
                    }
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 5 }}>
                        <Button variant='contained' sx={{ width: 'fit-content', mr: 2 }} onClick={toOrderPace}>Захиалгын мэдээлэл</Button>
                        <Button variant='outlined' sx={{ width: 'fit-content' }} onClick={toHome}>Нүүр хуудас</Button>
                    </Box>
                </Box>
            </Paper>
        </Container>
    )
}