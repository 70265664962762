import { primaryLight } from '../../../../../../../../utils/constants'
import { InputAdornment, TableCell, TextField } from '@mui/material'
import { useProductListCtx } from '../../..'
import { Close } from '@mui/icons-material'
import React, { useState } from 'react'

export default function ByName() {
    const { filters, setFilters } = useProductListCtx()
    const prevVal = filters?.find(f => f?.k === 'name')
    const [value, setValue] = useState(prevVal?.v || null)

    const doFilter = () => {
        if (value) {
            setFilters(prev => {
                const f = { k: 'name', v: value, o: '=' }
                if (prev?.length) {
                    if (prev.map(p => p?.k)?.includes('name')) {
                        return [ ...prev.map(p => p.k === 'name' ? f : p)]
                    } else { return [ ...prev, f ] }
                } else { return [f] }
            })
        }
    }

    const doClear = () => {
        setFilters(prev => prev?.filter(p => p.k !== 'name'))
        setValue(null)
    }
    const handleEnter = (e) => { if (e?.code === 'Enter') { doFilter() } }

    return (
        <TableCell sx={{ p: 0, px: '2px' }} key='name'>
            <TextField
                size='small'
                placeholder='Нэр'
                value={value||''}
                onChange={e => { setValue(e?.target?.value) }}
                sx={{
                    width: '100%',
                    '& fieldset': { borderColor: primaryLight, borderRadius: 0, borderWidth: prevVal ? 3 : 1 },
                    '&:hover fieldset': { borderColor: primaryLight },
                    '& .MuiInputBase-input::placeholder': { color: primaryLight, fontSize: '14px', opacity: 1, ml: '5px' },
                }}
                InputProps={{
                    endAdornment: (<InputAdornment>
                                        <Close
                                            sx={{
                                                fontSize: '18px', color: primaryLight,
                                                '&:hover': { cursor: 'pointer' },
                                                visibility: value ? 'visible' : 'hidden'
                                            }}
                                            onClick={doClear}
                                        />
                                    </InputAdornment>)
                }}
                inputProps={{ style: { padding: '3px 5px', fontSize: '14px', color: primaryLight } }}
                onKeyDown={ handleEnter }
                autoComplete='off'
            />
        </TableCell>
    )
}
