import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { scrollStyle } from '../../../../../utils/constants'
import { getShipments } from '../../../../../api/shipment'
import TablePages from '../../../../common/TablePages'
import ShipmentRow from './ShipmentRow'
import { useAdminCtx } from '../../..'
import { useQuery } from 'react-query'
import FilterRow from './FilterRow'
import { useShipCtx } from '..'
import React from 'react'

const headCells = [
    { field: 'no', label: '№' },
    { field: 'filter', label: '' },
    { field: 'delman', label: 'Түгээгч' },
    { field: 'createdOn', label: 'Огноо' },
    { field: 'startTime', label: 'Эхэлсэн цаг' },
    { field: 'endTime', label: 'Дууссан цаг' },
    { field: 'duration', label: 'Хугацаа' },
    { field: 'ordersCnt', label: 'Захиалгын тоо' },
    { field: 'progress', label: 'Явц' },
    { field: 'expense', label: 'Зарлага' },
]

export default function ShipmentHistory() {
    const { alertWarning } = useAdminCtx()
    const { staffNames, isDelman } = useShipCtx()
    const [filters, setFilters] = React.useState([])
    const [page, setPage] = React.useState(1)
    const [pageSize, setPageSize] = React.useState(20)
    const [count, setCount] = React.useState(0)
    const [shipments, setShipments] = React.useState(null)
    const getData = (data) => { setShipments(data?.data?.results||[]); setCount(data?.data?.count||0) }
    const { isFetched } = useQuery(['shipmentHistory', filters, page, pageSize], getShipments, { onError: alertWarning, retry: false, onSuccess: (data) => { getData(data) } } )
    React.useEffect(() => { setPage(1) }, [filters])

    const tf = React.useRef()
    const trHeight = tf?.current?.getBoundingClientRect()?.height||0

    const headCells2 = isDelman ? headCells.filter(hc => hc.field !== 'delman') : headCells

    return (
        <TableContainer sx={{ height: '100%', ...scrollStyle, '&::-webkit-scrollbar': { width: 8, height: 8 }, p: 1 }}>
            {
                isFetched && <Table stickyHeader>
                                <TableHead>
                                    <TableRow
                                        sx={{ 
                                            '& > th': {
                                                bgcolor: 'secondary.light', color: 'white',
                                                borderRight: '1px solid white', p: '2px 6px',
                                                textAlign: 'center'
                                            }
                                        }}
                                        ref={tf}
                                    >
                                        {headCells2.map((headCell) => (
                                            <TableCell key={headCell.field}>{headCell.label}</TableCell>
                                        ))}
                                    </TableRow>
                                    <FilterRow filters={filters} setFilters={setFilters} staffNames={staffNames} trHeight={trHeight} />
                                </TableHead>
                                <TableBody>
                                    {
                                        isFetched
                                            && shipments?.map((s, i) => {
                                                return  <ShipmentRow row={s} staffNames={staffNames} i={i} page={page} pageSize={pageSize} colLen={headCells.length} filters={filters} />
                                            })
                                    }
                                </TableBody>
                                <TablePages
                                    count={count} 
                                    page={page}
                                    setPage={setPage}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                />
            </Table>}
        </TableContainer>
    )
}
