import { Skeleton, Stack } from '@mui/material'
import React from 'react'

export default function ProductSkeleton() {
    return (
        <Stack
            sx={{
                height: { md: '210px', sm: '200px', xs: '190px' },
                width: { md: '180px', sm: '160px', xs: '150px' },
            }}
        >
            <Skeleton
                sx={{ mb: 1, borderRadius: 'inherit' }}
                variant='rectangular'
                width='100%'
                height='60%'
            />
            <Skeleton variant='text' fontSize='13px' />
            <Skeleton variant='text' fontSize='13px' />
        </Stack>
    )
}
