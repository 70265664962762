
export const getPageParams = (page, pageSize, qParam) => {
    if (page >= 0) {
        let pgs = `page=${page}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : qParam.endsWith('?') ? pgs : `&${pgs}`
    }
    if (pageSize) {
        let pgs = `page_size=${pageSize}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : qParam.endsWith('?') ? pgs : `&${pgs}`
    }
    return qParam
}

export const getFilterParams = (filters, url) => {
    if (filters) {
        for (let key in filters) {
            if (filters[key]) {
                url += url.endsWith('/') ? `?${key}=${filters[key]}` : `&${key}=${filters[key]}`
            }
        }
    }
    return url
}