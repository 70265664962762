import { scrollStyle } from '../../../../../../../../utils/constants'
import { Box, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import Product from './Product'
import React from 'react'

export default function GiftProductBox({ products }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 5 }}>
            <Add sx={{ fontSize: '25px', fontWeight: 700, my: 2 }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 1, boxShadow: 5 }}>
                <Box 
                    sx={{
                        display: 'flex', flexDirection: 'row', gap: 1, justifyContent: 'center',
                        flexWrap: 'wrap', width: '500px', minHeight: '210px', maxHeight: '430px',
                        overflowY: 'auto', 
                        ...scrollStyle,
                        '&::-webkit-scrollbar': { width: 8 },
                        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.light', borderRadius: 1 },
                        p: 2
                    }}
                >
                    {
                       products?.map((product, idx) => (
                                    <Product key={product.id} product={product} />
                                ))
                    }
                </Box>
                <Typography sx={{ color: 'primary.main', mb: 1 }}>бэлгэнд аваарай!</Typography>
            </Box>
        </Box>
    )
}
