import { Box, CircularProgress, TableBody, TableCell } from '@mui/material'
import { getConfirmed, getToConfim } from '../../../../../api/confirm'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import TablePages from '../../../../common/TablePages'
import { useLocation } from 'react-router'
import { useQuery } from 'react-query'
import { sortBy } from 'underscore'
import { Row } from './Row'
import Edit from './Edit'
import React from 'react'

export default function TBody({}) {
    // msg
    const [okMsg, setOkMsg] = React.useState(null)
    const okOff = () => { setOkMsg(null) }
    const [err, setErr] = React.useState(null)
    const errOff = () => { setErr(null) }

    const [orgs, setOrgs] = React.useState(null)
    const [count, setCount] = React.useState(0)    
    const [page, setPage] = React.useState(1)
    const [pageSize, setPageSize] = React.useState(20)
    const loc = useLocation()
    const getData = (res) => {
        setOrgs(res?.data?.results?.length > 0 ? sortBy(res.data.results, 'aemail') : [])
        setCount(res?.data?.count||0)
    }
    const { isFetching, refetch } = useQuery(
        ['orgs', page, pageSize, loc?.pathname], loc?.pathname === '/confirmation/main' ? getToConfim : getConfirmed,
        { 
            enabled: true, 
            onSuccess: (res) => { getData(res) },
            onError: (e) => { setErr('Серверийн алдаа!') }
        }
    )
    //console.log("C: ", count)

    // sel
    const [sel, setSel] = React.useState(null)
    const handleClose = () => { setSel(null) }

    return (
        <>
            {
                isFetching
                    ?   <TableCell colSpan={12} sx={{ p: 10 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>
                        </TableCell>
                    :   <TableBody>
                            {
                                orgs?.map((b, idx) => <Row key={`key_${idx}`} b={b} idx={idx} setSel={setSel} />) 
                            }
                        </TableBody>
            }
            { getToast(Boolean(err), errOff, err, 'warning', 1500) }
            { getToast(Boolean(okMsg), okOff, okMsg, 'success', 1500) }
            {
                sel && 
                    <Edit
                        sel={sel}
                        handleClose={handleClose}
                        refetch={refetch}
                        setErr={setErr}
                        setOkMsg={setOkMsg}
                    />
            }
            {
                <TablePages
                    count={count} 
                    page={page} 
                    setPage={setPage} 
                    pageSize={pageSize} 
                    setPageSize={setPageSize}
                />
            }
        </>
    )
}
