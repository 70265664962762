import { getPharmaciesList } from '../../api/seller'
import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Orderer from './orderer'

export default function Seller() {
    const [pharmas, setPharmas] = useState([])
    console.log("PHARMAS: ", pharmas)
    useEffect(() => {
        getPharmaciesList()
            .then(res => { setPharmas(res?.data?.pharmacies) })
            .catch(err => { console.log("ERR: ", err) })
    }, [])

    return (
        <Box>
            <Orderer pharmas={pharmas} />
        </Box>
    )
}
