import { Box, Button, IconButton, ListItemText, Typography } from '@mui/material'
import { NotificationImportant } from '@mui/icons-material'
import { StyledBadge } from '../../../helpers/styledBadge'
import { useBcCtx } from '../../../../context/BroadcastCtx'
import React, { useEffect, useState } from 'react'
import { getNotifs } from '../../../../api/notif'
import DropMenu from '../../../helpers/DropMenu'
import { useLocation } from 'react-router'
import Items from './Items'

export const getLocalNotifs = () => {
    let c = window.localStorage.getItem('notifs')
    return c ? JSON.parse(c) : null
}

const updateNotifs = (newOne) => {
    const prev = getLocalNotifs()
    if (prev) {
        window.localStorage.setItem('notifs', JSON.stringify({ ...prev, ...newOne }))
    } else {
        window.localStorage.setItem('notifs', JSON.stringify(newOne))
    }
}

const toObj = (arr, isSaw) => {
    return arr.reduce((acc, n) => {
        acc[n.id] = isSaw
        return acc
    }, {})
}

export default function Notification() {
    const [notifs, setNotifs] = useState(null)
    const loc = useLocation()
    const isAdmin = loc?.pathname?.includes('/admin')
    const [cnt, setCnt] = useState(null)

    const { msg } = useBcCtx()
    useEffect(() => { if (msg?.type === 'notif') { fetchNotifs() } }, [msg])

    const fetchNotifs = () => {
        getNotifs()
            .then(res => {
                if (res?.data?.length) {
                    setNotifs(res?.data)
                } else {
                    setNotifs(null)
                }
            })
            .catch(e => { console.log("notif ERR: ", e) })
    }

    useEffect(() => { fetchNotifs() }, [])

    useEffect(() => {
        if (notifs?.length) {
            const prev = getLocalNotifs()
            if (prev) {
                const newNotifs = notifs.filter(n => !prev?.[n.id])
                if (newNotifs.length) {
                    updateNotifs({...toObj(newNotifs, false), ...prev})
                    setCnt(newNotifs.length)
                }
            } else {
                updateNotifs(toObj(notifs, false))
                setCnt(notifs.length)
            }
        }
    }, [notifs])
    
    return (
        <DropMenu
            title={
                <IconButton>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <StyledBadge
                            color='secondary'
                            showZero={false}
                            badgeContent={
                                cnt > 0
                                    ?   <Typography color='white' fontSize='14px'>{cnt}</Typography>
                                    :   0
                                }
                        >
                            {
                                isAdmin
                                    ?   <Button
                                            sx={{ 
                                                color: 'white', justifyContent: 'start', borderBottom: '1px solid transparent',
                                                '&:hover': { borderBottomColor: 'white', borderRadius: 0 }, ml: 1, p: 0, fontSize: '13px'
                                            }}
                                        >Мэдэгдэл</Button>
                                    :   <NotificationImportant sx={{ color: "white", '&:hover': { color: '#e2e2e2' }, fontSize: 30 }} />
                            }
                        </StyledBadge>
                        {
                            !isAdmin &&
                            <ListItemText
                                sx={{ color: 'white', fontSize: '14px' }}
                                primary={<Typography fontSize='13px'>Мэдэгдэл</Typography>}
                            />
                        }
                    </Box>
                </IconButton>
            }
            detail={<Items notifs={notifs} updateNotifs={updateNotifs} setCnt={setCnt} />}
            top={isAdmin ? 35 : 70}
            left={isAdmin ? -130 : -150}
        />
    )
}
