import { Button, IconButton, InputAdornment, TextField, Tooltip, Typography, Zoom } from '@mui/material'
import { otpYup } from '../../../../utils/constants'
import { getToast } from '../../helpers/ToastAlert'
import React, { useState, useEffect } from 'react'
import { getOTP } from '../../../../api/auth'
import { Refresh } from '@mui/icons-material'
import { Timer } from '../../helpers/Timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const validationSchema = Yup.object({ ...otpYup })

export default function GetVerifyOTP({ email, onVerify, otpError = null }) {
    const [otpErr, setOtpErr] = useState('')
    useEffect(() => { if (otpError) { setOtpErr(otpError) } }, [otpError])

    const [sentAlert, setSentAlert] = useState(false)
    const handleSentAlertOpen = () => { setSentAlert(true) }
    const handleSentAlertClose = () => { setSentAlert(false) }

    const [waitAlert, setWaitAlert] = useState(false)
    const handleWaitOpen = () => { setWaitAlert(true) }
    const handleWaitClose = () => { setWaitAlert(false) }

    // get otp
    const [leftover, setLeftover] = useState(null)
    const [isReqSending, setIsReqSending] = useState(false)

    const sendOtp = () => {
        setIsReqSending(true)
        getOTP(email)
            .then(_ => { handleSentAlertOpen() })
            .catch(e => { 
                console.log("ERR::: ", e)
                if (e?.response?.status === 425) {
                    setLeftover(e?.response?.data)
                    handleWaitOpen()
                }
            })
            .finally(() => { setIsReqSending(false) })
    }

    useEffect(() => {
        sendOtp()
        const elm = document.getElementById('otp')
        if (elm) { elm.focus() }
    }, [])

    const onSubmit = ({ otp }) => { onVerify({ email, otp }) }
    const { handleSubmit, getFieldProps, errors } = useFormik({
        initialValues: { otp: '' },
        onSubmit,
        validationSchema
    })

    // tooltip
    const [tooltip, setTooltip] = useState(false)
    const handleTooltipOpen = () => { setTooltip(true) }
    const handleTooltipClose = () => { setTooltip(false) }
    useEffect(() => {
        if (tooltip) { setTimeout(() => { handleTooltipClose() }, 3000) }
    }, [tooltip])
    useEffect(() => {
        if (otpErr?.includes('код авна уу')) { handleTooltipOpen() }
    }, [otpErr])

    const handleGetOtp = () => { leftover ? handleWaitOpen() : sendOtp() }

    const handleEnter = (e) => { if (e.key === 'Enter') { handleSubmit() } }

    return (
        <>
            <TextField
                label='Баталгаажуулах код'
                name='otp'
                size='small'
                sx={{ width: '100%', mb: 2 }}
                InputProps={{
                    endAdornment: <InputAdornment position='end'>
                                    {(Boolean(leftover) && isReqSending === false) && <Timer initial={leftover} setRemaining={setLeftover} />}
                                    <Tooltip 
                                        title={<Typography fontSize={'13px'}>Дахин код авах</Typography>} 
                                        TransitionComponent={Zoom} 
                                        placement='top' sx={{ fontSize: '26px' }}
                                        disableHoverListener
                                        disableFocusListener
                                        open={tooltip}
                                        onOpen={handleTooltipOpen}
                                        onClose={handleTooltipClose}
                                    >
                                        <IconButton 
                                            sx={{ m: 0, color: leftover ? '#757575' : 'primary.main' }} 
                                            onClick={ handleGetOtp }
                                            onMouseEnter={ handleTooltipOpen }
                                            onMouseLeave={ handleTooltipClose }
                                        >
                                            <Refresh />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                }}
                {...getFieldProps('otp')}
                id='otp'
                error={ Boolean(errors?.otp) || Boolean(otpErr) }
                helperText={ Boolean(errors?.otp) ? errors.otp : otpErr ? otpErr : ' ' }
                onKeyDown={handleEnter}
            />
            <Button variant='contained' size='small' sx={{ p: '6.5px', width: '100%' }} onClick={ handleSubmit }>
                Баталгаажуулах
            </Button>
            { getToast(waitAlert, handleWaitClose, 'Түр хүлээнэ үү!', 'warning') }
            { getToast(sentAlert, handleSentAlertClose, 'Имэйл хаяг руу баталгаажуулах код илгээлээ!') }
        </>
    )
}