import AxiosWithToken from "../global/axiosBase"

// seller order

// get pharm info // Харилцагч биш эмийн сангийн мэдээлэл
export const getPharmaciesList = () => {
    return AxiosWithToken.get('seller/pharmacy_list/')
}
// register pharm
export const regPharm = (pharmInfo) => {
    return AxiosWithToken.post('seller/reg_pharmacy/', pharmInfo)
}
// register pharm
export const addPharmAddress = (addressInfo) => {
    return AxiosWithToken.patch('seller/add_address/', addressInfo)
}

// get seller orders
export const getOrdersBySeller = () => {
    return AxiosWithToken.get('seller/order/')
}

// get seller order
export const getOrderBySeller = (orderId) => {
    return AxiosWithToken.get(`seller/order/${orderId}/`)
}

// add seller order
export const addOrderBySeller = (orderInfo) => {
    return AxiosWithToken.post('seller/order/', orderInfo)
}

// update seller order
export const updateOrderBySeller = (orderId, orderInfo) => {
    return AxiosWithToken.patch(`seller/order/${orderId}/`, orderInfo)
}

// make return
export const makeReturn = (data) => {
    return AxiosWithToken.patch('update_item_qty/', { ...data })
}