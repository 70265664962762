import { scrollStyle } from '../../../../../../../../utils/constants'
import { Box, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import Product from './Product'
import React from 'react'

export default function ProductsBox({ products, isGift = false, isProduct = false }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {
                isGift
                    ?   <Add sx={{ fontSize: '25px', fontWeight: 700, mb: 2, mt: isProduct ? 2 : 0 }} />
                    :   <Typography sx={{ color: 'primary.main', mb: 1 }}>{isProduct ? '' : 'Багц:'}</Typography>
            }
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 1, boxShadow: 5 }}>
                <Box 
                    sx={{
                        display: 'flex', flexDirection: 'row', gap: 1, justifyContent: isGift ? 'center' : 'initial',
                        flexWrap: 'wrap', width: 'fit-content', maxWidth: '490px', minHeight: '200px', maxHeight: '430px',
                        overflowY: 'auto',
                        ...scrollStyle,
                        '&::-webkit-scrollbar': { width: 8 },
                        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.light', borderRadius: 1 },
                        p: 1
                    }}
                >
                    {
                       products?.map((product, idx) => (
                                    <Product key={product.id} product={product} />
                                ))
                    }
                </Box>
                {isGift && <Typography sx={{ color: 'primary.main', mb: 1 }}>бэлгэнд аваарай!</Typography>}
            </Box>
        </Box>
    )
}
