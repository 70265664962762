import React, { createContext, useContext, useState } from "react";
import { getToast } from "../../../auth/helpers/ToastAlert";
import { isTokenValid } from "../../../../utils/functions";
import { getOrderStaffs } from "../../../../api/staff";
import { useQuery } from "react-query";
import Orders from "./Orders";

const OrderListCtx = createContext();
export const useOrderListCtx = () => useContext(OrderListCtx);
export default function Order() {
  const { role } = isTokenValid();
  const isAB = ["A", "B"].includes(role);
  // get staffs(packer and delman) list for Booking manager
  const { data: staffData } = useQuery("get_staffs", getOrderStaffs, {
	enabled: ["A", "B"].includes(role),
  });
 // console.log("staffData: ", staffData);
  const staffs = staffData?.data?.reduce((acc, cur) => {
	if (!acc["delmans"]) {
	  acc["delmans"] = [];
	}
	if (!acc["packers"]) {
	  acc["packers"] = [];
	}
	if (!acc["sellers"]) {
	  acc["sellers"] = [];
	}
	// console.log("C: ", cur?.role)
	cur?.role === "Түгээгч"
		? acc["delmans"].push(cur)
		: cur?.role === "Борлуулагч"
			? acc["sellers"].push(cur)
			: acc["packers"].push(cur);
	return acc;
  }, {});
  const { delmans, packers, sellers } = staffs ?? {};

  // select orders
  const [sels, setSels] = useState([]);
  const handleSelect = (id) => {
	if (sels.includes(id)) {
	  setSels(sels.filter((s) => s !== id));
	} else {
	  setSels([...sels, id]);
	}
  };
  const selectAll = (ids) => {
	const f = ids?.find((i) => !sels.includes(i));
	if (f) {
	  const newIds = ids.filter((id) => !sels.includes(id));
	  setSels((prev) => [...prev, ...newIds]);
	} else {
	  setSels((prev) => prev.filter((id) => !ids.includes(id)));
	}
  };

  // pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  // filter
  const [filters, setFilters] = useState(null);
  const handleFilters = (value) => {
	setFilters(value);
	setPage(1);
  };
  const hasFilter = filters?.length > 0;

  // ordering
  const [ordering, setOrdering] = useState("ordering=-id");
  const handleOrdering = () => {
	setOrdering(ordering === "ordering=-id" ? "ordering=id" : "ordering=-id");
  };

  // alert
  const [alert, setAlert] = useState(null);
  const alertOff = () => {
	setAlert(null);
  };

  return (
	<OrderListCtx.Provider
	  value={{
		delmans,
		packers,
		sellers,
		staffs,
		role,
		isAB,
		sels,
		selectAll,
		handleSelect,
		// query
		filters,
		handleFilters,
		hasFilter,
		ordering,
		handleOrdering,
		page,
		setPage,
		pageSize,
		setPageSize,
		// alert
		setAlert,
	  }}
	>
	  {getToast(Boolean(alert?.m), alertOff, alert?.m, alert?.s, 2000)}
	  <Orders />
	</OrderListCtx.Provider>
  );
}
