import { LogoutOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { removeTokens } from '../../utils/functions'
import { Axios } from '../../global/axiosBase'
import MyTooltip from '../helpers/MyTooltip'
import { useAuthCtx } from '../../context/AuthCtx'

const Logout = () => {
    const { clearUserItems } = useAuthCtx()

    const handleLogout = () => {
        Axios.post('auth/logout/')
            .then(_ => {
                removeTokens()
                clearUserItems()
                window.location.href = '/signin'
            })
            .catch(e => console.log(e))
    }

    return (
        <MyTooltip title='Гарах' X={40}>
            <Button onClick={() => handleLogout()} variant='contained'>
                <LogoutOutlined />
            </Button>
        </MyTooltip>
    )
}

export default Logout