import { Box, Button, Dialog, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { deleteTargetGroup, getTargetGroup, getTargetGroups } from '../../../../../../../../api/promo'
import { scrollStyle } from '../../../../../../../../utils/constants'
import { useAdminCtx } from '../../../../../..'
import { Close } from '@mui/icons-material'
import React, { useEffect } from 'react'
import { useAddPromoCtx } from '../..'
import { useQuery } from 'react-query'
import { CheckCell } from '../Cells'
import DeleteBtn from './DeleteBtn'

export default function SelectFromList({ setSelected }) {
    const { selectedTargetGroup, setSelectedTargetGroup, isNewListAdded, setIsNewListAdded } = useAddPromoCtx()
    const { alertSuccess, alertWarning } = useAdminCtx()
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }

    const { data, isFetched, refetch } = useQuery(['get_target_groups'], getTargetGroups, { retry: 1 })
    useEffect(() => { if (isNewListAdded) { refetch(); setIsNewListAdded(false) } }, [isNewListAdded, refetch])
    const groups = data?.data?.results||[]

    const addToSelected = (group) => {
            getTargetGroup(group.id)
                .then(res => {
                    const customersToAdd = res?.data?.customers
                    const isIncluded = selectedTargetGroup.find(stg => stg.id === group.id)
                    if (isIncluded) {
                        // remove all customers from selected
                        const currentCustomers = selectedTargetGroup?.filter(tg => tg.id !== group.id)?.reduce((acc, stg) => ([...acc, ...stg.customers]), [])
                        const toRemove = customersToAdd.filter(c => !currentCustomers.find(s => s.id === c.id))
                        setSelected(prev => ([...prev.filter(s => !toRemove?.find(n => n.id === s.id))]))
                        // remove group from selected
                        setSelectedTargetGroup(prev => prev.filter(s => s.id !== group.id).filter(s => !s.customers.every(c => toRemove.find(t => t.id === c.id))))
                    } else {
                        setSelected(prev => ([...prev, ...customersToAdd.filter(n => !prev.some(s => s.id === n.id))]))
                        setSelectedTargetGroup(prev => ([...prev, res?.data]))
                    }
                })
                .catch((err) => { console.log("ERR: ", err) })
    }
    const handleDelete = (groupId) => {
        deleteTargetGroup(groupId)
            .then(() => { refetch(); alertSuccess() })
            .catch(err => { alertWarning() })
    }

    if (!isFetched || !groups.length) return null

    return (
        <>
            <Button variant='contained' size='small' onClick={handleOpen} sx={{ ml: 1 }}>Хадгалсан жагсаалтууд</Button>
            <Dialog open={open} onClose={handleClose} maxWidth='md'>
                <Box sx={{ p: 2, width: 730, height: 450 }}>
                    <Box sx={{ textAlign: 'right', mb: 1 }}>
                        <IconButton sx={{ p: '2px' }} onClick={handleClose}><Close /></IconButton>
                    </Box>
                    <Paper sx={{ p: 1, display: 'flex', justifyContent: 'space-between', boxShadow: 5 }}>
                        <TableContainer sx={{ height: 350, maxHeight: 350, ...scrollStyle, '&::-webkit-scrollbar': { width: 8 } }}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ '& > th': { py: '2px', px: '10px', backgroundColor: 'secondary.light', color: 'white', borderRight: '1px solid white' } }}>
                                        <TableCell width='20px' />
                                        <TableCell sx={{ px: '4px !important' }} width='25px'>№</TableCell>
                                        {
                                            ['Жагсаалтын нэр', 'Харилцагчдын тоо', 'Устгах'].map(
                                                (col, idx) => <TableCell key={idx} sx={{ textAlign: col === 'Жагсаалтын нэр' ? 'left' : 'center' }}>{col}</TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        groups.map((group, idx) =>
                                            <TableRow
                                                key={`_${group.id}`}
                                                sx={{ '& > td': { py: '1px', px: '10px' }, '&:hover': { backgroundColor: '#e5e5e5' }, cursor: 'pointer' }}
                                                onClick={() => {addToSelected(group)}}
                                            >
                                                <CheckCell checked={Boolean(selectedTargetGroup?.find(stg => stg.id === group.id))} />
                                                <TableCell sx={{ textAlign: 'center !important' }}>{idx+1}</TableCell>
                                                <TableCell>{group.name||'-'}</TableCell>
                                                <TableCell sx={{ textAlign: 'center !important' }}>{group.customers?.length||'-'}</TableCell>
                                                <DeleteBtn handleDelete={handleDelete} groupId={group.id} />
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Box>
            </Dialog>
        </>
    )
}
