import { addCategory, getCategories, updateCategory } from "../../../../api/category"
import { Box, IconButton, Link, TextField, Tooltip, Typography } from "@mui/material"
import { getToast } from "../../../auth/helpers/ToastAlert"
import { SaveTwoTone } from "@mui/icons-material"
import { useQuery } from "react-query"
import Category from "./Category"
import { useState } from "react"

export const categoryItemWidth = '450px'

export default function Categories () {
	const { data: categoryData, refetch, isFetching } = useQuery('get_categories', getCategories)
	const categories = categoryData?.data ? getChilds(categoryData.data) : []
	const [selectedCategory, setSelectedCategory] = useState({})
	const [newMainCategoryName, setNewMainCategoryName] = useState('')

	const [msg, setMsg] = useState(null)
	const msgOff = () => { setMsg(null) }
	const addNewMainCategory = () => {
		addCategory({ 'name': newMainCategoryName, 'parent': null })
			.then(res => {
				refetch()
				setNewMainCategoryName('')
				setMsg({ m: 'Амжилттай!', s: 'success' })
			})
			.catch(
				e => {
					if (e?.response?.data?.non_field_errors?.[0]?.startsWith('The fields name, supplier')) {
						setMsg({ m: 'Ангиллын нэр давхцаж байна!', s: 'warning' })
					} else { setMsg({ m: 'Алдаа гарлаа!', s: 'warning' }) }
				}
			)
	}
	
	const handleKeyDown = (e) => { if (e.code === 'Enter') { addNewMainCategory() } }

	// drag & drop
	const [toMove, setToMove] = useState(false)
	const handleToMove = () => { setToMove(!toMove) }
	
	const [topBtms, setTopBtms] = useState([])
	const passTopBtm = (item) => { setTopBtms(prev => {
		const prevIds = prev.map(p => p.id)
		if (prevIds.includes(item.id)) { return [...prev.filter(p => p?.id !== item.id), item] }
		return [...prev, item]
	})}

	const handleDrop = (e) => {
		e.preventDefault()
		const d = e.dataTransfer.getData('text/plain')
		const y = e.clientY
		if (d && y) {
			const ct = topBtms.find(t => t.bottom > y && y > t.top)
			if (ct?.id !== parseInt(d)) {
				updateCategory(d, { parent: ct?.id||null })
					.then(_ => { refetch() })
					.catch(e => setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }))
			}
		}
	}
	const handleDragOver = (e) => { e.preventDefault() }
  
	return (
			<>
				{
				categories
					? 	<Box
							sx={{
								backgroundColor: '#fff', borderRadius: 2, backgroundColor: 'white',
								m: 1, p: 2, boxShadow: 5
							}}
							
							onDrop={handleDrop}
							onDragOver={handleDragOver} 
						>
							{getToast(Boolean(msg), msgOff, msg?.m, msg?.s, 1500)}
							<Box sx={{ position: 'relative', width: categoryItemWidth, ml: 5, mb: 1 }}>
								<TextField
									size='small'
									label='Үндсэн ангилал нэмэх'
									value={newMainCategoryName}
									onChange={(e) => setNewMainCategoryName(e.target.value)}
									sx={{ width: '100%' }}
									onKeyDown={(e) => handleKeyDown(e)}
									inputProps={{ style: { fontSize: '13px', padding: '4px' } }}
									InputLabelProps={{ style: { fontSize: '13px', top: -5 } }}
								/>
								<IconButton onClick={addNewMainCategory} sx={{ position: 'absolute', p: '5px', mr: 2, top: 0, right: 0 }}>
									<Tooltip title='Хадгалах' placement='top-start' arrow>
										<SaveTwoTone sx={{ color: 'secondary.main', fontSize: '20px' }} />
									</Tooltip>
								</IconButton>
								<Link href='#' onClick={handleToMove}>
									<Typography sx={{ m: 1, ml: 0, textAlign: 'end', width: categoryItemWidth }}>{toMove ? 'Болих' : 'Дараалал өөрчлөх'}</Typography>
								</Link>
							</Box>
							<Category 
								categories={categories} 
								selected={selectedCategory} 
								onSelect={setSelectedCategory} 
								refetchCategories={refetch} 
								passTopBtm={passTopBtm}
								toMove={toMove}
								isFetching={isFetching}
								setMsg={setMsg}
							/>
						</Box>
					: 	null
				}
			</>
		)
}

// for categories
const getChildIds = (arrayOfObjects, arr) => {
	arrayOfObjects.forEach(obj => {
		arr.push(obj.id)
		if (obj?.children?.length > 0) {
			getChildIds(obj.children, arr)
		}
	})
  	return arr
}
const getChilds = (arrayOfObjects) => {
	return arrayOfObjects.map(obj => {
		let changed = {
			...obj,
			childs: getChildIds(obj.children, []),
			children: getChilds(obj.children)
		}
		return changed
	})
}