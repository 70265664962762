import { primaryLight } from '../../../../../../../utils/constants'
import { changeStatus } from '../../../../../../../api/order'
import { STATUS_TYPES } from '../../../../../../constants'
import { Box, Typography } from '@mui/material'
import TableCellMenu from './TableCellMenu'
import { useOrderListCtx } from '../../..'
import { keys } from 'underscore'
import React from 'react'

export default function SelectStatusCell({ row, setRow }) {
    const { isAB, setAlert } = useOrderListCtx()

    // pop current choice
    const excs = row.qp ? [row.status, 'W', 'C'] : [row.status, 'C']
    const choices = Object.fromEntries(Object.entries(STATUS_TYPES).filter(([key]) => !excs.includes(key))) || {}

    // select status
    const handleChange = (k) => {
        changeStatus({ id: row?.id, status: k })
            .then(res => { setRow(res?.data) })
            .catch(e => { 
                setAlert({m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning'})
                console.log("statusERR: ", e)
            })
    }

    return (
        <TableCellMenu
            current={<Typography fontSize='13px' noWrap sx={{ textAlign: 'center' }}>{STATUS_TYPES[row?.status]??'-'}</Typography>}
            dropMenu={
                <Box
                    sx={{
                        display: isAB ? 'flex' : 'none', flexDirection: 'column',
                        backgroundColor: 'white', boxShadow: 8
                    }}
                >
                    {keys(choices).map(k => (
                        <Typography
                            key={`${k}`}
                            onClick={ () => { handleChange(k) } }
                            sx={{
                                borderBottom: '1px solid transparent', borderRadius: 0, 
                                p: '4px 8px', fontSize: '13px', color: primaryLight,
                                '&:hover': { cursor: 'pointer', backgroundColor: '#f1f1f1', borderBottomColor: '#ad5555' }
                            }}
                        >{choices[k]}</Typography>
                    ))}
                </Box>
            }
        />
    )
}
