import { Dialog, DialogContent, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { toPrice } from '../../../../../../../utils/functions'
import React from 'react'

export function TimeCell({ time }) {
    //const parts = time?.split(':')
    //const partsStr = parts?.length > 0 ? `${parts[0]} цаг ${parts[1]} минут ${parts[2]} секунд` : null
    return (<TableCell align='center' sx={{ width: '200px' }}>{time||'-'}</TableCell>)
}
const divmod = (x, y) => [Math.floor(x / y), x % y];
export function DurationCell({ duration }) {
    const [days, remainder] = divmod(duration, 86400)
    const [hours, remainder2] = divmod(remainder, 3600)
    const [minutes, seconds] = divmod(remainder2, 60)
    let timeStr = ''
    if (seconds && seconds > 0) { timeStr += `${Math.floor(seconds)} секунд` }
    if (minutes && minutes > 0) { timeStr = `${minutes} минут ${timeStr}` }
    if (hours && hours > 0) { timeStr = `${hours} цаг ${timeStr}` }
    if (days && days > 0) { timeStr = `${days} өдөр ${timeStr}` }
    return (<TableCell align='center' sx={{ width: '200px' }}>{timeStr||'-'}</TableCell>)
}

export function ExpenseCell({ expenseSum, shipmentId, getExpenseDetail, expenseDetail, setExpenseDetail }) {
    const handleClick = () => { if (expenseSum) { getExpenseDetail(shipmentId) } }
    return (
        <>
            <TableCell
                align='center'
                onClick={handleClick}
                sx={{ 
                    cursor: expenseSum ? 'pointer' : 'initial', color: 'primary.light', fontWeight: 600,
                    '&:hover': { 
                        textDecoration: expenseSum ? 'underline' : 'none',
                        color: expenseSum ? 'secondary.light' : 'primary.light'
                    } 
                }}
            >
                {
                    expenseSum
                        ?   `${toPrice(expenseSum)}`
                        :   '-'
                }
            </TableCell>
            <Dialog open={expenseDetail?.id === shipmentId} onClose={() => { setExpenseDetail(null) }}>
                <DialogContent sx={{ p: 1 }}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ '& > th': { p: '2px 6px', bgcolor: 'secondary.light', color: 'white', borderRight: '1px solid white', textAlign: 'center' } }}>
                                <TableCell>№</TableCell>
                                <TableCell>Зарлагын дүн</TableCell>
                                <TableCell>Тайлбар</TableCell>
                                <TableCell>Огноо</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ '& > tr > td': { color: 'primary.light', p: '4px 6px' } }}>
                            {
                                expenseDetail?.data?.map((e, i) => (
                                    <TableRow key={e.id}>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>{toPrice(e.amount)}</TableCell>
                                        <TableCell>{e.note}</TableCell>
                                        <TableCell>
                                            {
                                                e.createdOn
                                                    ?   e.createdOn//.split(' ')?.[1]?.split(':')?.reduce((acc, cur, idx) => acc += idx === 0 ? `${cur} цаг ` : idx === 1 ? `${cur} минут ` : `${cur} секунд`, '')
                                                    :   '-'
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </>
    )
}