import React, { useRef, useState } from 'react'
import { Box, Grow } from '@mui/material'

export default function DropCellMenu({ title, detail }) {
    const [isHover, setIsHover] = useState(false)
    const handleHoverOff = () => { setIsHover(false) }

    const rf = useRef(null)
    const rfBox = rf?.current?.getBoundingClientRect()

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '100%' }}
            onMouseEnter={(e) => { setIsHover(true); e.stopPropagation() }}
            onMouseLeave={(e) => { handleHoverOff(); e.stopPropagation() }}
        >
            <Box sx={{ width: '100%' }} ref={rf}>{title}</Box>
            <Grow in={isHover}>
                <Box 
                    sx={{ 
                        position: 'absolute', zIndex: 1600, width: rfBox?.width,
                        top: rfBox?.height, left: '2px', display: isHover ? 'block': 'none'
                    }}
                >{detail}</Box>
            </Grow>
        </Box>
    )
}
