import { Box, Grow, TableCell } from '@mui/material'
import React, { useRef, useState } from 'react'

export default function TableCellMenu({ current, dropMenu }) {
    const [isHover, setIsHover] = useState(false)
    const handleHoverOff = () => { setIsHover(false) }

    const rf = useRef(null)
    const cellBox = rf?.current?.getBoundingClientRect()

    return (
        <TableCell
            ref={rf}
            sx={{ position: 'relative', p: 0, '&:hover': { backgroundColor: '#e1e1e1', cursor: 'pointer' } }}
            onMouseEnter={(e) => { setIsHover(true); e.stopPropagation() }}
            onMouseLeave={(e) => { handleHoverOff(); e.stopPropagation() }}
        >
            {current}
            <Grow in={isHover}>
                <Box 
                    sx={{ 
                        position: 'absolute', zIndex: 15000, width: cellBox?.width,
                        top: cellBox?.height, left: '2px', display: isHover ? 'block': 'none'
                    }}
                >{dropMenu}</Box>
            </Grow>
        </TableCell>
    )
}

export const PROMO_TYPES = {
    1: 'Багцын урамшуулал',
    2: 'Худалдан авалтын урамшуулал',
    3: 'Барааны урамшуулал',
}
export const TARGET_TYPES = {
    1: 'Бүх хэрэглэгч',
    2: 'Шинэ хэрэглэгч',
    3: 'Зорилтот харилцагч',
    4: 'Бүх харилцагч'
}
