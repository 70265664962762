import { Box, Dialog, IconButton, Table, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { AccountCircle, Close } from '@mui/icons-material'
import { isTokenValid } from '../../../../utils/functions'
import { primaryLight } from '../../../../utils/constants'
import React, { useEffect, useState } from 'react'
import { getStaffs } from '../../../../api/staff'

const roles = {
	A: 'Админ', 
	S: 'Борлуулагч', 
	D: 'Түгээгч', 
	B: 'Захиалгын менежер', 
	P: 'Бэлтгэгч'
}

export default function StaffInfo({ user_id }) {
    const { role, company_name } = isTokenValid()
    const [open, setOpen] = useState(false)
    const [staff, setStaff] = useState(null)
    const handleClose = () => { setOpen(false) }
    useEffect(() => {
		if (open && staff === null) {
            getStaffs(user_id)
            .then(r => { setStaff(r?.data) })
            .catch(_ => {})
        }
	}, [open, staff])

    return (
        <>
            <Box
                onClick={() => { setOpen(true) }}
                sx={{ 
                    display: 'flex', flexDirection: 'row', 
                    justifyContent: 'center', alignItems: 'center', 
                    flexGrow: 1, gap: 1, '&:hover': { cursor: 'pointer' }
                }}
            >
                <AccountCircle sx={{ color: primaryLight, fontSize: '30px' }} />
                <Typography sx={{ color: 'primary.light', textAlign: 'center', fontSize: '14px' }}>{company_name||roles?.[role]||''}</Typography>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
                    <IconButton sx={{ mb: 2 }} onClick={handleClose}><Close sx={{ color: primaryLight }}/></IconButton>
                    <TableContainer sx={{ mb: 2 }}>
                        <Table sx={{ '& > tr > td': { p: '6px 8px' } }}>
                            <TableRow><TableCell>Нэр</TableCell><TableCell sx={{ color: primaryLight, fontWeight: 'bold' }}>{staff?.name}</TableCell></TableRow>
                            <TableRow><TableCell>Имэйл</TableCell><TableCell sx={{ color: primaryLight, fontWeight: 'bold' }}>{staff?.email}</TableCell></TableRow>
                            <TableRow><TableCell>РД</TableCell><TableCell sx={{ color: primaryLight, fontWeight: 'bold' }}>{staff?.rd}</TableCell></TableRow>
                            <TableRow><TableCell>Утас</TableCell><TableCell sx={{ color: primaryLight, fontWeight: 'bold' }}>{staff?.phone}</TableCell></TableRow>
                            <TableRow><TableCell>Ажил үүрэг</TableCell><TableCell sx={{ color: primaryLight, fontWeight: 'bold' }}>{staff?.role}</TableCell></TableRow>
                        </Table>
                    </TableContainer>
                </Box>
            </Dialog>
        </>
    )
}
