import { primaryLight, secondaryLight } from '../../../../../utils/constants'
import { compressImage } from '../../../../../utils/functions'
import { AddPhotoAlternate } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import Thumbnail from './Thumbnail'
import { useEffect, useRef, useState } from 'react'
import React from 'react'

const allowedFileTypes = ['image/jpg', 'image/jpeg', 'image/webp']
const allowedFileSize = 10 // 10mb
const allowedFileNameLength = 150


export default function LogoInput({ logo, setLogo }) {
    const inputRef = useRef(null)

    const [fileErr, setFileErr] = useState(null)
    useEffect(() => {
        if (fileErr) setTimeout(() => { setFileErr(null) }, 3000)
    }, [fileErr])

    const removeImg = (e) => { 
        setLogo(null); 
        setFileErr(null);
        if (inputRef.current) inputRef.current.value = null
    }
    const fileInputClick = () => {
        removeImg()
        document.getElementById('logoInput').click()
    }
    const handleFileSelection = (e) => {
        const newFile = e.target.files[0]
        if (newFile) {
            if (!allowedFileTypes.includes(newFile.type)) { setFileErr(`Файлын төрөл JPG, JPEG эсвэл WEBP байх ёстой!`) }
            else if (newFile.size / 1024 / 1024 > allowedFileSize) { setFileErr(`Файлын хэмжээ хэт их байна. ${allowedFileSize} MB-с ихгүй байх ёстой!`) }
            else if (newFile.name?.length > allowedFileNameLength) { setFileErr(`Файлын нэр хэт урт. ${allowedFileNameLength} тэмдэгтээс ихгүй байх ёстой!`) }
            else { compressImage(newFile, setLogo) }
        } else { setFileErr('Файл хавсаргана уу!') }
    }

    const [isHovered, setIsHovered] = useState(false)
    
    return (
        <>
            <input
                accept="*/*"
                id="logoInput"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileSelection}
                ref={inputRef}
            />
            <Box 
                sx={{ 
                    border: '2px dashed grey', borderRadius: '10px', 
                    p: 1, height: '100px', display: 'flex', flexDirection: 'column',
                    justifyContent: 'center', alignItems: 'center',
                    '&: hover': { borderColor: secondaryLight, cursor: 'pointer' },
                    mb: fileErr ? 'initial': '42px'
                }}
                onClick={fileInputClick}
                onMouseOver={() => { setIsHovered(true) }}
                onMouseLeave={() => { setIsHovered(false) }}
            >
                <Typography fontSize='15px' sx={{ color: isHovered ? secondaryLight : primaryLight }}>Лого хавсаргах</Typography>
                {
                    logo
                        ?   <Thumbnail logo={logo} removeImg={removeImg} />
                        :   <AddPhotoAlternate sx={{ fontSize: '50px', color: 'grey', opacity: isHovered ? 1 : 0.5 }} />
                }
            </Box>
            <Typography fontSize='14px' sx={{ color: secondaryLight, textAlign: 'center' }}>{fileErr}</Typography>
        </>
    )
}