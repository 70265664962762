import Categories from "../../components/admin/tabs/Categories"
import Promotion from "../../components/admin/tabs/Promotion"
import Customers from "../../components/admin/tabs/Customer"
import Products from "../../components/admin/tabs/Products"
import Shipment from "../../components/admin/tabs/Shipment"
import Setting from "../../components/admin/tabs/Settings"
import Order from "../../components/admin/tabs/Order"

export const adminRoutes = [
    {
        index: true,
        path: 'orders',
        element: <Order />,
    },
    {
        path: 'promotion',
        element: <Promotion />
    },
    {
        path: 'customers',
        element: <Customers />
    },
    {
        path: 'products',
        children: [
            {
                index: true,
                path: 'list',
                element: <Products />,
            },
            {
                path: 'category',
                element: <Categories />,
            }
        ]
    },
    {
        path: 'settings',
        element: <Setting />
    },
    {
        path: 'shipment',
        element: <Shipment />
    }
]