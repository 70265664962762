import { apiUrl } from '../../../../../../../../../global/axiosBase'
import { toPrice } from '../../../../../../../../../utils/functions'
import { Box, Typography } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import { useNavigate } from 'react-router'
import React from 'react'

export default function Product ({ key, product }) {
    //console.log('Product:', product)
    const { image, price, name } = product
    const imgUrl = image ? apiUrl + image : null
    const navigate = useNavigate()
    const goToDetail = () => { navigate(`/products/${product.id}`) }

    return (
        <Box
            key={key}
            sx={{
                border: '1px solid', height: '180px', width: '150px', 
                position: 'relative', boxShadow: 1,
                borderRadius: 1, background: '#f7f7f5',
                '&:hover': { cursor: 'pointer', boxShadow: 6 },
                borderColor: 'secondary.main',
            }}
        >
            {
                (product?.qty && product?.qty > 1) && 
                <Typography 
                    sx={{ 
                        position: 'absolute', top: -8, right: -8, borderRadius: 5, zIndex: 1500,
                        border: 1, bgcolor: 'primary.light', color: 'white', p: '6px', boxShadow: 2
                    }}
                >
                    {`x ${product?.qty}`}
                </Typography>
            }
            <CardMedia
                onClick={goToDetail}
                component="img"
                image={imgUrl || require(`./noimage.jpg`)}
                alt="random"
                sx={{
                    mb: 1, height: '60%', minWidth: '140px',
                    borderColor: 'neutral.main', opacity: '80%',
                    '&:hover': { cursor: 'pointer', opacity: '100%' }
                }}
            />
            <Typography 
                gutterBottom 
                fontWeight={600}
                sx={{
                    p: '1px 3px', ml: 1,
                    fontSize: '12.5px', p: 0,
                    '&:hover': { cursor: 'pointer' }
                }}
                onClick={ goToDetail }
            >{name}</Typography>
            <Typography
                noWrap
                sx={{
                    fontSize:'13px', textAlign: 'center', boxShadow: 1,
                    fontWeight: 600, position: 'absolute', bottom: 5, right: 5,
                    backgroundColor: 'secondary.light', color: 'white',
                    borderRadius: 1, p: '2px 8px',
                }}
            >{toPrice(price)}</Typography>
        </Box>
    )
}