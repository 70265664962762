import { Box, InputAdornment, MenuItem, TableCell, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'
import DropCellMenu from './DropCellMenu'
import { keys } from 'underscore'
import React from 'react'

export default function DropCell({ field, choices, filters, setFilters }) {
    const value = filters?.find(f => f.k === field)?.v
    const handleSelect = (newVal) => {
        setFilters(prev => {
            const f = { k: field, v: newVal }
            if (prev?.length) {
                if (prev.map(p => p.k).includes(field)) {
                    return prev.map(p => p?.k === field ? f : p)
                } else { return [...prev, f] }
            }
            return [f]
        })
    }
    const clearSelect = () => { setFilters(prev => prev?.filter(p => p.k !== field) ) }
    
    return (
        <TableCell key={field} sx={{ width: '150px' }}>
            <DropCellMenu
                title={<TextField
                            placeholder={choices?.[value]??'Сонгох'}
                            InputProps={{
                                endAdornment: (<InputAdornment sx={{ backgroundColor: 'transparent' }}>
                                                    <Close 
                                                        sx={{ 
                                                            fontSize: '15px', color: 'primary.light',
                                                            backgroundColor: 'transparent', '&:hover': { cursor: 'pointer' },
                                                            display: value ? 'block' : 'none',
                                                        }}
                                                        onClick={clearSelect}
                                                    />
                                                </InputAdornment>)
                            }}
                            inputProps={{ style: { padding: '2px 5px', cursor: 'pointer' } }}
                            sx={{
                                '& fieldset': { borderRadius: 0, borderColor: 'primary.light' },
                                //'&:hover fieldset': { borderColor: 'primary.light' },
                                '& .MuiInputBase-input::placeholder': {
                                    color: 'primary.light', ml: '5px',
                                    fontSize: '13px', opacity: 1, textAlign: 'center'
                                },
                                '& .MuiInputBase-input': { 'caretColor': 'transparent' },
                                border: 'none',
                            }}
                            size='small'
                        />}
                detail={<Box sx={{ backgroundColor: 'white', boxShadow: 8 }}>
                            {keys(choices).map(k => <MenuItem key={k} sx={{ fontSize: '13px', color: 'primary.light' }} onClick={() => handleSelect(k)}>
                                                        {choices[k]}
                                                    </MenuItem>)}
                        </Box>}
            />
        </TableCell>
    )
}
