import { useAuthCtx } from '../../../../../../context/AuthCtx'
import { Box, Dialog, Typography } from '@mui/material'
import { useAddToCart } from '../../../../../hooks'
import React, { useState } from 'react'
import CountInput from './CountInput'
import { useEffect } from 'react'
import { btnStyle } from '..'

export default function AddToCartWithCnt ({ product, isHover, setIsHover }) {
    const { basket } = useAuthCtx()
    const item = basket?.items?.find(i => i?.product_id === product?.id)
    const [count, setCount] = useState(item?.qty||1)
    //console.log("C: ", item)
    const [ handleAddItem, alertMsg, alertOff, isSuccess, isIncluded, _ ] = useAddToCart({ product, qty: count, isUpdate: true })

    const cartStyle = { ...btnStyle, right: isHover ? -18 : -5 }

    // handle modal
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false); setIsHover(false); }
    useEffect(() => { if (isSuccess) { handleClose() } }, [alertMsg])
    
    return (
        <Box
            sx={{ 
                display: 'flex', transition: 'all 0.2s linear',
                position: 'relative', opacity: isHover ? '100%' : '0%'
            }}
        >
            <Typography
                onClick={handleOpen}
                sx={{ ...cartStyle, p: '2px 4px' }}
            >
                Захиалах тоо
            </Typography>
            <Dialog open={open} onClose={handleClose}>
                <Box sx={{ p: 2, pl: 4, pr: 4 }}>
                    <CountInput 
                        count={count} 
                        setCount={setCount} 
                        handleClick={handleAddItem}
                    />
                </Box>
            </Dialog>
        </Box>
    )
}