import { Box, Typography } from '@mui/material'
import React from 'react'

export default function EndDate({ endDate }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', p: 1, width: '500px', mt: 2 }}>
            <Typography sx={{ color: 'primary.main', mr: 1 }}>Урамшуулал дуусах хугацаа:</Typography>
            <Typography
                sx={{
                    color: 'secondary.light',
                    fontWeight: 700,
                    fontSize: '20px',
                    textShadow: '1px 1px 1px'
                }}
            >{endDate?.split(' ')?.[0]}</Typography>
        </Box>
    )
}
