import { Grid, List, ListItem, ListItemText, Typography, styled } from '@mui/material'
import React, { useState } from 'react'
import CheckColumn from './CheckColumn'

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: '15px',
    border: '1px solid transparent',
    paddingLeft: theme.spacing(1),
    transition: 'border 0.15s ease-in-out',
    '&:hover': {
      color: theme.palette.secondary.light,
      cursor: 'pointer',
      borderColor: theme.palette.secondary.light,
    },
}))

export default function TableColumnsOrder({ updatedCols, setUpdatedCols}) {
    // column drag and drop
    const [hoverIdx, setHoverIdx] = useState(null)
    const handleDragStart = (event, idx) => { event.dataTransfer.setData('text/plain', idx) }
    const handleDragOver = (event, hoverIndex) => { 
        event.preventDefault()
        setHoverIdx(hoverIndex)
    }
    const handleDragEnd = () => { setHoverIdx(null) }
    const handleDrop = (event) => {
        event.preventDefault()
        const draggedIdx = parseInt(event.dataTransfer.getData('text/plain'))
        if (draggedIdx !== hoverIdx) {
            setUpdatedCols(prev => prev.map(field => {
                if (field.idx === draggedIdx) { return { ...field, idx: hoverIdx } }
                else {
                    if (draggedIdx > hoverIdx) {
                        if (field.idx >= hoverIdx && field.idx < draggedIdx) {
                            return { ...field, idx: field.idx + 1 }
                        }
                        return field
                    } else {
                        if (field.idx > draggedIdx && field.idx <= hoverIdx) {
                            return { ...field, idx: field.idx - 1}
                        }
                        return field
                    }
                }
            }))
        }
    }

    const sortedArray = updatedCols.sort((a, b) => a.idx - b.idx)

    return (
        <Grid item xs={6}>
            <Typography sx={{ m: 1, fontSize: '16px', width: '200px' }} vcomponent="div">
                Багануудын тохиргоо
            </Typography>
            <List component='nav'>
                {
                    sortedArray.map(oc => (
                            <ListItem 
                                key={`${oc.field_name}`}
                                draggable
                                onDragStart={(event) => handleDragStart(event, oc.idx)}
                                onDragOver={(event) => handleDragOver(event, oc.idx)}
                                onDrop={(event) => handleDrop(event)}
                                onDragEnd={handleDragEnd}
                                sx={{ p: 0, width: '200px' }}
                            >
                                <CheckColumn setUpdatedCols={setUpdatedCols} idx={oc.idx} curVal={oc.isShown} />
                                <ListItemText
                                    primary={<StyledTypography>{oc.name}</StyledTypography>}
                                    sx={{ 
                                        border: hoverIdx === oc.idx ? 1: 'none',
                                        m: 0, ml: 1, '&:hover': { cursor: 'pointer' }
                                    }}
                                />
                            </ListItem>
                        )
                    )
                }
            </List>
        </Grid>
    )
}
