import { TextField } from '@mui/material'
import React from 'react'

export default function LicenseExp({ getFieldProps, getErrors }) {
    return (
        <TextField
            label='ТЗ дуусах хугацаа'
            inputProps={{ style: { fontSize: '15px' }}}
            InputLabelProps={{ style: { fontSize: '15px' } }}
            name='licenseExp'
            {...getFieldProps('licenseExp')}
            {...getErrors('licenseExp')}
            size='small'
            sx={{ width: '100%' }}
        />
    )
}
