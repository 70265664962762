import { secondaryLight } from '../../../../../../utils/constants'
import { TableCell, TableHead, TableRow } from '@mui/material'
import FilterRow from './FilterRow'
import React from 'react'

export default function Head({ cols }) {
    const rf = React.useRef(null)
    const top = rf?.current?.getBoundingClientRect()?.height
    return (
        <TableHead>
            <TableRow
                sx={{ 
                    '& > th': { 
                        px: '4px', py: '2px', 
                        backgroundColor: secondaryLight, color: 'white', 
                        borderRight: '1px solid white', textAlign: 'center'
                    }
                }}
                ref={rf}
            >
                <TableCell width='30px'>№</TableCell>
                {
                    cols?.map(
                        (col, idx) => 
                            <TableCell key={idx}>{col?.name}</TableCell>
                    )
                }
                <TableCell>Үйлдэл</TableCell>
            </TableRow>
            <FilterRow top={top} />
        </TableHead>
    )
}
