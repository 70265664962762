import { qtyMsgs, useAddToCart, useCountInput } from '../../../hooks'
import { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import React from 'react'

const CountInput = ({ item, qd, qtysErr, setQtysErr }) => {
    const firstErr = qd?.[item.qtyId] === false ? qtyMsgs.lessQty : qd?.[item.qtyId] === null ? qtyMsgs.noQty : ''
    
    const product = { id: item?.product_id, itemname_id: item?.product_itemname_id }
    const [val, setVal] = useState(item.qty)
    const [ handleAddItem, alertMsg, alertOff, isSuccess, isIncluded, _ ] = useAddToCart({ product, qty: val, isUpdate: true, inOrderItems: true })
    useEffect(() => { if (val) { alertOff() } }, [val])
    const { err, value, handleValue, handleAdd, handleEnter } = useCountInput({ setCount: setVal, handleClick: handleAddItem, count: item.qty })
    
    const handleQtysErr = (msg) => { setQtysErr({ ...qtysErr, [item.qtyId]: msg }) }
    useEffect(() => {
        if (err) { handleQtysErr(err) }
        if (alertMsg === qtyMsgs.noQty) { handleQtysErr(qtyMsgs.noQty) }
        if (alertMsg === qtyMsgs.lessQty) { handleQtysErr(qtyMsgs.lessQty) }
        if (!err && !alertMsg) { delete qtysErr?.[item.qtyId] }
    }, [err, alertMsg])
    const am = !isSuccess ? alertMsg : ''
    useEffect(() => {
        if (isSuccess) { delete qtysErr?.[item.qtyId]}
    }, [isSuccess])
    
    return <>
                <TextField
                    size='small'
                    sx={{ width: '100px' }}
                    value={ value }
                    onChange={ handleValue }
                    onBlur={ handleAdd }
                    onKeyDown={ handleEnter }
                    inputProps={{ style: { textAlign: 'center', fontSize: '14px', fontWeight: 'bold', padding: '2px', } }}
                    type='number'
                    error={Boolean(firstErr)|| Boolean(am) || err}
                    helperText={Boolean(firstErr) ? firstErr : am || err || ''}
                />
            </>
}

export default CountInput