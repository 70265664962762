import CompanyInfo from '../../../../profile/Settings/CompanyInfo'
import { paperStyle } from '../../../../../utils/constants'
import { Paper } from '@mui/material'
import React from 'react'

const AccountsInfo = () => {    
    
    return (
        <>
            <Paper sx={{ overflow: 'hidden', ...paperStyle, p: 3 }}>
                <CompanyInfo />
            </Paper>
        </>
    )
}

export default AccountsInfo