import { paperStyle } from '../../../../utils/constants'
import AddToBasket from './AddToBasket'
import { Box } from '@mui/material'
import Specs from './Specs'
import Title from './Title'
import Price from './Price'
import React from 'react'

const ProductInfo = ({ product }) => {
    const { id, name, price, barcode } = product
    
    return (
        <Box sx={{ width: '46%', ...paperStyle, p: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Title name={name} id={id} barcode={barcode} />
            <Price price={price} sale_price={product?.sale_price} />
            {<Specs product={product} />}
            <AddToBasket product={product} />
        </Box>
    )
}

export default ProductInfo