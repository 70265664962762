import { Box, Collapse, List, Tooltip, Typography } from '@mui/material'
import { secondaryLight } from '../../../../utils/constants'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

const hoverColor = '#f5f5f5'

const MenuItem = ({ item, navi, tab, open }) => {
	const isSelected = item?.name === tab

	return (
		<ListItemButton
			onClick={() => navi(item?.route)}
			sx={{
				backgroundColor: isSelected ? hoverColor : 'initial',
				'&:hover' : { backgroundColor: hoverColor },
				borderBottom: isSelected ? `1px solid ${secondaryLight}` : '1px solid transparent',
				borderTop: isSelected ? `1px solid ${secondaryLight}` : '1px solid transparent',
				p: '2px'
			}}
		>
			<Tooltip title={`${item?.name}`} placement='right-start' disableHoverListener={open}>
				<ListItemIcon>{item.icon}</ListItemIcon>
			</Tooltip>
			<ListItemText sx={{ color: secondaryLight }} primary={<Typography fontSize='14px'>{item.name}</Typography>} />
		</ListItemButton>
	)
}

const MenuItemWithSub = ({ item, navi, tab, open: drawerOpen }) => {	
	const [open, setOpen] = useState(false)
	const handleClick = (r) => { 
		setOpen(!open)
		navi(r)
	}
	const isSelected = item?.name === tab
	const isSubSelected = (sub) => sub?.name === tab
	const collapse = ![item?.name]?.concat(item?.subMenus?.map(s => s?.name)).includes(tab)
	useEffect(() => { if (collapse) { setOpen(false) } }, [collapse])

	return (
		<Box>
			<ListItemButton 
				onClick={ () => handleClick(item?.route) }
				sx={{ 
					backgroundColor: isSelected ? hoverColor : 'initial',
					'&:hover' : { backgroundColor: hoverColor }, p: '2px'
				}}
			>
				<Tooltip title={`${item?.name}`} placement='right-start' disableHoverListener={drawerOpen}>
					<ListItemIcon>
						{item.icon}
					</ListItemIcon>
				</Tooltip>
				<ListItemText sx={{ color: secondaryLight }} primary={<Typography fontSize='14px'>{item.name}</Typography>} />
				{ open ? <ExpandLess sx={{ color: secondaryLight }} /> : <ExpandMore sx={{ color: secondaryLight }} /> }
			</ListItemButton>
			<Collapse
				in={open}
				timeout='auto'
				unmountOnExit
			>
				<List>
					{
						item.subMenus.map(sub => 
							<ListItemButton
								key={sub.name} 
								onClick={ () => navi(sub?.route) }
								sx={{
									backgroundColor: isSubSelected(sub) ? hoverColor : 'initial',
									'&:hover' : { backgroundColor: hoverColor }, p: '2px', pl: 5,
									borderBottom: isSubSelected(sub) ? `1px solid ${secondaryLight}` : '1px solid transparent',
									borderTop: isSubSelected(sub) ? `1px solid ${secondaryLight}` : '1px solid transparent'
								}}
							>
								<Tooltip title={`${sub?.name}`} placement='right-start' disableHoverListener={drawerOpen}>
									<ListItemIcon>{sub.icon}</ListItemIcon>
								</Tooltip>
								<ListItemText sx={{ color: secondaryLight }} primary={<Typography fontSize='14px'>{sub.name}</Typography>} />
							</ListItemButton>
						)
					}
				</List>
			</Collapse>
		</Box>
	)
}

export default function Menu({ tab, menus, open }) {
	const navi = useNavigate()
	return (
		<List>
			{
				menus.map(
				m => <Box key={m.name}>
						{ m.subMenus 
							? <MenuItemWithSub item={m} tab={tab} navi={navi} open={open} />
							: <MenuItem item={m} tab={tab} navi={navi} open={open} />
						}
					</Box>
				)
			}
		</List>
	)
}