import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'

export default function Title({ name, barcode}) {
	const bCode = barcode ? `#${barcode}` : ''

	return (
		<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
			<Typography fontSize='20px' fontWeight='bold'>
				{name?.toUpperCase()}
			</Typography>
			<Tooltip title='Баркод' placement='right'>
				<Typography fontSize='15px' fontWeight='bold' sx={{ color: '#707070', width: 'fit-content', mb: '4px' }}>
					{bCode}
				</Typography>
			</Tooltip>
		</Box>
	)
}