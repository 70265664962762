import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { scrollStyle } from '../../../../../utils/constants'
import { filterBoxStyle } from '../../utils'
import React from 'react'

export default function Vndr({ vndrs, filters, setFilters, setPage, setSf }) {
    const handleCheck = (e) => {
        const newValue = parseInt(e.target.value)
        filters?.vndr?.includes(newValue)
            ?   setFilters(prev => ({ ...prev, vndr: prev.vndr.filter(m => m !== newValue) }))
            :   setFilters(prev => ({ ...prev, vndr: [...prev.vndr, newValue] }))
        setSf(null)
        setPage(1)
    }

    return (
        <Box sx={{ ...filterBoxStyle } } >
            <Typography
                sx={{
                    borderBottom: '1.5px solid',
                    textAlign: 'center', fontSize: '14px', fontWeight: 'bold',
                    borderBottomColor: 'secondary.light', mb: 1, p: '5px', position: 'relative',
                }}
            >
                Нийлүүлэгч ({vndrs?.length})
            </Typography>
            <Box
                sx={{
                    ...scrollStyle, '&::-webkit-scrollbar': { width: 6 },
                    maxHeight: '300px', overflow: 'hidden', overflowY: 'auto'
                }}
            >
                <FormControl>
                    <FormGroup>
                        {
                            vndrs.map(v => <FormControlLabel
                                                sx={{ marginBottom: '5px' }}
                                                key={v?.name}
                                                value={v?.id}
                                                control={
                                                    <Checkbox
                                                        checked={filters?.vndr?.includes(v?.id)}
                                                        onChange={handleCheck}
                                                        size='small'
                                                        sx={{ p: 0, pl: 1, m: 'auto 0' }}
                                                    />
                                                }
                                                label={<Typography fontSize='13.5px' marginLeft={1}>{v?.name}</Typography>}
                                            />
                                )
                        }
                    </FormGroup>
                </FormControl>
            </Box>
        </Box>
    )
}
