import { useAddToCart, useCountInput } from '../../../../hooks'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { useAuthCtx } from '../../../../../context/AuthCtx'
import { Box, Button, TextField } from '@mui/material'
import React, { useState } from 'react'

export default function AddToBasket({ product }) {
    const { basket } = useAuthCtx()
    const item = basket?.items?.find(i => i?.product_id === product?.id)
    const [count, setCount] = useState(item?.qty||1)
    const [ handleAddItem, alertMsg, alertOff, isSuccess, _, msgClr ] = useAddToCart({ product, qty: count, isUpdate: true, inDetail: true })
    const { err, value, handleValue, handleAdd, handleEnter } = useCountInput({ setCount, handleClick: handleAddItem, count })

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0, justifyContent: 'center', alignItems: 'center', mt: 2 }}>
            { getToast(Boolean(alertMsg), alertOff, alertMsg, msgClr) }
            <TextField
                label='Тоо, ширхэг'
                sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                    "& input[type=number]": { MozAppearance: "textfield" },
                    width: '170px'
                }}
                autoFocus
                value={ value }
                onChange={ handleValue }
                type='number'
                inputProps={{ style: { textAlign: 'center', padding: 0, fontSize: '20px' } }}
                onKeyDown={ handleEnter }
                variant='standard'
                size='small'
                error={Boolean(err)}
                helperText={Boolean(err) ? err : ' '}
            />
            <Button
                variant='contained'
                onClick={ handleAdd }
                size='small'
                sx={{ 
                    mb: '10px', width: '160px',
                    borderRadius: 0,
                    backgroundColor: 'primary.main',
                    color: 'white',
                    '&:hover': { backgroundColor: 'primary.main' }
                }}
            >Сагсанд нэмэх</Button>
        </Box>
    )
}
