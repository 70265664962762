import { Box, Button, Dialog, Grid, TextField, Typography } from '@mui/material'
import { addressValidationSchema } from '../../../../auth/RegisterAddress'
import { addBranch, updateBranch } from '../../../../../api/company'
import { emailYup, phoneYup } from '../../../../../utils/constants'
import { useProfileCtx } from '../../..'
import { useFormik } from 'formik'
import { keys } from 'underscore'
import Manager from './Manager'
import Address from './Address'
import * as Yup from 'yup'
import React from 'react'

const validationSchema = Yup.object({
    name: Yup.string().required('Салбарын нэр оруулна уу!'),
    phone: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').required('Салбарын утасны дугаар оруулна уу!'),
    address: addressValidationSchema.required('Салбарын хаяг оруулна уу!').nullable(),
    manager: Yup.object().when('$isMain', {
        is: false,  // condition based on ctx.parent
        then: Yup.object({
            name: Yup.string().required('Менежерийн нэр оруулна уу!').nullable(),
            ...emailYup,
            ...phoneYup,
        }).required('Менежерийн мэдээлэл оруулна уу!').nullable(),
        otherwise: Yup.object().nullable(), // or any other condition
    })
})

export default function AddBranch({ refetch, selected, open, handleClose }) {
    const { setMsg } = useProfileCtx()
    const isUpdate = Boolean(selected?.id)
    const isMain = isUpdate && selected?.isMain
    const { handleSubmit, getFieldProps, setFieldValue, setErrors, values, errors, resetForm } = useFormik({
        initialValues: {
            name: selected?.name||'',
            phone: selected?.phone||'',
            address: selected?.address?.ids||null,
            manager: selected?.manager||null
        },
        onSubmit: (branchInfo) => {
            if (isUpdate) {
                updateBranch(branchInfo, selected.id)
                    .then(_ => { 
                        setMsg({ m: 'Амжилттай!', s: 'success' })
                        refetch()
                        handleClose()
                    })
                    .catch(e => {
                        console.log("ERR: ", e)
                        const d = e?.response?.data
                        if (d?.manager?.phone) { setErrors({ 'manager': { 'phone': d.manager.phone[0] } }) }
                        if (d?.manager?.name) { setErrors({ 'manager': { 'name': d.manager.name[0] } }) }
                    })
            } else {
                addBranch(branchInfo)
                    .then(res => {
                        setMsg({ m: 'Амжилттай!', s: 'success' })
                        refetch()
                        handleClose()
                    })
                    .catch(e => {
                        const d = e?.response?.data
                        if (d?.manager?.email) { setErrors({ 'manager': { 'email': d.manager.email[0] } }) }
                        if (d?.manager?.phone) { setErrors({ 'manager': { 'phone': d.manager.phone[0] } }) }
                        if (d?.manager?.name) { setErrors({ 'manager': { 'name': d.manager.name[0] } }) }
                    })
            }
        },
        validationSchema,
        validateOnChange: false
    })

    React.useEffect(() => {
        if (isUpdate) {
            if (values?.manager?.name === '' && values?.manager?.email === '' && values?.manager?.phone === '') {
                setFieldValue('manager', null)
            }
        }
    }, [values])

    React.useEffect(() => { resetForm() }, [])

    const getErrors = (name) => {
        const isErr = keys(errors)?.includes(name)// && keys(touched)?.includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }

    const handleAddressFieldValue = (field, value) => { setFieldValue(`address.${field}`, value) }

    // err
    React.useEffect(() => {
        if (keys(errors)?.length === 1 || keys(errors)?.length === 2) {
            if (Boolean(errors?.address) === true && typeof errors.address === 'string') { setMsg({ m: errors?.address, s: 'warning' }) }
            else if (Boolean(errors?.manager) === true && typeof errors.manager === 'string') { setMsg({ m: errors?.manager, s: 'warning' }) }
        }
    }, [errors])

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='500px'>
            <Box p={3} sx={{ mt: 2 }}>
                <Box sx={{ width: '500px', ml: 1 }}>
                    <Typography sx={{ mb: 1 }}>Салбарын нэр, утас</Typography>
                    <Grid container spacing={1}>
                        <Grid item sx={6}>
                            <TextField
                                label='Салбарын нэр'
                                name='name'
                                {...getFieldProps('name')}
                                {...getErrors('name')}
                                size='small'
                                inputProps={{ style: { fontSize: '15px', color: '#1b2e3c', cursor: 'pointer' }}}
                                InputLabelProps={{ style: { fontSize: '15px' } }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label='Салбарын утас'
                                name='phone'
                                {...getFieldProps('phone')}
                                {...getErrors('phone')}
                                size='small'
                                inputProps={{ style: { fontSize: '15px', color: '#1b2e3c', cursor: 'pointer' }}}
                                InputLabelProps={{ style: { fontSize: '15px' } }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Address 
                    handleAddressFieldValue={handleAddressFieldValue}
                    values={values?.address}
                    errors={errors?.address}
                />
                {
                    !isMain && 
                        <Manager 
                            getFieldProps={getFieldProps}
                            errors={errors?.manager}
                            setFieldValue={setFieldValue}
                            isUpdate={isUpdate}
                        />
                }
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button 
                        variant='contained'
                        size='small'
                        sx={{ 
                            mt: 3, mb: 2, 
                            width: '200px'
                        }}
                        onClick={handleSubmit}
                    >Хадгалах</Button>
                </Box>
            </Box>
        </Dialog>
    )
}
