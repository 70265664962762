import AxiosWithToken from "../global/axiosBase"
import { getPageParams } from "./utils"

export const getToConfim = ({ queryKey }) => {
    const [_, page, pageSize, loc ] = queryKey
    let url = getPageParams(page, pageSize, 'confirm/?isReviewed=false')
    return AxiosWithToken.get(url) 
}
export const getConfirmed = ({ queryKey }) => {
    const [_, page, pageSize, loc ] = queryKey
    let url = getPageParams(page, pageSize, 'confirm/?isReviewed=true')
    //console.log("E: ", page, pageSize, url)
    return AxiosWithToken.get(url) 
}

export const toConfirm = ({ companyId, data }) => { return AxiosWithToken.patch(`confirm/${companyId}/`, data) }
export const getOs = () => { return AxiosWithToken.get('confirm/o/') }
export const getNotfs = () => { return AxiosWithToken.get('confirm_notf/') }
export const createNotf = (data) => { return AxiosWithToken.post('confirm_notf/', data) }
export const delNotf = (id) => { return AxiosWithToken.delete(`confirm_notf/${id}/`) }