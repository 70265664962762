import { endShipment } from '../../../../../../../../../api/shipment'
import { TableCell } from '@mui/material'
import React, { useState } from 'react'
import EndBtn from './EndBtn'

export default function EndCell({ endTime, shipmentId, setMsg }) {
    const [et, setEt] = useState(endTime||'')
    const [warning, setWarning] = useState(false)
    
    const handleEnd = () => {
        endShipment({ shipmentId, force: warning })
            .then(res => {
                if (res?.data === false) { setWarning(true) }
                else {
                    setMsg({ s: 'success', m: 'Түгээлт дууслаа.' })
                    setEt(res?.data)
                    setWarning(false)
                }
            })
            .catch(err => { setMsg({ s: 'warning', m: 'Түр хүлээгээд дахин оролдоно уу!' }); console.log("err: ", err) })
            .finally(() => {})
    }
    const et1 = et ? et.split(':') : []
    const timeStr = et1?.length >= 2 ? `${et1[0]} цаг ${et1[1]} минут` : ''

    return (
        <TableCell width='160px'>
            {
                et
                    ?   timeStr
                    :   <EndBtn handleEnd={handleEnd} warning={warning} setWarning={setWarning} />
            }
        </TableCell>
    )
}
