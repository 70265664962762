import { Dialog, Paper, Slide } from '@mui/material'
import CheckRegistration from './CheckRegistration'
import RegistrationForm from '../RegistrationForm'
import { getToast } from '../helpers/ToastAlert'
import RegisterLicense from '../RegisterLicense'
import RegisterAddress from '../RegisterAddress'
import Container from '@mui/material/Container'
import CreatePwd from '../PwdReset/CreatePwd'
import Box from '@mui/material/Box'
import { useState } from 'react'
import React from 'react'

export const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

// Main component
export default function SignIn() {
	const [email, setEmail] = useState('')
	
	const [badEmail, setBadEmail] = useState(null)
	const [noPwd, setNoPwd] = useState(null)
	
	const [incomplete, setIncomplete] = useState(null)
	const [registerAddress, setRegisterAddress] = useState(null)
	const [registerLicense, setRegisterLicense] = useState(null)
	const [regMsg, setRegMsg] = useState(null)
	const regMsgOff = () => { setRegMsg(null) }
	const [rs, setRs] = useState(null)
	const rsOff = () => { setRs(null) }

	const restart = () => {
		setNoPwd(null)
		setIncomplete(null)
		setRegisterAddress(null)
		setRegisterLicense(null)
	}
	
	const toLogin = badEmail === false && noPwd === false
	//console.log("AAA: ", toLogin, noPwd)

	const open = noPwd||incomplete||registerAddress||registerLicense

	return (
		<Container component="main" maxWidth="xs" sx={{ mt: 25 }}>
			<Box
				sx={{ marginTop: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3, mb: 4 }}
				component={Paper}
				elevation={24}
			>
				<CheckRegistration
					email={email}
					setEmail={setEmail}
					setBadEmail={setBadEmail}
					setNoPwd={setNoPwd}
					toLogin={toLogin}
					setIncomplete={setIncomplete}
					setRegisterAddress={setRegisterAddress}
					setRegisterLicense={setRegisterLicense}
					setRegMsg={setRegMsg}
					restart={restart}
				/>
			</Box>
			{getToast(Boolean(regMsg), regMsgOff, regMsg, 'warning', 2500 )}
			{getToast(Boolean(rs), rsOff, rs, 'success', 2500 )}
			<Dialog open={open} onClose={restart} TransitionComponent={Transition}>
				{noPwd && <CreatePwd email={email} setRs={setRs} handleClose={restart} />}
				{incomplete && <RegistrationForm setRs={setRs} handleClose={restart} />}
				{registerAddress && <RegisterAddress setRs={setRs} handleClose={restart} />}
				{registerLicense && <RegisterLicense setRs={setRs} handleClose={restart} />}
			</Dialog>
		</Container>
	)
}