import { InputAdornment, TableCell, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'
import { usePromoCtx } from '../../..'
import React from 'react'

export default function NameCell() {
    const { setFilters } = usePromoCtx()
    const [value, setValue] = React.useState('')

    // delayed search
    const searchTimeoutRef = React.useRef(null)
    React.useEffect(() => {
        if (value) {
            if (searchTimeoutRef.current) {
                clearTimeout(searchTimeoutRef.current)
                searchTimeoutRef.current = null
            }
            searchTimeoutRef.current = setTimeout(() => { setFilters(prev => ([...prev, { k: 'name', v: value }])) }, 650)
        } else { setFilters(prev => prev.filter(f => f.k !== 'name'))}
        return () => { clearTimeout(searchTimeoutRef.current) }
    }, [value])

    const clearName = () => { setValue(''); setFilters(prev => prev.filter(f => f.k !== 'name')) }

    return (
        <TableCell sx={{ p: '2px' }}>
            <TextField
                placeholder='Нэрээр хайх'
                value={value}
                onChange={(e) => { setValue(e.target.value) }}
                InputProps={{
                    endAdornment: (<InputAdornment sx={{ backgroundColor: 'transparent' }}>
                                        <Close 
                                            sx={{ 
                                                fontSize: '15px', color: 'primary.light',
                                                backgroundColor: 'transparent',
                                                '&:hover': { cursor: 'pointer' },
                                                display: value ? 'block' : 'none',
                                            }}
                                            onClick={clearName}
                                        />
                                    </InputAdornment>)
                }}
                inputProps={{ style: { padding: '2px 8px' } }}
                sx={{
                    '& fieldset': { borderRadius: 0, borderColor: 'primary.light' },
                    '& .MuiInputBase-input::placeholder': {
                        color: 'primary.light', textAlign: 'center',
                        fontSize: '13px', opacity: 1
                    },
                    border: 'none', width: '100%'
                }}
                size='small'
            />
        </TableCell>
    )
}
