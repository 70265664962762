import { PAYMENT_TYPES, PROCESS_TYPES, STATUS_TYPES } from '../../../constants'
import { getBranchList, getPartnerSuppliers } from '../../../../api/company'
import { primaryLight, secondaryLight } from '../../../../utils/constants'
import { TableCell, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FilterAlt } from '@mui/icons-material'
import { isEmpty } from 'underscore'
import DropCell from './DropCell'

export default function Filter({ filters, setFilters, hhRef }) {
    const [top, setTop] = useState(hhRef?.current?.getBoundingClientRect()?.height)
    useEffect(() => {
        const newTop = hhRef?.current?.getBoundingClientRect()?.height
        if (newTop) { setTop(newTop) }
    }, [hhRef])

    // branches, suppliers
    const [brs, setBrs] = useState(null)
    const [ps, setPs] = useState(null)
    useEffect(() => { 
        getBranchList().then(res => { setBrs(res?.data) })
        getPartnerSuppliers().then(res => { setPs(res?.data) })
    }, [])
    const brsChoices = brs ? brs.reduce((acc, cur, idx) => {
        acc[cur?.id] = cur?.name
        return acc
    }, {}) : null
    const psChoices = ps ? ps.reduce((acc, cur, idx) => {
        acc[cur?.id] = cur?.name
        return acc
    }, {}) : null

    //console.log("BRS: ", psChoices)

    return (
        <TableRow 
            sx={{ 
                '& > td': { 
                    m: 0, p: 0, borderBottom: '1px solid gray', zIndex: 1050, top,
                    borderBottomColor: primaryLight, position: 'sticky', backgroundColor: 'white', 
                    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)'
                }
            }}
        >
            <TableCell align='center'>
                <Tooltip title='Захиалгын шүүлтүүр' placement='right'><FilterAlt sx={{ color: secondaryLight }} /></Tooltip>
            </TableCell>
            <TableCell colSpan={4}></TableCell>
            <DropCell field={'status'} choices={STATUS_TYPES} filters={filters} setFilters={setFilters} />
            <DropCell field={'payType'} choices={PAYMENT_TYPES} filters={filters} setFilters={setFilters} />
            <DropCell field={'process'} choices={PROCESS_TYPES} filters={filters} setFilters={setFilters} />
            {(brsChoices && !isEmpty(brsChoices)) && <DropCell field={'branch'} choices={brsChoices} filters={filters} setFilters={setFilters} />}
            {(psChoices && !isEmpty(psChoices)) && <DropCell field={'supplier'} choices={psChoices} filters={filters} setFilters={setFilters} />}
            <TableCell></TableCell>
        </TableRow>
    )
}
