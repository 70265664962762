import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material'
import { useOrderCtx } from '../..'
import { useEffect } from 'react'
import React from 'react'

export default function SelectAddress() {
	const { branches, branchId, setBranchId } = useOrderCtx()
	const many = branches?.length > 1

	// ганц салбартай бол тухайн салбарыг дефолтоор сонгох
	useEffect(() => {
		if (branches && branches?.length === 1) {
			setBranchId(branches[0].id)
			window.localStorage.setItem('adr', branches?.[0]?.name)
		}
	}, [branches])

	// олон салбартай бол сонгох
	const selectBranch = (b) => {
		if (many) {
			setBranchId(b?.id)
			window.localStorage.setItem('adr', b?.name)
		}
	}

  return (
		<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
			{
				branches?.length
					? 	<List sx={{ maxWidth: '600px' }}>
							{ branches?.map(
								(b, idx) => <ListItem 
												key={`${idx}`}
												onClick={() => selectBranch(b)}
												sx={{
													'&:hover': { bgcolor: '#efefef', cursor: 'pointer' },
													borderBottom: '1px solid #efefef', p: '2px'
												}}
											>
												<ListItemIcon sx={{ m: 0, p: 0 }}>
													{ b.id === branchId ? <RadioButtonChecked sx={{ color: 'secondary.main', fontSize: '16px' }} /> : <RadioButtonUnchecked sx={{ fontSize: '16px' }} /> }
												</ListItemIcon>
												<ListItemText
													primary={<Typography fontSize='14px' color='primary.main' fontWeight='bold'>{b.name||'-'}</Typography>}
													secondary={b?.address?.address||''}
												/>
											</ListItem>
							) }
							</List>
				: null
			}
		</Box>
	)
}
