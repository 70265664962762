import { Dialog, InputAdornment, TableCell, TextField } from '@mui/material'
import { primaryLight } from '../../../../../../../../../utils/constants'
import { useProductListCtx } from '../../../..'
import { Close } from '@mui/icons-material'
import React, { useState } from 'react'
import VndrsList from './VndrsList'

export default function ByVndr({ selVndrName }) {
    const { setFilters, vndrs } = useProductListCtx()
    const [open, setOpen] = useState(false)
    const toOpen = () => { setOpen(true) }
    const toClose = () => { setOpen(false) }

    const handleSelect = (m) => {
        setFilters(prev => ([
            ...prev.filter(f => f.k !== 'vndr'), 
            { k: 'vndr', v: JSON.stringify([m.id]), o: '=' }
        ]))
        toClose()
    }
    const handleClear = (e) => {
        setFilters((prev => prev.filter(f => f.k !== 'vndr')))
        toClose()
        e.stopPropagation()
    }

    if (vndrs?.length === 0) return <TableCell key='vndrs' />
    
    return (
        <TableCell key='vndr' sx={{ p: 0, px: '2px' }}>
            <TextField
                onClick={toOpen}
                size='small'
                placeholder='Нийлүүлэгчээр'
                value={selVndrName||''}
                sx={{
                    flex: 1, width: '100%',
                    '& fieldset': { borderColor: primaryLight, borderRadius: 0, borderWidth: selVndrName ? 3 : 1 },
                    '&:hover fieldset': { borderColor: primaryLight },
                    '& .MuiInputBase-input::placeholder': { color: primaryLight, fontSize: '14px', opacity: 1, ml: '5px' },
                }}
                inputProps={{ style: { padding: '3px 5px', fontSize: '14px', color: primaryLight, cursor: 'pointer' } }}
                InputProps={{ 
                    readOnly: true,
                    endAdornment: (<InputAdornment>
                                        <Close
                                            sx={{ 
                                                fontSize: '18px', color: primaryLight,
                                                '&:hover': { cursor: 'pointer' },
                                                visibility: selVndrName ? 'visible' : 'hidden'
                                            }} 
                                            onClick={handleClear}
                                        />
                                    </InputAdornment>)
                }}
                autoComplete='off'
            />
            <Dialog open={open} onClose={toClose}>
                <VndrsList handleSelect={handleSelect} toClose={toClose} />
            </Dialog>
        </TableCell>
    )
}
