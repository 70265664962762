import { Redeem } from '@mui/icons-material'
import { Box, Stack, Tooltip } from '@mui/material'
import React from 'react'
import { isEmpty } from 'underscore'
import { getPromoById } from '../../../../../api/promo'
import { PROMO_TYPES } from '../../../../admin/tabs/Promotion/PromoTable/utils'
import { promoBtnColor } from '../Featured/PromosBtn'
import Promo from './Promo'

export default function Promos({ promos }) {
    //console.log("PROMOS: ", promos)
    const [selectedPromo, setSelectedPromo] = React.useState(null)
    const getPromoDetail = (promoId) => {
        getPromoById(promoId)
            .then(res => { setSelectedPromo(res?.data) })
            .catch(err => { console.log("PROMO ERR: ", err) })
    }

    return (
        <Stack sx={{ mt: 3 }}>
            <Box sx={{ p: 1, my: 1, display: 'flex', flexDirection: 'row', gap: 1, '& > div': { fontSize: '13px', color: 'primary.light' }, borderBottom: 1 }}>
                <Box sx={{ width: '20px' }}>№</Box>
                <Box sx={{ flexGrow: 1, ml: 2, mr: 5, maxWidth: '400px' }}>Урамшууллын нэр</Box>
                <Box sx={{ width: '250px', display: 'flex', alignItems: 'center' }}>Урамшууллын төрөл</Box>
                <Box sx={{ width: '100px' }}>Дуусах огноо</Box>
            </Box>
            {
                promos?.map((promo, idx) => (
                    <Box
                        key={promo.id}
                        sx={{
                            p: 1, boxShadow: 2, borderRadius: 1, my: 1,
                            borderColor: 'primary.main', border: 1, bgcolor: '#f7f7f5',
                            display: 'flex', flexDirection: 'row', gap: 1, transition: 'ease-in-out 0.1s',
                            '&:hover': { borderColor: 'primary.dark', cursor: 'pointer', boxShadow: 6 },
                            '& > div': { fontSize: '13px', color: 'primary.light' },
                        }}
                        onClick={() => getPromoDetail(promo.id)}
                    >
                        <Box sx={{ width: '20px' }}>{idx + 1}.</Box>
                        <Box>
                            <Tooltip title={promo?.has_gift ? 'Бэлэгтэй урамшуулал' : ''} placement='left'>
                                <Redeem sx={{ color: promo?.has_gift ? promoBtnColor : 'transparent' }} />
                            </Tooltip>
                        </Box>
                        <Box sx={{ flexGrow: 1, mr: 5, maxWidth: '400px', textShadow: '0.5px 0.5px #dfdce3' }}>{promo.name || '-'}</Box>
                        <Box sx={{ width: '250px', fontSize: '13px', textAlign: 'left' }}>{PROMO_TYPES[promo.promo_type] || '-'}</Box>
                        <Box sx={{ width: '100px' }}>{promo.end_date?.split(' ')?.[0] || '-'}</Box>
                    </Box>
                ))
            }
            {!isEmpty(selectedPromo) && <Promo promo={selectedPromo} setSelectedPromo={setSelectedPromo} />}
        </Stack>
    )
}
