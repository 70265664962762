import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { toPrice } from '../../../../../utils/functions'
import { acceptOrder } from '../../../../../api/order'
import React, { useState } from 'react'

const changedColor = 'rgba(255, 0, 0, 0.2)'
const isQtyChanged = (item) => item.iQty !== item.itemQty
export default function Items({ items, order, setOrder }) {
    const [msg, setMsg] = useState(null)
    const msgOff = () => { setMsg(null) }
    const accept = () => {
        acceptOrder({ id: order?.id })
            .then(res => {
                setOrder(prev => ({ ...prev, process: 'Хүлээн авсан' }))
                setMsg({ m: 'Амжилттай!', s: 'success' })
            })
            .catch(err => {
                setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
                console.log("ERR: ", err)
            })
    }
    const isReceived = order.process === 'Хүлээн авсан'
    const toAccept = !order?.branch || order.process === 'Түгээлтэнд гарсан'
    const iqc = items.find(item => isQtyChanged(item))
    //console.log("OR: ", iqc)

    return (
        <TableRow>
            <TableCell colSpan={10}>
                {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 2000)}
                <Table sx={{ ml: 1, width: '700px' }}>
                    <TableHead>
                        <TableRow sx={{ '& > th': { fontWeight: 'bold', borderBottom: '1px solid grey', p: '4px' } }}>
                            {['№', 'Барааны нэр', 'Барааны үнэ', 'Тоо, ширхэг', 'Нийт үнэ'].map(c => <TableCell align={c === 'Тоо, ширхэг' ? 'center' : 'initial'}>{c}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item, idx) => (
                                    <TableRow 
                                        sx={{ 
                                            '& > td': { p: '4px' },
                                            backgroundColor: isQtyChanged(item) ? changedColor : 'transparent',
                                        }}
                                    >
                                        <TableCell width={'20px'}>{idx+1}</TableCell>
                                        <TableCell>{item.itemName}</TableCell>
                                        <TableCell>{toPrice(item.itemPrice)}</TableCell>
                                        <TableCell align='center'>{item.itemQty}</TableCell>
                                        <TableCell>{toPrice(item.itemTotalPrice)}</TableCell>
                                    </TableRow>
                                ))}
                        {
                            !isReceived && toAccept && <TableRow>
                                                            <TableCell colSpan={5} sx={{ p: '4px 2px', textAlign: 'center' }}>
                                                                <Button 
                                                                    size='small' variant='contained'
                                                                    sx={{ p: '3px', m: 1 }}
                                                                    onClick={accept}
                                                                >Захиалга хүлээн авсан</Button>
                                                            </TableCell>
                                                        </TableRow>
                        }
                    </TableBody>
                    {iqc && <TableCell colSpan={5} align='left' sx={{ p: '2px', pt: 1, pl: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Box sx={{ border: 1, bgcolor: changedColor, width: '12px', height: '12px', mr: 1 }} />
                            <Typography variant='caption'>- Тоо ширхэг өөрчлөгдсөн</Typography>
                        </Box>
                    </TableCell>}
                </Table>
            </TableCell>
        </TableRow>
    )
}