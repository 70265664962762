import { Box, Button, MenuItem, TextField } from '@mui/material'
import { emailYup } from '../../../../../../utils/constants'
import { addStaff } from '../../../../../../api/staff'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React from 'react'

const staffRoles = {
    S: 'Борлуулагч',
    D: 'Түгээгч',
    B: 'Захиалгын менежер',
    P: 'Бэлтгэгч'
}
const validationSchema = Yup.object({
    name: Yup.string().max(150, 'Нэр хэт урт байна!').required('Ажилтны нэр оруулна уу!'),
    ...emailYup,
    rd: Yup.string().transform(val => val.trim().replace(/\s+/g, '')).matches(/^[а-яА-Я]{2}\d{8}$/, 'Регистрийн дугаар буруу байна!').required('Регистрийн дугаар оруулна уу!'),
    phone: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').required('Утасны дугаар оруулна уу!'),
    role: Yup.string().oneOf(Object.keys(staffRoles), 'Ажилтны төрөл буруу байна!').required('Ажил үүрэг сонгоно уу!')
})

export default function AddStaff({ refetch, alertOn }) {
    const { handleSubmit, getFieldProps, setErrors, errors, touched, values } = useFormik({
        initialValues: {
            'name': '',
            'rd': '',
            'email': '',
            'phone': '',
            'role': ''
        },
        onSubmit: (fVals) => {
            addStaff(fVals)
            .then(_ => { alertOn(); refetch() })
            .catch(e => {
                const err = e?.response?.data
                const emailErr = err?.email
                if (emailErr) { setErrors({ email: emailErr }) }
                const phoneErr = err?.phone
                if (phoneErr) { setErrors({ phone: phoneErr }) }
            })
        },
        validationSchema,
        validateOnChange: false,
    })

    const getErrors = (name) => {
        const isErr = Object.keys(errors)?.includes(name) && Object.keys(touched)?.includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }

    // enter shift
    const idMap = { f1: 'f2', f2: 'f3', f3: 'f4', f4: 'f5', f5: 'f6' }
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            const curElmId = e?.target?.id
            e.preventDefault()
            if (curElmId === 'f5' && values?.role) {
                handleSubmit()
            } else if (e?.target?.value) {
                document.getElementById(idMap[curElmId])?.focus()
            }
        }
    }

    return (
        <>
            <Box width='400px' sx={{ p: 5 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <TextField
                        label='Нэр'
                        name='name'
                        {...getFieldProps('name')}
                        {...getErrors('name')}
                        size='small'
                        id='f1'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Регистрийн дугаар'
                        name='rd'
                        {...getFieldProps('rd')}
                        {...getErrors('rd')}
                        size='small'
                        id='f2'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Имэйл хаяг'
                        name='email'
                        {...getFieldProps('email')}
                        {...getErrors('email')}
                        size='small'
                        id='f3'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Утасны дугаар'
                        name='phone'
                        {...getFieldProps('phone')}
                        {...getErrors('phone')}
                        size='small'
                        id='f4'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Ажил үүрэг'
                        name='role'
                        {...getFieldProps('role')}
                        {...getErrors('role')}
                        size='small'
                        select
                        id='f5'
                        onKeyDown={handleEnter}
                    >
                        {
                            Object.keys(staffRoles).map(s => <MenuItem key={`${s}`} value={s}>{staffRoles[s]}</MenuItem>)
                        }
                    </TextField>
                    <Button 
                        variant='contained' 
                        size='small' 
                        sx={{ p: '8px' }} 
                        onClick={handleSubmit}
                        id='f6'
                    >
                        Нэмэх
                    </Button>
                </Box>
            </Box>
        </>
    )
}
